import React, { useMemo } from 'react';
import { GridColDef, GridHeaderFilterCellProps } from '@mui/x-data-grid-premium';
import { equalsOperator, filterOperators, notEqualsOperator } from '@src/utils/tables/utils';
import { paymentRequestMappers } from '@src/utils/mappers/tables/PaymentRequestMappers';
import { PaymentStatus } from '@src/service/types';
import MenuItem from '@mui/material/MenuItem';
import { gridFilterModelSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

export const columnsDef: GridColDef[] = [
  {
    field: 'id',
    headerName: '№',
    description: 'ИД Заявки',
    flex: 3,
    minWidth: 20,
    type: 'string',
    align: 'center',
    editable: false,
    resizable: true,
    groupable: false,
    aggregable: false,
    filterOperators: filterOperators,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'company_name',
    headerName: 'Компания',
    description: 'Компания',
    flex: 10,
    minWidth: 50,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    editable: false,
    resizable: true,
    filterOperators: filterOperators,
    groupable: false,
    aggregable: false,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'description',
    headerName: 'Назначение платежа',
    description: 'Назначение платежа',
    flex: 20,
    minWidth: 100,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    editable: false,
    resizable: true,
    filterOperators: filterOperators,
    headerClassName: 'super-app-theme--header',
    groupable: false,
    aggregable: false,
  },
  {
    field: 'amount',
    headerName: 'Сумма',
    description: 'Сумма',
    flex: 5,
    minWidth: 30,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    editable: false,
    resizable: true,
    filterOperators: filterOperators,
    groupable: false,
    aggregable: false,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'status',
    headerName: 'Статус',
    description: 'Статус',
    flex: 5,
    minWidth: 30,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    editable: true,
    resizable: true,
    headerClassName: 'super-app-theme--header',
    filterable: true,
    filterOperators: [...equalsOperator, ...notEqualsOperator],
    groupable: false,
    aggregable: false,
    valueFormatter: (value: PaymentStatus) => paymentRequestMappers.statusToDisplay[value],
  },

  {
    field: 'expense_item',
    headerName: 'Статья расходов',
    description: 'Статья расходов',
    flex: 10,
    minWidth: 30,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    editable: false,
    groupable: false,
    aggregable: false,
    resizable: true,
    filterOperators: filterOperators,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'creator_name',
    headerName: 'Ответственный',
    description: 'Ответственный',
    flex: 10,
    minWidth: 30,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    editable: false,
    resizable: true,
    filterOperators: filterOperators,
    headerClassName: 'super-app-theme--header',
  },
];

export const StatusFilter = (props: GridHeaderFilterCellProps) => {
  const { colDef } = props;
  const getDefaultFilter = (field: string) => ({ field, operator: 'not equals' });
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const currentFieldFilters = useMemo(
    () => filterModel.items?.filter(({ field }) => field === colDef.field),
    [colDef.field, filterModel.items],
  );
  // const displayStatusList = [...[...Object.values(PaymentStatus)].map(s => paymentRequestMappers.statusToDisplay[s])];

  const handleChange = (event: SelectChangeEvent<PaymentStatus>) => {
    if (!event.target.value) {
      if (currentFieldFilters[0]) {
        apiRef.current.deleteFilterItem(currentFieldFilters[0]);
      }

      return;
    }
    apiRef.current.upsertFilterItem({
      ...(currentFieldFilters[0] || getDefaultFilter(colDef.field)),
      value: event.target.value,
    });
  };

  const value = (currentFieldFilters[0]?.value as PaymentStatus) ?? '';
  const label = !value ? 'Фильтр' : 'Статус';

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select value={value} onChange={handleChange} label={label} fullWidth>
        {Object.values(PaymentStatus).map(status => (
          <MenuItem key={status} value={status}>
            {paymentRequestMappers.statusToDisplay[status]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
