// FIXME: Небезопасное приведение типов. Требуется рефакторинг.
import { Action } from '@src/store/action';

import {
  IDaysRqDto,
  IPaymentRequestRsDto,
  ICreatePaymentRequestDto,
  IUpdatePaymentRequestDto,
  IPaymentRequestApiService,
  IPaymentRegistryRq,
} from '@src/service/types';

class CPaymentRequestApiService implements IPaymentRequestApiService {
  private readonly HOST_URL = '/api/v1/payment_request';

  private GetByIdAction = new Action<string, IPaymentRequestRsDto>();
  private GetPRListAction = new Action<IDaysRqDto, IPaymentRequestRsDto[]>({ isInterrupted: true });
  private CreatePRAction = new Action<ICreatePaymentRequestDto, IPaymentRequestRsDto>();
  private UpdatePRAction = new Action<IUpdatePaymentRequestDto, IPaymentRequestRsDto>();
  private DeletePRAction = new Action<string, boolean>();
  private MutateManyPRAction = new Action<string[], boolean>();
  private DownloadRegistryAction = new Action<IPaymentRegistryRq, boolean>();

  async getById(id: string): Promise<IPaymentRequestRsDto> {
    const result = await this.GetByIdAction.callAction(`${this.HOST_URL}/${id}`, 'GET');

    return result as IPaymentRequestRsDto;
  }

  /**
   * Получить список PaymentRequest
   */
  async getDatedList(dto: IDaysRqDto): Promise<IPaymentRequestRsDto[]> {
    const result = await this.GetPRListAction.callAction(
      `${this.HOST_URL}/get_dated?date_from=${dto.start_day}&date_to=${dto.end_day}`,
      'GET',
    );
    console.debug(result);
    if (result === true || !result) {
      return [];
    }

    return result as IPaymentRequestRsDto[];
  }

  /**
   * Создать новую запись PaymentRequest
   */
  async createPaymentRequest(dto: ICreatePaymentRequestDto): Promise<IPaymentRequestRsDto> {
    const result = await this.CreatePRAction.callAction(`${this.HOST_URL}/create`, 'POST', dto);
    // console.debug(result);

    return result as IPaymentRequestRsDto;
  }

  /**
   * Удалить PaymentRequest по ИД
   */
  async deletePaymentRequest(id: string): Promise<boolean> {
    const result = await this.DeletePRAction.callAction(`${this.HOST_URL}/${id}`, 'DELETE');

    return result as boolean;
  }

  /**
   * Обновить данные PaymentRequest
   */
  async updatePaymentRequest(id: string, dto: IUpdatePaymentRequestDto): Promise<IPaymentRequestRsDto> {
    const result = await this.UpdatePRAction.callAction(`${this.HOST_URL}/${id}`, 'PATCH', dto);

    return result as IPaymentRequestRsDto;
  }

  async approveMany(ids: string[]) {
    const success = await this.MutateManyPRAction.callAction(`${this.HOST_URL}/approve_many`, 'PATCH', ids);

    return success as boolean;
  }
  async rejectMany(ids: string[]) {
    const success = await this.MutateManyPRAction.callAction(`${this.HOST_URL}/reject_many`, 'PATCH', ids);

    return success as boolean;
  }
  async deleteMany(ids: string[]) {
    const success = await this.MutateManyPRAction.callAction(`${this.HOST_URL}/delete_many`, 'DELETE', ids);

    return success as boolean;
  }
  // async downloadPaymentsRegistry(dto: IPaymentRegistryRq) {
  //   const success = await this.DownloadRegistryAction.callAction(`${this.HOST_URL}/download_registry`, 'POST', dto);
  //
  //   return success as boolean;
  // }
  async downloadPaymentsRegistry(dto: IPaymentRegistryRq): Promise<boolean> {
    const token = localStorage.getItem('token') ?? '';

    try {
      const response = await fetch(`${this.HOST_URL}/download_registry`, {
        method: 'POST',
        body: JSON.stringify(dto),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/octet-stream',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const error = await response.json();
        if (error?.detail) {
          console.error(error.detail?.msg || error.detail);
        }

        return false;
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = '1c_to_kl.txt';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      return true;
    } catch (error) {
      console.error('Download error:', error);

      return false;
    }
  }
}

export const PaymentRequestApiService: IPaymentRequestApiService = new CPaymentRequestApiService();
