import { observer } from 'mobx-react-lite';

import { paymentRequestTableStore } from '@src/components/Tables/PaymentRequestsTable/store/PaymentRequestTableStore';
import {
  IBankAccountExRsDto,
  IPaymentCreateDialog,
  IPaymentRequestsTableDisplayDto,
  IPaymentUpdateDialog,
} from '@src/service/types';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import { paymentRequestMappers } from '@src/utils/mappers/tables/PaymentRequestMappers';
import { CreatePaymentRequestDialog } from '@src/components/Tables/PaymentRequestsTable/components/CreateDialog/CreatePaymentRequestDialog';
import { UpdatePaymentRequestDialog } from '@src/components/Tables/PaymentRequestsTable/components/UpdateDialog/UpdatePaymentRequestDialog';
import { GridRowParams } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import { AlertDialog } from '@src/components/AlertDialog/AlertDialog';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { Dayjs } from 'dayjs';
import { PaymentRqMainActions } from '@src/components/Tables/PaymentRequestsTable/components/PaymentRqMainActions/PaymentRqMainActions';
import { GridHeaderFilterCellProps, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { nowStr } from '@src/utils/date_utils';
import {
  columnsDef,
  StatusFilter,
} from '@src/components/Tables/PaymentRequestsTable/components/grid/PaymentRequestTableColumns';
import { DataTableGrid } from '@src/components/DataTable/DataTableGrid';
import { Close as CloseIcon, Download as DownloadIcon } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import _ from 'lodash';

interface AlertDialogState {
  isOpen: boolean;
  title: string;
  content: string;
  action: () => Promise<void>;
}

interface BankAutocompleteOption {
  account: IBankAccountExRsDto;
  output: string;
}

const buttonSx = {
  textTransform: 'none',
  borderRadius: 2,
  borderWidth: 2,
  padding: '6px 16px',
  '&:hover': {
    borderWidth: 2,
  },
};

export const PaymentRequestsTable: FC = observer(() => {
  /**
   * states
   */

  const {
    init,
    reloadListPR,
    isLoading,
    isPendingActions,
    isDownloadRegistry,
    createPaymentRequest,
    userSettings,
    list,
    bankAccountsList,
    deleteMany,
    approveMany,
    rejectMany,
    myBankAccountsList,
    registryStatistics,
    updateRegistryStatistics,
  } = paymentRequestTableStore;
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [createDialogInitialData, setCreateDialogInitialData] = useState<IPaymentCreateDialog>({});
  const [updateDialogInitialData, setUpdateDialogInitialData] = useState<IPaymentUpdateDialog | null>(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>({
    isOpen: false,
    title: '',
    content: '',
    action: async () => {},
  });
  const [downloadDialogOpen, setDownloadDialogOpen] = useState<boolean>(false);
  const [bankAccountsOptionList, setBankAccountsOptionList] = useState<BankAutocompleteOption[]>([]);
  const [defaultBankAccountsOption, setDefaultBankAccountsOption] = useState<BankAutocompleteOption | null>(null);

  /**
   * Effects
   */

  useEffect(() => {
    void init().catch(e => {
      console.error('Critical initialization error:', e);
      toast.error('Не удается загрузить данные');
    });
  }, [init]);

  useEffect(() => {
    if (myBankAccountsList) {
      const banksAutocompleteOptions: BankAutocompleteOption[] = myBankAccountsList.map(acc => ({
        account: acc,
        output: [acc.bank_name, acc.account_number].join(' | '),
      }));
      setBankAccountsOptionList(banksAutocompleteOptions);
      if (banksAutocompleteOptions) {
        setDefaultBankAccountsOption(banksAutocompleteOptions.find(b => b.account.is_main) ?? null);
      }
    }
  }, [myBankAccountsList]);

  /**
   * Handlers
   */

  // clicks

  const handleRowClick = useCallback((id: string) => {
    setSelectedRow(id);
  }, []);

  const handleRowDoubleClick = useCallback((params: GridRowParams<IPaymentRequestsTableDisplayDto>) => {
    const _row_data = params.row;
    setUpdateDialogInitialData(_row_data);
    setUpdateDialogOpen(true);
  }, []);

  const handleRowsCheckboxSelection = useCallback((ids: GridRowSelectionModel) => {
    setRowSelectionModel(ids);
  }, []);

  // buttons
  const handleDateRangeChange = useCallback(
    async (range: DateRange<Dayjs>) => {
      userSettings.saveFilterDateRange(range);
      await paymentRequestTableStore.updateDateRange(range);

      void reloadListPR();
    },
    [reloadListPR, userSettings],
  );

  const handleCreateButton = useCallback(() => {
    const defaultData: IPaymentCreateDialog = {
      payment_date: nowStr(),
      creator_id: paymentRequestTableStore.user?.id,
      creator_name: paymentRequestTableStore.user?.fio,
      amount: undefined,
      description: undefined,
      company_name: undefined,
      account_number: undefined,
      bank_name: undefined,
      expense_item: undefined,
    };
    const _stored_data = selectedRow ? list.find(item => item.id === selectedRow) : undefined;
    const _initialData =
      _stored_data &&
      !bankAccountsList.find(b => b.account_number === _stored_data.bank_account__account_number)?.company.is_blocked
        ? {
            ...paymentRequestMappers.toCreateDialog(_stored_data),
            payment_date: nowStr(),
            creator_id: paymentRequestTableStore.user?.id,
            creator_name: paymentRequestTableStore.user?.fio,
          }
        : defaultData;
    setCreateDialogInitialData(_initialData);
    setCreateDialogOpen(true);
  }, [bankAccountsList, list, selectedRow]);

  const handleApproveButtonAlert = useCallback(() => {
    setAlertDialogState({
      isOpen: true,
      title: 'Согласование',
      content: 'Вы согласовываете выбранные заявки?',
      action: async () => {
        const _ids: string[] = [...rowSelectionModel].map(id => String(id));
        const success = await approveMany(_ids);
        success ? toast.success('Заявки согласованы') : toast.error('Ошибка при согласовании заявок');
      },
    });
  }, [approveMany, rowSelectionModel]);

  const handleRejectButtonAlert = useCallback(() => {
    setAlertDialogState({
      isOpen: true,
      title: 'Отказ',
      content: 'Вы отказываете в согласовании выбранных заявок?',
      action: async () => {
        const _ids: string[] = [...rowSelectionModel].map(id => String(id));
        const success = await rejectMany(_ids);
        success ? toast.success('Заявки отклонены') : toast.error('Ошибка при согласовании заявок');
      },
    });
  }, [rejectMany, rowSelectionModel]);

  const handleDeleteButtonAlert = useCallback(() => {
    setAlertDialogState({
      isOpen: true,
      title: 'Удаление',
      content: 'Удалить выбранные заявки?',
      action: async () => {
        const _ids: string[] = [...rowSelectionModel].map(id => String(id));
        const success = await deleteMany(_ids);
        success ? toast.success('Заявки удалены') : toast.error('Ошибка при согласовании заявок');
      },
    });
  }, [rowSelectionModel, deleteMany]);

  const handleOpenDownloadDialog = useCallback(() => {
    updateRegistryStatistics(rowSelectionModel);
    setDownloadDialogOpen(true);
  }, [rowSelectionModel, updateRegistryStatistics]);

  const handleCloseDownloadDialog = useCallback(() => {
    setDownloadDialogOpen(false);
  }, []);

  const handleDownloadSubmit = useCallback(async () => {
    if (defaultBankAccountsOption) {
      const _ids: string[] = [...rowSelectionModel].map(id => String(id));
      const success = await paymentRequestTableStore.downloadPaymentsRegistry(
        defaultBankAccountsOption.account.id,
        _ids,
      );
      success || toast.error('Ошибка запросе файла реестра');
      handleCloseDownloadDialog();
    }
  }, [defaultBankAccountsOption, handleCloseDownloadDialog, rowSelectionModel]);

  /**
   * Dialog behavior
   */

  const handleCloseCreateDialog = useCallback(() => {
    setCreateDialogOpen(false);
    setSelectedRow(null);
    setCreateDialogInitialData({});
  }, []);

  const handleCloseUpdateDialog = useCallback(() => {
    setUpdateDialogOpen(false);
  }, []);

  /**
   * Children Handlers
   */
  // Create Dialog

  const handleCreateSubmit = useCallback(
    async (data: IPaymentCreateDialog) => {
      const _bankAccount = paymentRequestTableStore.bankAccountsList.find(
        b => b.account_number === data.account_number,
      );
      const _expenseItem = paymentRequestTableStore.expenseList.find(item => item.name === data.expense_item);
      if (_bankAccount && _expenseItem && data.amount && data?.payment_date && data.description) {
        try {
          const _createDto = paymentRequestMappers.fromCreateDialogToApi(data, _bankAccount.id, _expenseItem.id);
          const success = await createPaymentRequest(_createDto);

          success ? toast.success('Заявка создана') : toast.error('Ошибка выполнения');

          handleCloseCreateDialog();
        } catch (e) {
          if (e instanceof Error) {
            toast.error(e.message);
          }
        }
      }
      _bankAccount || toast.error('Компания или банковский счет не найдены');
      _expenseItem || toast.error('Статья расходов не найдена');
      data?.amount || toast.error('Сумма не должна быть пустой');
      data?.payment_date || toast.error('Дата не должна быть пустой');
      data.description || toast.error('Добавьте назначение платежа');
    },
    [createPaymentRequest, handleCloseCreateDialog],
  );

  // UpdateDialog

  const handleUpdateSubmit = useCallback(
    async (data: IPaymentUpdateDialog) => {
      if (_.isEqual(data, updateDialogInitialData)) {
        handleCloseUpdateDialog();
        toast.info('Изменений не найдено');

        return;
      }
      const _bankAccount = paymentRequestTableStore.bankAccountsList.find(
        b => b.account_number === data.account_number,
      );
      const _expenseItem = paymentRequestTableStore.expenseList.find(e => e.name === data.expense_item);
      if (_bankAccount && _expenseItem) {
        const apiItem = paymentRequestMappers.fromUpdateDisplayToUpdateApi(data, _bankAccount.id, _expenseItem.id);
        const success = await paymentRequestTableStore.updatePaymentRequest(data.id, apiItem);
        success ? toast.success('Заявка обновлена') : toast.error('Ошибка выполнения');
        handleCloseUpdateDialog();
      }
      _bankAccount || toast.error('Компания или банковский счет не найдены');
      _expenseItem || toast.error('Статья расходов не найдена');
    },
    [handleCloseUpdateDialog, updateDialogInitialData],
  );

  const handleDelete = useCallback(async () => {
    if (selectedRow) {
      const success = await paymentRequestTableStore.deletePaymentRequest(selectedRow);
      success ? toast.success('Заявка удалена успешно') : toast.error('Ошибка выполнения');
      setUpdateDialogOpen(false);
    }
  }, [selectedRow]);

  const handleDuplicate = useCallback(() => {
    if (
      updateDialogInitialData &&
      !bankAccountsList.find(b => b.account_number === updateDialogInitialData.account_number)?.company.is_blocked
    ) {
      const _createDialogData = {
        ...paymentRequestMappers.fromUpdateDialogToCreateDialog(updateDialogInitialData),
        payment_date: nowStr(),
        creator_id: paymentRequestTableStore.user?.id,
        creator_name: paymentRequestTableStore.user?.fio,
      };
      setCreateDialogInitialData(_createDialogData);
      setCreateDialogOpen(true);
    } else {
      toast.error('Нельзя дублировать данную заявку');
    }
  }, [bankAccountsList, updateDialogInitialData]);

  const handleRowDeleteAlert = useCallback(() => {
    setAlertDialogState({
      isOpen: true,
      title: 'Удаление заявки',
      content: 'Вы действительно хотите удалить выбранную заявку?',
      action: handleDelete,
    });
  }, [handleDelete]);

  /**
   * memos
   */

  const columns = useMemo(() => {
    return columnsDef.map(colDef => {
      if (colDef.field === 'status') {
        return {
          ...colDef,
          renderHeaderFilter: (params: GridHeaderFilterCellProps) => <StatusFilter {...params} />,
        };
      }

      return colDef;
    });
  }, []);

  const rows = useMemo(() => {
    return list?.map(item => paymentRequestMappers.toDisplay(item));
  }, [list]);

  /**
   * Alert dialog
   */

  const handleAlertCancel = useCallback(() => {
    setAlertDialogState(prev => ({ ...prev, isOpen: false }));
  }, []);

  const handleAlertConfirm = useCallback(async () => {
    await alertDialogState.action();
    setAlertDialogState(prev => ({ ...prev, isOpen: false }));
  }, [alertDialogState]);

  /**
   * render
   */

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <AlertDialog
        title={alertDialogState.title}
        content={alertDialogState.content}
        handleApply={handleAlertConfirm}
        handleCancel={handleAlertCancel}
        isOpen={alertDialogState.isOpen}
      />

      {createDialogInitialData && (
        <CreatePaymentRequestDialog
          isOpen={createDialogOpen}
          onClose={handleCloseCreateDialog}
          initialData={createDialogInitialData}
          disabled={isPendingActions}
          onSubmit={handleCreateSubmit}
        />
      )}
      {updateDialogInitialData && (
        <UpdatePaymentRequestDialog
          isOpen={updateDialogOpen}
          onClose={handleCloseUpdateDialog}
          onSubmit={handleUpdateSubmit}
          onDelete={handleRowDeleteAlert}
          onDuplicate={handleDuplicate}
          initialData={updateDialogInitialData}
          disabledSubmit={isPendingActions}
          disabledDuplicate={
            bankAccountsList.find(b => b.account_number === updateDialogInitialData.account_number)?.company.is_blocked
          }
        />
      )}
      <PaymentRqMainActions
        onCreate={handleCreateButton}
        onApprove={handleApproveButtonAlert}
        onReject={handleRejectButtonAlert}
        onDelete={handleDeleteButtonAlert}
        onDownload={handleOpenDownloadDialog}
        downloadDisable={isDownloadRegistry}
        onDateRangeChange={handleDateRangeChange}
        dateRange={paymentRequestTableStore.dateRange}
      />
      <DataTableGrid
        isLoading={isLoading}
        columns={columns}
        rows={rows}
        isSimpleTable={false}
        tablePageModel={userSettings.tablePageModel}
        tableFilterModel={userSettings.tableFilterModel}
        tableSortModel={userSettings.tableSortModel}
        tableVisibilityModel={userSettings.tableVisibilityModel}
        tableDensityMode={userSettings.tableDensityMode}
        tableColumnsWidth={userSettings.columnsWidth}
        tableColumnsOrder={userSettings.columnsOrder}
        saveTablePageData={userSettings.saveTablePageData}
        saveTableDensityMode={userSettings.saveTableDensityMode}
        saveTableColumnsWidth={userSettings.saveColumnsWidth}
        saveTableColumnsOrder={userSettings.saveColumnsOrder}
        saveTableSortData={userSettings.saveTableSortData}
        onRowClick={handleRowClick}
        onRowDoubleClick={handleRowDoubleClick}
        checkboxSelection={true}
        rowSelectionModel={rowSelectionModel}
        onChangeRowSelectionModel={handleRowsCheckboxSelection}
      />
      <Dialog open={downloadDialogOpen} onClose={handleCloseDownloadDialog} maxWidth={'md'} fullWidth>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Скачать реестр платежей для загрузки в банк
          <IconButton onClick={handleCloseDownloadDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText mb={'2%'}>Выберите банковский счет, с которого будем платить:</DialogContentText>

          <Autocomplete
            key={'payerBankAccount'}
            autoFocus
            fullWidth
            options={bankAccountsOptionList}
            getOptionLabel={(option: BankAutocompleteOption) => option.output}
            value={defaultBankAccountsOption}
            isOptionEqualToValue={(option, value) => option.account.account_number === value.account.account_number}
            onChange={(_, newValue) => setDefaultBankAccountsOption(newValue)}
            //todo: need update to mui 7 to use auto width
            sx={{
              width: {
                xs: '364px',
                sm: '552px',
                md: '852px',
              },
            }}
            renderInput={params => <TextField {...params} label="Счет" required={true} />}
          />
          <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ mt: '10%' }}>
            <Stack direction={'row'} spacing={2} justifyContent="space-between" alignItems={'center'}>
              <Typography>{`Всего заявок ${registryStatistics.all_count}`}</Typography>
              <Typography>{`Из них согласованных ${registryStatistics.approved}`}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={2} justifyContent="flex-end" alignItems={'center'}>
              <Button
                type={'button'}
                disabled={isDownloadRegistry}
                variant="outlined"
                color="primary"
                startIcon={<DownloadIcon />}
                sx={buttonSx}
                onClick={handleDownloadSubmit}
              >
                {`Выгрузить на сумму ${Number(registryStatistics.sum).toLocaleString('ru-RU')} ₽`}
              </Button>
              <Button type={'button'} sx={buttonSx} variant="outlined" onClick={handleCloseDownloadDialog}>
                Отмена
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
});
