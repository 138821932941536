import { IPaymentUpdateDialog } from '@src/service/types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { UpdateDialogAction } from './DialogAction/UpdateDialogAction';
import { UpdateDialogForm } from './DialogForm/UpdateDialogForm';

interface IUpdatePaymentRequestDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: IPaymentUpdateDialog) => void;
  onDelete: (id: string) => void;
  onDuplicate: (id: string) => void;
  disabledSubmit?: boolean;
  disabledDuplicate?: boolean;
  initialData: IPaymentUpdateDialog;
}

export const UpdatePaymentRequestDialog = memo<IUpdatePaymentRequestDialogProps>(
  ({ isOpen, onClose, initialData, disabledSubmit, disabledDuplicate, onSubmit, onDelete, onDuplicate }) => {
    /**
     * States
     */

    const [formData, setFormData] = useState<IPaymentUpdateDialog>(initialData);

    /**
     * Effects
     */

    useEffect(() => {
      setFormData(initialData);
    }, [initialData]);

    /**
     * Handlers
     */

    const handleFormDataChange = useCallback(
      <K extends keyof IPaymentUpdateDialog>(value: IPaymentUpdateDialog[K], field: K) => {
        setFormData(prevState => ({ ...prevState, [field]: value }));
      },
      [],
    );

    const handleSubmit = () => {
      onSubmit(formData);
    };
    const handleDelete = () => {
      onDelete(formData.id);
    };
    // const handleBlock
    const handleDuplicate = () => {
      onDuplicate(formData.id);
    };

    const handleClose = () => {
      onClose();
      setFormData(initialData);
    };

    /**
     * render
     */

    return (
      <Dialog open={isOpen} onClose={onClose} maxWidth="md" aria-modal="true">
        <DialogTitle variant={'h5'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Редактирование заявки на сумму {formData.amount} для {formData.company_name}
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <UpdateDialogForm formData={formData} onFormDataChange={handleFormDataChange} />
        <UpdateDialogAction
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          onDuplicate={handleDuplicate}
          disabledSubmit={disabledSubmit}
          disabledDuplicate={disabledDuplicate}
          onClose={handleClose}
        ></UpdateDialogAction>
      </Dialog>
    );
  },
);

UpdatePaymentRequestDialog.displayName = 'EditPaymentRequestDialog';
