import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { ThemeProvider } from '@src/theme';
import { DictStore } from '@src/store/DictStore';
import { PageProgressBar } from '@src/components/PageProgressBar/PageProgressBar';
import AuthStore from '@src/store/AuthStore';

interface IWrapperProps {
  children: React.ReactNode;
}

export const Wrapper: FC<IWrapperProps> = observer((props: IWrapperProps) => {
  const { children } = props;
  const { init: dictStoreInit, isLoading } = DictStore;
  const { isAuthenticated } = AuthStore;

  useEffect(() => {
    if (isAuthenticated) {
      void dictStoreInit();
    }
  }, [isAuthenticated, dictStoreInit]);

  return (
    <ThemeProvider>
      <PageProgressBar isLoading={isLoading}>{children}</PageProgressBar>
    </ThemeProvider>
  );
});
