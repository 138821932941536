import React, { FC, useEffect } from 'react';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { Dayjs } from 'dayjs';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { RangeDatePicker } from '@src/components/RangeDatePicker/RangeDatePicker';
import { AlertDialog } from '@src/components/AlertDialog/AlertDialog';

interface IDocumentTableFilterProps {
  date: DateRange<Dayjs>;
  onDateChanged: (range: DateRange<Dayjs>) => void;
  onCopyBtnClick: () => void;
  copyBtnDisabled: boolean;
  disableDeleteDialog: boolean;
  handleApplyDelete: () => void;
}

export const EditRunsTableFilter: FC<IDocumentTableFilterProps> = (props: IDocumentTableFilterProps) => {
  const { date, disableDeleteDialog, handleApplyDelete, copyBtnDisabled, onCopyBtnClick, onDateChanged } = props;
  const [open, setOpen] = React.useState(false);
  const [isCtrlReserved, setIsCtrlReserved] = React.useState(false);

  useEffect(() => {
    const onKeyDown = e => {
      if (e.key === 'Delete' && !disableDeleteDialog) {
        setOpen(true);
      }
      if (e.key === 'Control') {
        setIsCtrlReserved(true);
      } else if (e.key === 'd' || e.key === 'D' || e.key === 'в' || e.key === 'В') {
        if (isCtrlReserved) {
          onCopyBtnClick();
          e.preventDefault();
        }
      }
    };

    const onKeyUp = e => {
      if (e.key === 'Control') {
        setIsCtrlReserved(false);
      }
    };

    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [disableDeleteDialog, isCtrlReserved, onCopyBtnClick]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setOpen(false);
    handleApplyDelete();
  };

  return (
    <Box sx={{ display: 'flex', marginLeft: '5px', marginBottom: '15px', gap: '8px' }}>
      <RangeDatePicker date={date} onDateChanged={onDateChanged} />
      <Button variant="outlined" onClick={onCopyBtnClick} disabled={copyBtnDisabled}>
        Дублировать строку
      </Button>
      <AlertDialog
        title={'Удаление записи'}
        content={'Вы уверены, что хотите удалить данную запись?'}
        handleApply={handleOk}
        isOpen={open}
        handleCancel={handleClose}
      />
    </Box>
  );
};
