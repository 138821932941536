import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
  Stack,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { IBankAccountExRsDto, ICompanyExRsDto } from '@src/service/types';
import { toast } from 'react-toastify';
import { BankAccountsTable } from '@src/components/Tables/CompanyTable/components/BankAccountsTable';
import DeleteIcon from '@mui/icons-material/Delete';
import { Close as CloseIcon } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';

interface IFormInputs {
  id: string;
  name: string;
  inn: string;
  kpp: string;
  bank_accounts: IBankAccountExRsDto[];
  is_blocked: boolean;
}

interface IEditCompanyDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: ICompanyExRsDto) => void;
  onDelete: () => void;
  initialData: ICompanyExRsDto;
  disabled?: boolean;
}

const buttonSx = {
  textTransform: 'none',
  borderRadius: 2,
  borderWidth: 2,
  padding: '6px 16px',
  '&:hover': {
    borderWidth: 2,
  },
};

const validators = {
  name: {
    required: 'Наименование обязательно',
    maxLength: {
      value: 255,
      message: 'Максимальная длина 255 символов',
    },
  },
  inn: {
    required: 'ИНН обязателен',
    pattern: {
      value: /^\d{10}(\d{2})?$/,
      message: 'ИНН должен состоять из 10 или 12 цифр',
    },
  },
  kpp: {
    pattern: {
      value: /^\d{9}$/,
      message: 'КПП должен состоять из 9 цифр',
    },
  },
};

export const UpdateCompanyDialog: FC<IEditCompanyDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  disabled,
  onDelete,
  initialData,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    reset,
    control,
  } = useForm<IFormInputs>({
    mode: 'onChange',
    defaultValues: initialData,
  });

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [confirmInput, setConfirmInput] = useState('');

  useEffect(() => {
    reset(initialData);
  }, [isOpen, initialData, reset]);

  useEffect(() => {
    if (showDeleteConfirmation) {
      setConfirmInput('');
    }
  }, [showDeleteConfirmation]);

  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirm = () => {
    if (confirmInput === initialData.name) {
      onDelete();
      setShowDeleteConfirmation(false);
      onClose();
    } else {
      toast.error('Введенное имя не совпадает с именем компании. Удаление невозможно.');
    }
  };

  const handleCloseDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
    setConfirmInput('');
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} maxWidth={'md'} fullWidth>
        <DialogTitle variant={'h4'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Редактирование компании
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1.5}>
              <TextField
                {...register('name', validators.name)}
                autoFocus
                label="Наименование"
                fullWidth
                variant="standard"
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              />
              <TextField {...register('inn', validators.inn)} label="ИНН" fullWidth variant="standard" disabled />
              <TextField
                {...register('kpp', validators.kpp)}
                label="КПП"
                fullWidth
                variant="standard"
                error={Boolean(errors.kpp)}
                helperText={errors.kpp?.message}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  maxLength: 9,
                }}
              />
              <BankAccountsTable accounts={initialData.bank_accounts} companyId={initialData.id} />
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Stack direction="row" spacing={2}>
                  <Button
                    type="button"
                    onClick={handleDeleteClick}
                    variant="outlined"
                    color="error"
                    sx={{
                      ...buttonSx,
                      '&:hover': {
                        borderColor: 'error.dark',
                        borderWidth: 2,
                      },
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                  <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                      <Controller
                        name="is_blocked"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={value}
                                onChange={onChange}
                                color="error"
                                aria-label="payment-blocked-toggle"
                              />
                            }
                            label="Блокировка компании"
                          />
                        )}
                      />
                    </FormGroup>
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Button
                    type="submit"
                    disabled={disabled || !isDirty || !isValid}
                    variant="outlined"
                    color="primary"
                    sx={buttonSx}
                  >
                    Изменить
                  </Button>
                  <Button type="button" onClick={onClose} variant="outlined" sx={buttonSx}>
                    Отмена
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog open={showDeleteConfirmation} onClose={handleCloseDeleteConfirmation}>
        <DialogTitle
          id="delete-confirmation-title"
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          Подтверждение удаления
          <IconButton onClick={handleCloseDeleteConfirmation}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Для подтверждения удаления введите наименование компании: <b>{initialData.name}</b>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            value={confirmInput}
            onChange={e => setConfirmInput(e.target.value)}
            label="Наименование компании"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={handleCloseDeleteConfirmation} variant="outlined" sx={buttonSx}>
            Отмена
          </Button>
          <Button
            type="button"
            onClick={handleDeleteConfirm}
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            sx={{
              ...buttonSx,
              '&:hover': {
                borderColor: 'error.dark',
                borderWidth: 2,
              },
            }}
          >
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
