import { IPaymentCreateDialog } from '@src/service/types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { CreateDialogAction } from './DialogAction/CreateDialogAction';
import { CreateDialogForm } from './DialogForm/CreateDialogForm';

interface ICreatePaymentRequestDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: IPaymentCreateDialog) => void;
  disabled?: boolean;
  initialData: IPaymentCreateDialog;
}

export const CreatePaymentRequestDialog = memo<ICreatePaymentRequestDialogProps>(
  ({ isOpen, onClose, initialData, disabled, onSubmit }) => {
    const [formData, setFormData] = useState<IPaymentCreateDialog>(initialData);

    /**
     * Handlers
     */

    const handleFormDataChange = (value: string, field: keyof IPaymentCreateDialog) => {
      setFormData(prevState => ({ ...prevState, [field]: value }));
    };
    /**
     * Effects
     */

    useEffect(() => {
      setFormData(initialData);
    }, [initialData, isOpen]);

    /**
     * Children Handlers
     */

    const handleSubmit = useCallback(() => {
      onSubmit(formData);
    }, [formData, onSubmit]);

    /**
     * render
     */
    return (
      <Dialog open={isOpen} onClose={onClose} maxWidth="md" aria-modal="true">
        <DialogTitle variant={'h5'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Создание заявки на оплату
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <CreateDialogForm formData={formData} onFormDataChange={handleFormDataChange} />
        <CreateDialogAction onSubmit={handleSubmit} disabled={disabled} onClose={onClose}></CreateDialogAction>
      </Dialog>
    );
  },
);

CreatePaymentRequestDialog.displayName = 'CreatePaymentRequestDialog';
