import { z } from 'zod';

const envSchema = z.object({
  WEBSOCKET_API_URL: z.string().default('ws://testing.rwtarif.com/api/v2/ws/'),
  MUI_X_PREMIUM_LICENSE_KEY: z.string().default(''),
});

export type EnvConfig = z.infer<typeof envSchema>;

export const config: EnvConfig = envSchema.parse({
  WEBSOCKET_API_URL: process.env.WEBSOCKET_API_URL,
  MUI_X_PREMIUM_LICENSE_KEY: process.env.MUI_X_PREMIUM_LICENSE_KEY,
});
