import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { DialogContent, FormControlLabel, FormGroup, Switch, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import React, { FC, useMemo } from 'react';
import { IPaymentUpdateDialog, PaymentStatus } from '@src/service/types';
import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { paymentRequestTableStore } from '@src/components/Tables/PaymentRequestsTable/store/PaymentRequestTableStore';
import { paymentRequestMappers } from '@src/utils/mappers/tables/PaymentRequestMappers';
import { DialogFormAutocomplete } from '@src/components/Tables/PaymentRequestsTable/components/DialogFormAutocomlete/DialogFormAutocomplete';

interface IPaymentRequestDialogFormProps {
  formData: IPaymentUpdateDialog;
  onFormDataChange: <K extends keyof IPaymentUpdateDialog>(value: IPaymentUpdateDialog[K], field: K) => void;
}

export const UpdateDialogForm: FC<IPaymentRequestDialogFormProps> = ({ formData, onFormDataChange }) => {
  /**
   * States and memos
   */

  const statusList = useMemo(() => [...Object.values(PaymentStatus)], []);
  const displayStatusList = useMemo(
    () => [...statusList.map(s => paymentRequestMappers.statusToDisplay[s])],
    [statusList],
  );

  /**
   * Effects
   */

  /**
   * Handlers
   */

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof IPaymentUpdateDialog,
  ) => {
    if (e.target.value) {
      onFormDataChange(e.target.value, field);
    }
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    if (e.target.name === 'is_blocked' || e.target.name === 'is_paid') {
      onFormDataChange(value, e.target.name);
    }
  };

  const handleStatusChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof IPaymentUpdateDialog,
  ) => {
    const status = statusList.find(s => paymentRequestMappers.statusToDisplay[s] === e.target.value);
    if (status) {
      onFormDataChange(status, field);
    }
  };

  const handleCreatorChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const _creator_value = e.target.value;
    const _creator_id = paymentRequestTableStore.usersList.find(_user => _user.fio === _creator_value)?.id;
    if (_creator_value && _creator_id) {
      onFormDataChange(_creator_value, 'creator_name');
      onFormDataChange(_creator_id, 'creator_id');
    }
  };

  // memos

  return (
    <DialogContent>
      <Box
        mt={1}
        sx={{
          display: 'flex',
          width: '100%',
          flexGrow: 1,
        }}
      >
        <Grid container spacing={1}>
          <DialogFormAutocomplete formData={formData} onFormDataChange={onFormDataChange} />

          <Grid xs={12} md={7}>
            <TextField
              label={'Назначение платежа'}
              type={'text'}
              rows={9}
              multiline={true}
              fullWidth={true}
              onChange={e => handleInputChange(e, 'description')}
              value={formData.description || ''}
            />
          </Grid>
          <Grid container spacing={1} xs={12} md={5}>
            <Grid xs={6} md={6} key={'payment_date'}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                <DatePicker
                  format={'DD.MM.YYYY'}
                  label="Дата"
                  value={formData.payment_date ? dayjs(formData.payment_date) : null}
                  onChange={newValue => {
                    const dateStr = newValue ? newValue.format('YYYY-MM-DD') : '';
                    onFormDataChange(dateStr, 'payment_date');
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid xs={6} md={6} key={'status'}>
              <TextField
                label={'Статус'}
                select={true}
                value={paymentRequestMappers.statusToDisplay[formData.status]}
                onChange={e => handleStatusChange(e, 'status')}
                fullWidth={true}
              >
                {displayStatusList.map(status => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid xs={12} md={6} key={'amount'}>
              <TextField
                fullWidth={true}
                name={'amount'}
                label={'Сумма'}
                type="number"
                value={formData?.amount || ''}
                InputProps={{ startAdornment: <InputAdornment position="start">₽</InputAdornment> }}
                onChange={e => handleInputChange(e, 'amount')}
              />
            </Grid>
            <Grid xs={12} md={6} key={'creator_name'}>
              <TextField
                label={'Ответственный'}
                select={true}
                required={true}
                value={formData?.creator_name}
                onChange={e => handleCreatorChange(e)}
                fullWidth={true}
              >
                {paymentRequestTableStore.usersList.map(_user => (
                  <MenuItem key={`userMenuItem${_user.id}`} value={_user.fio}>
                    {_user.fio}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid xs={12} md={12} key={'expense_item'}>
              <TextField
                label={'Статья'}
                select={true}
                value={formData?.expense_item || ''}
                onChange={e => handleInputChange(e, 'expense_item')}
                fullWidth={true}
              >
                {paymentRequestTableStore.expenseList.map(expense => (
                  <MenuItem key={expense.id} value={expense.name}>
                    {expense.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid xs={12} md={12} key={'toggles'}>
              <FormControl component="fieldset" variant="standard" fullWidth>
                <FormGroup row sx={{ justifyContent: 'space-around' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        name={'is_blocked'}
                        onChange={(event, checked) => handleSwitchChange(event, checked)}
                        color="error"
                        aria-label="payment-blocked-toggle"
                        checked={formData.is_blocked}
                      />
                    }
                    label={'Блокировка'}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        name={'is_paid'}
                        onChange={(event, checked) => handleSwitchChange(event, checked)}
                        color="success"
                        aria-label={'block-toggle'}
                        checked={formData.is_paid}
                      />
                    }
                    label={'Оплачено'}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </DialogContent>
  );
};
