/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { observer } from 'mobx-react-lite';
import { PageProgressBar } from '@src/components/PageProgressBar/PageProgressBar';
import { GridColDef } from '@mui/x-data-grid-premium';
import { DataTableGrid } from '@src/components/DataTable/DataTableGrid';
import { BankAccountsTableStore } from '@src/components/Tables/CompanyTable/store/BankAccountsTableStore';
import { filterOperators } from '@src/utils/tables/utils';
import { IBankAccountExRsDto, IBankAccountCreateDto } from '@src/service/types';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import StarIcon from '@mui/icons-material/Star';
import { toast } from 'react-toastify';

import { Close as CloseIcon } from '@mui/icons-material';

import { AddBankAccountDialog } from './AddBankAccountDialog';

interface IBankAccountsTableProps {
  accounts: IBankAccountExRsDto[];
  companyId: string;
}

const buttonSx = {
  textTransform: 'none',
  borderRadius: 2,
  borderWidth: 2,
  padding: '6px 16px',
  '&:hover': {
    borderWidth: 2,
  },
};

export const BankAccountsTable: FC<IBankAccountsTableProps> = observer(({ accounts, companyId }) => {
  const { isPendingActions, mainAccountId, deleteBankAccount, setMainAccount, createBankAccount, list } =
    BankAccountsTableStore;
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [confirmInput, setConfirmInput] = useState('');
  const [selectedRowId, setSelectedRowId] = useState('0');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const selectedRowAcc = list.find(account => account.id === selectedRowId)?.account_number;

  /**
   * Effects
   */

  useEffect(() => {
    BankAccountsTableStore.setInitialAccounts(accounts);
  }, [accounts]);

  /**
   * Table
   */

  const columns: GridColDef[] = [
    {
      field: 'account_number',
      headerName: 'Счет',
      description: 'Счет',
      flex: 2,
      minWidth: 30,
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      resizable: true,
      filterOperators: filterOperators,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'bank_name',
      headerName: 'Банк',
      description: 'Банк',
      flex: 4,
      minWidth: 50,
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      resizable: true,
      filterOperators: filterOperators,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'bank_city',
      headerName: 'Город банка',
      description: 'Город банка',
      flex: 1,
      minWidth: 20,
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      resizable: true,
      filterOperators: filterOperators,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'bic',
      headerName: 'БИК',
      description: 'БИК',
      flex: 1,
      minWidth: 10,
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      resizable: true,
      filterOperators: filterOperators,
      headerClassName: 'super-app-theme--header',
    },

    {
      field: 'corresp_account',
      headerName: 'Корр счет',
      description: 'Корр счет',
      flex: 1,
      minWidth: 2,
      width: 10,
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      resizable: true,
      filterOperators: filterOperators,
      headerClassName: 'super-app-theme--header',
    },
  ];

  /**
   * Handlers
   */

  const handleRowClick = (id: string): void => {
    setSelectedRowId(id);
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedRowAcc && confirmInput === selectedRowAcc) {
      const success = await deleteBankAccount(selectedRowId, companyId);
      setShowDeleteConfirmation(false);
      if (success) {
        toast.success('Счет удален');
      } else {
        toast.error('Ошибка при удалении счета');
      }
      setSelectedRowId('0');
      setConfirmInput('');
    } else {
      toast.error('Введенный номер счета не совпадает');
    }
  };

  const handleCloseDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
    setConfirmInput('');
  };

  const handleSetMainAccount = async () => {
    if (mainAccountId === selectedRowId) {
      return;
    }
    const success = await setMainAccount(selectedRowId, companyId);
    if (success) {
      toast.success('Счет назначен основным');
    } else {
      toast.error('Ошибка обработки');
    }
  };

  const handleSubmitCreateBankAccount = useCallback(
    async (data: IBankAccountCreateDto) => {
      const success = await createBankAccount(data);
      if (success) {
        toast.success('Банковский счет добавлен');
        setAddDialogOpen(false);
      } else {
        toast.error('Ошибка при добавлении счета');
      }
    },
    [createBankAccount],
  );

  /**
   * Render
   */

  return (
    <>
      <Stack direction={'row'} alignItems="center" justifyContent="space-between" mb={2} mt={1}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h6">Банковские счета</Typography>
        </Box>
        <Stack direction="row" spacing={2}>
          <Button
            onClick={() => setAddDialogOpen(true)}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            sx={buttonSx}
          >
            Добавить
          </Button>

          <Button
            variant="outlined"
            onClick={handleSetMainAccount}
            disabled={selectedRowId === '0' || selectedRowId === mainAccountId}
            startIcon={<StarIcon />}
            sx={{
              ...buttonSx,
              borderColor: 'rgba(76, 215, 100, 0.5)',
              color: 'rgba(76, 215, 100, 1)',
              backgroundColor: 'rgba(76, 215, 100, 0.1)',
              '&:hover': {
                backgroundColor: 'rgba(76, 215, 100, 0.2)',
                borderColor: 'rgba(76, 215, 100, 0.7)',
                borderWidth: 2,
              },
              '&.Mui-disabled': {
                borderColor: 'rgba(76, 215, 100, 0.3)',
                borderWidth: 2,
                color: 'rgba(76, 215, 100, 0.3)',
              },
            }}
          >
            Назначить основным
          </Button>
          <Button
            onClick={handleDeleteClick}
            disabled={selectedRowId === '0'}
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            sx={{
              ...buttonSx,
              '&:hover': {
                borderColor: 'error.dark',
                borderWidth: 2,
              },
              '&.Mui-disabled': {
                borderWidth: 2,
              },
            }}
          >
            Удалить
          </Button>
        </Stack>
      </Stack>
      <PageProgressBar isLoading={isPendingActions}>
        <DataTableGrid
          onRowClick={handleRowClick}
          disableColumnFilter={true}
          columns={columns}
          rows={list}
          checkboxSelectionVisibleOnly={false}
          tableSortModel={[
            {
              field: 'id',
              sort: 'asc',
            },
          ]}
          hideFooterSelectedRowCount={true}
          rowHeight={30}
          isSimpleTable={true}
          getRowClassName={params => (params.id === mainAccountId ? 'main-account-row' : '')}
          tableVisibilityModel={{
            corresp_account: false, // Скрываем corresp_account
          }}
        />
      </PageProgressBar>
      <AddBankAccountDialog
        isOpen={isAddDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        onSubmit={handleSubmitCreateBankAccount}
        companyId={companyId}
      />
      <Dialog
        open={showDeleteConfirmation}
        onClose={handleCloseDeleteConfirmation}
        aria-labelledby="delete-confirmation-title"
      >
        <DialogTitle
          id="delete-confirmation-title"
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          Подтверждение удаления
          <IconButton aria-label="close" onClick={handleCloseDeleteConfirmation}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Для подтверждения удаления введите номер счета: <b>{selectedRowAcc}</b>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            value={confirmInput}
            onChange={e => setConfirmInput(e.target.value)}
            label="Номер счета"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation} variant="outlined" sx={buttonSx}>
            Отмена
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            disabled={selectedRowId === '0'}
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            sx={{
              ...buttonSx,
              '&:hover': {
                borderColor: 'error.dark',
                borderWidth: 2,
              },
            }}
          >
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
