/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  GRID_AGGREGATION_FUNCTIONS,
  GridCellSelectionModel,
  GridColDef,
  GridColumnResizeParams,
  GridColumnVisibilityModel,
  GridDensity,
  GridEventListener,
  GridFilterModel,
  GridPaginationModel,
  GridRowModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
  gridPaginatedVisibleSortedGridRowIdsSelector,
} from '@mui/x-data-grid-premium';
import { ruRU } from '@mui/x-data-grid/locales';
import { GridEditMode } from '@mui/x-data-grid/models/gridEditRowModel';

import Snackbar from '@mui/material/Snackbar/Snackbar';
import { Alert } from '@mui/lab';
import { AlertProps } from '@mui/material';

import { AutocompleteEditInputCell } from '@src/components/DataTable/components/AutocompleteEditInputCell/AutocompleteEditInputCell';

import { GridAggregationModel } from '@mui/x-data-grid-premium/hooks/features/aggregation/gridAggregationInterfaces';

import { autosizeOptions, checkboxColumn, checkIsTheSameRow } from '@src/components/DataTable/utils/DataTableGridUtils';
import { CustomToolbar } from '@src/components/DataTable/components/CustomToolbar/CustomToolbar';

import { GridGroupingColDefOverride } from '@mui/x-data-grid-pro/models/gridGroupingColDefOverride';

import type { GridAggregationFunction } from '@mui/x-data-grid-premium/hooks/features/aggregation';

import Box from '@mui/material/Box';

import { StyledDataGrid } from './StyledDataGrid';

export interface ICustomToolbarButtonProps {
  text: string;
  disabled?: boolean;
  hideIcon?: boolean;
  onClick: () => void;
}

interface IDataTableGridProps {
  // table data
  columns: GridColDef[];
  rows: object[];
  rowsForUpdate?: object[];
  notBooleanAsUpdateResult?: boolean;
  isSimpleTable?: boolean;
  rowHeight?: number;
  // table user settings from local storage
  tablePageModel?: GridPaginationModel;
  tableFilterModel?: GridFilterModel;
  tableSortModel?: GridSortModel;
  tableDensityMode?: GridDensity;
  tableVisibilityModel?: GridColumnVisibilityModel;
  tableColumnsWidth?: Map<string, number>;
  tableColumnsOrder?: string[];
  saveTablePageData?: (data: GridPaginationModel) => void;
  saveTableVisibilityData?: (data: GridColumnVisibilityModel) => void;
  saveTableSortData?: (data: GridSortModel) => void;
  saveTableFilterData?: (data: GridFilterModel) => void;
  saveTableDensityMode?: (data: GridDensity) => void;
  saveTableColumnsWidth?: (data: Map<string, number>) => void;
  saveTableColumnsOrder?: (data: string[]) => void;
  handleSveTableFilterData?: (data: GridFilterModel) => void;
  // table settings
  checkboxSelection?: boolean;
  hideFooterSelectedRowCount?: boolean;
  // table editing
  editMode?: GridEditMode;
  onRowEditStopForFields?: string[];
  optionsForEditField?: Map<string, string[]>;
  // preventEditModeFor?: IPreventEditFor;
  // column grouping
  rowGroupingColumnMode?: 'single' | 'multiple';
  rowGroupingFields?: string[];
  notHideGroupingDuplicateColumn?: boolean;
  defaultGroupingExpansionDepth?: number;
  // aggregation
  aggregationFields?: GridAggregationModel;
  aggregationFunctions?: GridAggregationFunction;
  // events
  mutationUpdate?: (obj: any) => Promise<boolean | any>;
  onRowClick?: (id: string) => void;
  onRowDoubleClick?: (params: GridRowParams) => void;
  isLoading?: boolean;
  onChangeRowSelectionModel?: (obj: GridRowSelectionModel) => void;
  onChangeCellSelectionModel?: (obj: GridCellSelectionModel) => void;
  // onCellKeyDownEvent?: (key: string) => void;
  // export
  exportFileName?: string;
  exportHeaders?: string[];
  // custom buttons
  toolbarCustomButtons?: ICustomToolbarButtonProps[];
  // styling
  cellsBackgroundColorsForFields?: string[];
  onRowEditStart?: GridEventListener<'rowEditStart'>;
  onRowEditStop?: GridEventListener<'rowEditStop'>;
  onCellEditStart?: GridEventListener<'cellEditStart'>;
  groupingColDef?: GridGroupingColDefOverride;
  disableColumnFilter?: boolean;
  disableColumnMenu?: boolean;
  disableColumnSelector?: boolean;
  getRowClassName?: (params: GridRowParams) => string;
  rowSelectionModel?: GridRowSelectionModel;
  checkboxSelectionVisibleOnly?: boolean;
}

export const DataTableGrid: FC<IDataTableGridProps> = (props: IDataTableGridProps) => {
  const apiRef = useGridApiRef();
  const {
    rows: initialRows,
    columns: initialColumns,
    tablePageModel,
    rowSelectionModel,
    tableFilterModel,
    tableSortModel,
    tableVisibilityModel,
    tableDensityMode,
    tableColumnsWidth,
    tableColumnsOrder,
    saveTablePageData,
    saveTableVisibilityData,
    saveTableSortData,
    saveTableFilterData,
    saveTableDensityMode,
    saveTableColumnsWidth,
    saveTableColumnsOrder,
    handleSveTableFilterData,
    checkboxSelection,
    rowHeight,
    hideFooterSelectedRowCount,
    editMode,
    mutationUpdate,
    onRowClick,
    onRowDoubleClick,
    exportFileName,
    exportHeaders,
    isLoading,
    onRowEditStopForFields,
    optionsForEditField,
    rowGroupingColumnMode,
    rowGroupingFields,
    aggregationFields,
    toolbarCustomButtons,
    onChangeRowSelectionModel,
    onChangeCellSelectionModel,
    isSimpleTable,
    onRowEditStart,
    onRowEditStop,
    groupingColDef,
    aggregationFunctions,
    notHideGroupingDuplicateColumn,
    onCellEditStart,
    rowsForUpdate,
    defaultGroupingExpansionDepth,
    cellsBackgroundColorsForFields,
    notBooleanAsUpdateResult,
    disableColumnFilter = false,
    disableColumnMenu = false,
    disableColumnSelector = false,
    getRowClassName,
    checkboxSelectionVisibleOnly = true,
  } = props;

  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);
  const [pageSize, setPageSize] = useState<number>(tablePageModel?.pageSize || 5);
  const [density, setDensity] = useState<string>(tableDensityMode || 'standard');

  const densityHeight = useMemo(() => {
    if (density === 'standard') {
      return 30;
    } else if (density === 'compact') {
      return 21;
    }

    return 39;
  }, [density]);

  const handleCloseSnackbar = () => setSnackbar(null);

  useEffect(() => {
    if (rowsForUpdate !== undefined && rowsForUpdate.length > 0) {
      apiRef.current.updateRows(rowsForUpdate);
    }
  }, [apiRef, rowsForUpdate]);

  const processRowUpdate = useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve, reject) => {
        const isMutation = !checkIsTheSameRow(newRow, oldRow);
        if (mutationUpdate !== undefined && isMutation) {
          (async function () {
            const res = await mutationUpdate(newRow);

            if (res) {
              if (notBooleanAsUpdateResult) {
                resolve(res);
              } else {
                resolve(newRow);
              }
            } else {
              const text = `Ошибка во время обновления записи: ${res}`;
              console.error(text);
              setSnackbar({ children: text, severity: 'error' });
              reject(oldRow);
            }
          })();
        } else {
          resolve(oldRow);
        }
      }),
    [mutationUpdate, notBooleanAsUpdateResult],
  );

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      pagination: {
        paginationModel: tablePageModel,
      },
      filter: {
        filterModel: tableFilterModel,
      },
      sorting: {
        sortModel: tableSortModel,
      },
      columns: {
        columnVisibilityModel: tableVisibilityModel,
        orderedFields: tableColumnsOrder,
      },
      rowGrouping: {
        model: rowGroupingFields,
      },
      density: tableDensityMode,
      aggregation: {
        model: aggregationFields,
      },
    },
  });

  const columns = useMemo(() => {
    return [
      checkboxColumn(apiRef),
      ...initialColumns.map(col => {
        // устанавливаем сохраненные юзером ширины колонок
        col.width = tableColumnsWidth?.get(col.field);
        if (tableColumnsWidth?.has(col.field)) {
          col.flex = undefined;
        }

        if (col.type !== 'singleSelect') {
          return col;
        }

        return {
          ...col,
          type: undefined,
          renderEditCell: params => {
            if (optionsForEditField === undefined) {
              return;
            }

            const options = optionsForEditField?.get(params.field);
            if (options !== undefined) {
              return (
                <AutocompleteEditInputCell
                  params={params}
                  value={params.formattedValue}
                  options={options}
                  freeSolo={false}
                  multiple={false}
                  apiRef={apiRef}
                />
              );
            }
          },
        };
      }),
    ];
  }, [apiRef, initialColumns, optionsForEditField, tableColumnsWidth]);

  useEffect(() => {
    apiRef.current.subscribeEvent('columnHeaderDragEnd', () => {
      saveTableColumnsOrder && saveTableColumnsOrder(apiRef.current.getAllColumns().map(col => col.field));
    });
  }, [apiRef, saveTableColumnsOrder]);

  useEffect(() => {
    tablePageModel && apiRef.current.setPaginationModel(tablePageModel);
  }, [apiRef, tablePageModel]);

  useEffect(() => {
    tableFilterModel && apiRef.current.setFilterModel(tableFilterModel);
  }, [apiRef, tableFilterModel]);

  useEffect(() => {
    tableSortModel && apiRef.current.setSortModel(tableSortModel);
  }, [apiRef, tableSortModel]);

  useEffect(() => {
    tableVisibilityModel && apiRef.current.setColumnVisibilityModel(tableVisibilityModel);
  }, [apiRef, tableVisibilityModel]);

  useEffect(() => {
    rowGroupingFields && apiRef.current.setRowGroupingModel(rowGroupingFields);
  }, [apiRef, rowGroupingFields]);

  useEffect(() => {
    // для исключения дубликатов скрываем колонки, по которым организована группировка
    if (!notHideGroupingDuplicateColumn) {
      const columnsToHide = initialColumns.filter(col => col.groupable).map(col => col.field);

      columnsToHide.forEach(col => {
        if (tableVisibilityModel) {
          tableVisibilityModel[col] = false;
        }
      });
    }
  }, [columns, notHideGroupingDuplicateColumn, initialColumns, tableVisibilityModel]);

  useEffect(() => {
    const handleRowClick: GridEventListener<'rowClick'> = params => {
      if (onRowClick !== undefined) {
        onRowClick(params.row.id);
      }
    };

    // The `subscribeEvent` method will automatically unsubscribe in the cleanup function of the `useEffect`.
    return apiRef.current.subscribeEvent('rowClick', handleRowClick);
  }, [apiRef, onRowClick]);

  const onColumnWidthChange = (params: GridColumnResizeParams) => {
    tableColumnsWidth?.set(params.colDef.field, params.width);
    saveTableColumnsWidth && tableColumnsWidth && saveTableColumnsWidth(tableColumnsWidth);
  };

  const toolbarSlot = useMemo(
    () =>
      isSimpleTable
        ? undefined
        : props => (
            <CustomToolbar
              {...props}
              toolbarCustomButtons={toolbarCustomButtons}
              isLoading={isLoading}
              exportHeaders={exportHeaders}
              exportFileName={exportFileName}
            />
          ),
    [exportFileName, exportHeaders, isLoading, isSimpleTable, toolbarCustomButtons],
  );

  const slots = {
    toolbar: toolbarSlot,
  };

  const tableLayoutHeight = useMemo(() => {
    const h =
      209 +
      pageSize * densityHeight +
      (aggregationFields ? densityHeight : 0) +
      (aggregationFields && aggregationFunctions ? pageSize * densityHeight : 0);

    const tableContentH = window.innerHeight - 220;
    const maxH = tableContentH - densityHeight;

    return h < maxH ? h : maxH;
  }, [aggregationFields, aggregationFunctions, densityHeight, pageSize]);

  const handleRowSelectionModelChange = (newModel: GridRowSelectionModel) => {
    if (!onChangeRowSelectionModel) return;
    if (newModel.length === initialRows.length) {
      const visibleRows = gridPaginatedVisibleSortedGridRowIdsSelector(apiRef);
      onChangeRowSelectionModel(visibleRows);
    } else {
      onChangeRowSelectionModel(newModel);
    }
  };

  const handleFilterModelChange = useCallback(
    (value: GridFilterModel, details: any) => {
      if (saveTableFilterData) {
        saveTableFilterData(value);
      }
      if (
        handleSveTableFilterData &&
        details.reason &&
        ['filterItemEdited', 'filterItemRemoved', 'filterItemAdded'].includes(details.reason)
      ) {
        handleSveTableFilterData(value);
        if (onChangeRowSelectionModel) onChangeRowSelectionModel([]);
      }
    },
    [saveTableFilterData, handleSveTableFilterData, onChangeRowSelectionModel],
  );

  return (
    <Box sx={{ height: tableLayoutHeight, width: '100%' }}>
      <StyledDataGrid
        disableColumnFilter={disableColumnFilter}
        disableColumnMenu={disableColumnMenu}
        disableColumnSelector={disableColumnSelector}
        rowSelectionModel={rowSelectionModel}
        groupingColDef={groupingColDef}
        apiRef={apiRef}
        rows={initialRows}
        onRowDoubleClick={onRowDoubleClick}
        columns={columns}
        initialState={initialState}
        getCellClassName={params => {
          const colorify = () => {
            if (params.row[`${params.field}_color`] === 2) {
              return 'hot';
            } else if (params.row[`${params.field}_color`] === 1) {
              return 'cold';
            }

            return '';
          };

          if (cellsBackgroundColorsForFields?.includes(params.field)) {
            return colorify();
          }

          return '';
        }}
        onDensityChange={density => {
          if (tableDensityMode !== density && saveTableDensityMode) {
            saveTableDensityMode(density);
          }
          setDensity(density);
        }}
        aggregationFunctions={
          aggregationFunctions
            ? {
                ...GRID_AGGREGATION_FUNCTIONS,
                custom: aggregationFunctions,
              }
            : { ...GRID_AGGREGATION_FUNCTIONS }
        }
        ignoreValueFormatterDuringExport={true}
        headerFilters={!isSimpleTable}
        autosizeOptions={autosizeOptions}
        disableColumnResize={false}
        hideFooter={isSimpleTable}
        onColumnWidthChange={onColumnWidthChange}
        onPaginationModelChange={value => {
          if (saveTablePageData) {
            saveTablePageData(value);
          }
          setPageSize(value.pageSize);
        }}
        onFilterModelChange={handleFilterModelChange}
        onSortModelChange={saveTableSortData}
        onColumnVisibilityModelChange={saveTableVisibilityData}
        rowHeight={rowHeight ?? 30}
        hideFooterSelectedRowCount={isSimpleTable ?? hideFooterSelectedRowCount}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        getRowClassName={params => {
          const baseClass = params.indexRelativeToCurrentPage % 2 === 0 ? 'super-app-theme' : 'super-app-theme-even';
          const customClass = getRowClassName ? getRowClassName(params) : '';

          return [baseClass, customClass].filter(Boolean).join(' ');
        }}
        slots={slots}
        checkboxSelection={checkboxSelection}
        disableRowSelectionOnClick={true}
        checkboxSelectionVisibleOnly={checkboxSelectionVisibleOnly}
        pagination={!isSimpleTable}
        cellSelection={true}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        editMode={editMode}
        processRowUpdate={processRowUpdate}
        rowGroupingColumnMode={rowGroupingColumnMode}
        defaultGroupingExpansionDepth={
          defaultGroupingExpansionDepth
            ? defaultGroupingExpansionDepth
            : rowGroupingFields
              ? rowGroupingFields.length - 1
              : 0
        }
        onCellEditStart={onCellEditStart}
        onRowEditStart={onRowEditStart}
        onRowEditStop={(params, event, details) => {
          if (params.field && onRowEditStopForFields?.includes(params.field) && params.reason === 'enterKeyDown') {
            event.defaultMuiPrevented = true;
          }

          if (onRowEditStop) {
            onRowEditStop(params, event, details);
          }
        }}
        onCellSelectionModelChange={(newModel: GridCellSelectionModel) => {
          onChangeCellSelectionModel && onChangeCellSelectionModel(newModel);
        }}
        onRowSelectionModelChange={handleRowSelectionModelChange}
      />
      {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Box>
  );
};
