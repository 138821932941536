export interface IDictApiService {
  getDictCars(): Promise<IDictCarDto[]>;

  getDictDrivers(): Promise<IDictDriverDto[]>;
}

export interface IDataApiService {
  getInvoices(dto: IDayRqDto): Promise<IDataInvoiceDto[]>;
}

export interface IRunApiService {
  getListRuns(dto: IDaysRqDto): Promise<IRunDto[]>;

  createRun(dto: ICreateRunDto): Promise<IRunDto>;

  createByInvoiceRun(dto: ICreateByInvoiceRunDto): Promise<number>;

  updateRun(dto: IRunDto): Promise<boolean>;

  updateMultipleRun(dto: IRunDto[]): Promise<IRunDto[]>;

  deleteRun(id: string): Promise<boolean>;
}

export interface ITransportDataTableApiService {
  getListRuns(dto: IDaysRqDto): Promise<ITransportDataTableDto[]>;

  updateMultipleRun(dto: ITransportDataTableUpdateRq[]): Promise<boolean>;

  mutateManyWithSameValues(dto: ITransportDataTableUpdateRqPartial[]): Promise<boolean>;
}

export interface IClientDocumentsTableApiService {
  getListRuns(dto: IDaysRqDto): Promise<IClientDocumentsTableDto[]>;

  updateMultipleRun(dto: IClientDocumentsTableUpdateRq[]): Promise<boolean>;

  mutateManyWithSameValues(dto: IClientDocumentsTableDtoPartial[]): Promise<boolean>;
}

export interface IDocumentApiService {
  createDocument(dto: ICreateDocument): Promise<boolean>;

  createMultipleDocument(dto: ICreateDocument[]): Promise<boolean>;
}

export interface ICargoApiService {
  getStocked(): Promise<ICargoStockBalance[]>;
}

export interface ICargoDocumentApiService {
  getList(dto: ICargoDocumentRqDto): Promise<ICargoDocumentRsDto[]>;
}

export interface ICounterpartyApiService {
  getList(): Promise<ICounterpartyDto[]>;

  getById(id: string): Promise<ICounterpartyDto>;
}

export interface ILogisticsDailyPlanApiService {
  getList(date: string): Promise<ILogisticsDailyPlanDto[]>;

  update(dto: ILogisticsDailyPlanDto): Promise<boolean>;
}

export interface IUserEventLogApiService {
  getUserEventLogs(dto: IDaysRqDto): Promise<IUserEventLogDto[]>;
}

export interface IAuthApiService {
  login(dto: ILoginRqDto): Promise<ILoginRsDto>;

  refresh(dto: IRefreshRqDto): Promise<IRefreshRsDto>;

  logout(): Promise<string>;
}

export interface ICompanyApiService {
  createCompany(dto: ICompanyCreateDto): Promise<ICompanyExRsDto>;

  updateCompany(id: string, dto: ICompanyUpdateDto): Promise<ICompanyExRsDto>;

  getCompany(id: string): Promise<ICompanyExRsDto>;

  getAllCompanies(): Promise<ICompanyExRsDto[]>;

  deleteCompany(id: string): Promise<boolean>;
}

export interface IBankAccountApiService {
  getAll(): Promise<IBankAccountExRsDto[]>;

  createBankAccount(dto: IBankAccountCreateDto): Promise<IBankAccountExRsDto>;

  deleteBankAccount(id: string): Promise<boolean>;

  updateBankAccount(id: string, dto: IUpdateBankAccountDto): Promise<IBankAccountExRsDto>;

  getMyBankAccounts(): Promise<IBankAccountExRsDto[]>;
}

export interface IPaymentRequestApiService {
  getById(id: string): Promise<IPaymentRequestRsDto>;

  getDatedList(dto: IDaysRqDto): Promise<IPaymentRequestRsDto[]>;

  createPaymentRequest(dto: ICreatePaymentRequestDto): Promise<IPaymentRequestRsDto>;

  deletePaymentRequest(id: string): Promise<boolean>;

  updatePaymentRequest(id: string, dto: IUpdatePaymentRequestDto): Promise<IPaymentRequestRsDto>;

  approveMany(ids: string[]): Promise<boolean>;

  rejectMany(ids: string[]): Promise<boolean>;

  deleteMany(ids: string[]): Promise<boolean>;

  downloadPaymentsRegistry(dto: IPaymentRegistryRq): Promise<boolean>;
}

export interface IExpenseItemApiService {
  getAll(): Promise<IExpenseItemRsDto[]>;

  createExpenseItem(dto: ICreateExpenseItemDto): Promise<IExpenseItemRsDto>;

  updateExpenseItem(id: string, dto: IUpdateExpenseItemDto): Promise<IExpenseItemRsDto>;

  deleteExpenseItem(id: string): Promise<boolean>;
}
export interface IUserApiService {
  getAll(): Promise<IUserMinRsDto[]>;
  getMe(): Promise<IUserMinRsDto>;
}

export interface ITransportDataTableDto {
  item_id: string; // ИД рейса
  //car_id: number; // ИД машины
  car_owner: string; // собственник машины, при создании рейса данные берутся из таблицы cars и замораживаются в рейсе
  car_plate_number: string; // номер авто
  //driver_id: number; // ИД водителя
  driver_fio: string; // ФИО водителя, при создании рейса данные берутся из таблицы drivers и замораживаются в рейсе
  date_departure: string; // Дата погрузки
  date_arrival?: string; // Дата выгрузки
  weight: number | null; // Вес груза на погрузке
  weight_arrival: number | null; // Вес груза на выгрузке
  //client_weight: number; // Вес груза по данным клиента на выгрузке
  //client_weight_arrival: number; // Вес груза по данным клиента на выгрузке
  weight_color: number; // служебное поле для закрашивания ячейки с весом груза
  weight_arrival_color: number; // служебное поле для закрашивания ячейки с весом груза
  tn_document_name: string | null;
  pl_document_name: string | null;
  registry_document_name: string | null;
  carrier_invoice: string | null; // УПД перевозчика
  client: string;
  route: string;
  cargo: string;
  carrier_price: number | null; // ставка наемного перевозчика за тонну перевезенного груза
  // trailer_id: number; // ИД прицепа, при создании рейса данные берутся из таблицы cars и замораживаются в рейсе
  trailer_description: string; // Наименование прицепа, при создании рейса данные берутся из таблицы cars и замораживаются в рейсе
}

export interface IClientDocumentsTableDto {
  item_id: string; // ИД рейса
  //car_id: number; // ИД машины
  //car_owner: string; // собственник машины, при создании рейса данные берутся из таблицы cars и замораживаются в рейсе
  car_plate_number: string; // номер авто
  //driver_id: number; // ИД водителя
  driver_fio: string; // ФИО водителя, при создании рейса данные берутся из таблицы drivers и замораживаются в рейсе
  driver_license: string | null; // Водительское удостоверение
  date_departure: string; // Дата погрузки
  date_arrival?: string; // Дата выгрузки
  weight: number; // Вес груза на погрузке
  weight_arrival: number; // Вес груза на выгрузке
  //client_weight: number; // Вес груза по данным клиента на выгрузке
  //client_weight_arrival: number; // Вес груза по данным клиента на выгрузке
  weight_color: number; // служебное поле для закрашивания ячейки с весом груза
  weight_arrival_color: number; // служебное поле для закрашивания ячейки с весом груза
  tn_document_name: string | null;
  client_registry_name: string | null;
  client_invoice_name: string | null;
  supplier_invoice_name: string | null;
  client: string;
  route: string;
  cargo: string;
  supplier: string; // Поставщик по заявке
  //carrier_price: string; // ставка наемного перевозчика за тонну перевезенного груза
  //trailer_id: number; // ИД прицепа, при создании рейса данные берутся из таблицы cars и замораживаются в рейсе
  client_price: number | null; // ставка Заказчику за тонну
  run_price: number | null; // ставка Заказчику за рейс
  trailer_description: string; // Наименование прицепа, при создании рейса данные берутся из таблицы cars и замораживаются в рейсе
}

export type IClientDocumentsTableDtoPartial = Partial<Omit<IClientDocumentsTableDto, 'item_id'>> & {
  item_id: string; // ИД рейса
};

export interface ITransportDataTableUpdateRq {
  item_id: string; // ИД рейса
  car_id: number; // ИД машины
  driver_id: number; // ИД водителя
  date_departure: string; // Дата погрузки
  date_arrival?: string; // Дата выгрузки
  weight: number | null; // Вес груза на погрузке
  weight_arrival: number | null; // Вес груза на выгрузке
  weight_color: number; // служебное поле для закрашивания ячейки с весом груза
  weight_arrival_color: number; // служебное поле для закрашивания ячейки с весом груза
  tn_document_name: string | null;
  pl_document_name: string | null;
  registry_document_name: string | null;
  carrier_invoice: string | null; // УПД перевозчика
  carrier_price: number | null; // ставка наемного перевозчика за тонну перевезенного груза
}

export type ITransportDataTableUpdateRqPartial = Partial<Omit<ITransportDataTableUpdateRq, 'item_id'>> & {
  item_id: string; // ИД рейса
};

export interface IClientDocumentsTableUpdateRq {
  item_id: string; // ИД рейса
  car_id: number; // ИД машины
  driver_id: number; // ИД водителя
  date_departure: string; // Дата погрузки
  date_arrival?: string; // Дата выгрузки
  weight: number; // Вес груза на погрузке
  weight_arrival: number; // Вес груза на выгрузке
  weight_color: number; // служебное поле для закрашивания ячейки с весом груза
  weight_arrival_color: number; // служебное поле для закрашивания ячейки с весом груза
  // tn_document_name: string | null;
  client_registry_name: string | null;
  client_invoice_name: string | null;
}

// Расширенная модель рейса для отражения информации из базы данных
export interface IRunDto {
  item_id: string; // ИД рейса
  weight: number; // Вес груза на погрузке
  date_departure: string; // Дата погрузки
  date_arrival?: string; // Дата выгрузки
  car_id: number; // ИД машины
  driver_id: number; // ИД водителя
  weight_arrival: number; // Вес груза на выгрузке
  client_weight: number; // Вес груза по данным клиента на выгрузке
  client_weight_arrival: number; // Вес груза по данным клиента на выгрузке
  weight_color: number; // служебное поле для закрашивания ячейки с весом груза
  weight_arrival_color: number; // служебное поле для закрашивания ячейки с весом груза
  run_status: string; // статус рейса, ограничен перечислением RunStatus
  comment: string; // комментарий к рейсу (например, комментарий водителя в случае отказа от рейса)
  car_description: string; // наименование машины, при создании рейса данные берутся из таблицы cars и замораживаются в рейсе
  car_owner: string; // собственник машины, при создании рейса данные берутся из таблицы cars и замораживаются в рейсе
  car_plate_number: string; // номер авто
  driver_fio: string; // ФИО водителя, при создании рейса данные берутся из таблицы drivers и замораживаются в рейсе
  driver_company: string; // компания-наниматель водителя, при создании рейса данные берутся из таблицы drivers и замораживаются в рейсе
  trailer_id: number; // ИД прицепа, при создании рейса данные берутся из таблицы cars и замораживаются в рейсе
  trailer_description: string; // Наименование прицепа, при создании рейса данные берутся из таблицы cars и замораживаются в рейсе
  carrier_price: string; // ставка наемного перевозчика за тонну перевезенного груза
  cars_group: string; // колонна, к которой относится автомобиль, при создании рейса данные берутся из таблицы cars и замораживаются в рейсе
  // invoice_id: number;
  // reg_date: string;
  // acc_date: string;
  // client: string;
  // route: string;
  // cargo: string;
  // waybill: string;
  // invoice_document: string;
  invoice: IDataInvoiceDto; // Заявка заказчика, является ссылкой на другую схему и таблицу, см. InvoiceSchema
  documents: IDocument[]; // Список документов, привязанных к рейсу. Является ссылкой на другую схему и таблицу, см. DocumentSchema
}

export interface ICreateRunDto {
  invoice_id: string;
  car_id: number;
  weight: number;
  date_departure: string;
  driver_id: number;
}

export interface ICreateByInvoiceRunDto {
  invoice_id: string;
  date_departure: string;
  cars: number[];
}

export interface ILogisticsDailyPlanDto {
  invoice_id: string;
  date_departure: string;
  cars: number[];
}

// Модель заявки Заказчика для отражения информации из базы данных
export interface IDataInvoiceDto {
  item_id: string; // ИД груза
  order_num: string; // Номер заявки из 1С
  order_date: string; // Дата заявки из 1С
  order_version: number; // Версия заявки
  departure_point: string; // Пункт погрузки
  arrival_point: string; // Пункт выгрузки
  route_time: string; // Время прохождения маршрута
  shipper: string; // Отправитель
  consignee: string; // Получатель
  arrival_date: string; // Дата окончания действия заявки
  shipper_contact_person: string; // Контактное лицо отправителя
  consignee_contact_person: string; // Контактное лицо получателя
  client: string; // Заказчик перевозки
  client_contract: string; // Договор Заказчика
  route: string; // Маршрут
  route_id: number; // Ид маршрута
  cargo: string; // Груз
  cargo_id: number; // ИД груза
  weight: number; // Вес груза к перевозке
  price: string; // Стоимость перевозки 1 тонны
  price_sum: string; // Стоимость перевозки по заявке
  volume: string; // Объем груза к перевозке
  departure_date: string; // Дата начала действия заявки
  distance: string; // Расстояние по маршруту
  actual: boolean; // флаг актуальности заявки
  supplier: string; // Поставщик по заявке
  cargo_price: string; // Стоимость закупки груза
  comment: string; // Комментарий
  // weight_arrival: number;
}

// Модель документа для отражения информации из базы данных
export interface IDocument {
  item_id: string | null; // ИД документа
  name: string; // Название документа
  doc_date: string; // Дата документа
  comment: string | null; // Комментарий к документу
  run_id: string; // ИД рейса, к которому привязан документ
  doc_type_obj: IDocumentType | null; // Тип документа. Является ссылкой на другую схему и таблицу, см. DocumentType
  doc_type: number; // Тип документа. Является ссылкой на другую схему и таблицу, см. DocumentType
}

// Модель документа для отражения информации из базы данных
export interface IUpdateDocument {
  item_id: string | null; // ИД документа
  name: string; // Название документа
  doc_date: string; // Дата документа
  doc_type: number; // Тип документа. Является ссылкой на другую схему и таблицу, см. DocumentType
}

export interface ICargoStockBalance {
  item_id: string;
  cargo_name: string;
  cargo_group: string;
  balance: string;
}

export interface ICounterpartyDto {
  item_id: string;
  company_name: string;
  inn: string;
  kpp: string;
  contract: string;
}

// [
//   {
//     "item_id": 1,
//     "document_type": "Путевой лист"
//   },
//   {
//     "item_id": 2,
//     "document_type": "ТН"
//   },
//   {
//     "item_id": 3,
//     "document_type": "ТТН"
//   },
//   {
//     "item_id": 4,
//     "document_type": "Реестр Заказчику"
//   },
//   {
//     "item_id": 6,
//     "document_type": "Реестр Перевозчика"
//   },
//   {
//     "item_id": 7,
//     "document_type": "УПД Заказчику"
//   },
//   {
//     "item_id": 8,
//     "document_type": "УПД Поставщика"
//   },
//   {
//     "item_id": 9,
//     "document_type": "УПД Перевозчика"
//   },
//   {
//     "item_id": 10,
//     "document_type": "Сертификат на продукцию"
//   }
// ]

export interface ICreateDocument {
  name: string; // Название документа
  doc_date: string; // Дата документа
  comment?: string; // Комментарий к документу
  run_id: string; // ИД рейса, к которому привязан документ
  doc_type: number; // Тип документа. Является ссылкой на другую схему и таблицу, см. DocumentType
}

// Модель типа документа для отражения информации из базы данных
export interface IDocumentType {
  item_id: string | null; // ИД типа документа
  document_type: string; // Наименование типа документа
}

export interface IDayRqDto {
  day: string;
}

export interface ICargoDocumentRqDto {
  date_from: string;
  date_to: string;
}

export interface ICargoDocumentRsDto {
  item_id: string;
  name: string;
  doc_date: string;
  transaction_type: string;
  quantity: string;
  created_at: string;
  counterparty_id: string;
  cargo: ICargoStockBalance;
}

export interface IDaysRqDto {
  start_day: string;
  end_day: string;
}

export interface IDictCarDto {
  item_id: number;
  description: string;
  plate_number: string;
  car_model: string;
  owner: string;
  vin: string;
  year: number;
  engine_hp: number;
  weight_capacity: number;
  volume: number;
  weight_own: number;
  car_type: string;
}

export interface IDictDriverDto {
  item_id: number;
  fio: string;
  code: string;
  position: string;
  inn: string;
  snils: string;
}

export interface ILoginRqDto {
  username: string;
  password: string;
}

export interface ILoginRsDto {
  access_token: string;
  refresh_token: string;
}

export interface IRefreshRqDto {
  token: string;
}

export interface IRefreshRsDto {
  access_token: string;
}

/**
 * Users DTOs.
 */

export interface IUserMinRsDto {
  id: string;
  fio: string;
}

export interface IUserEventLogDto {
  id: number;
  user_id: string;
  message: string;
  datetime: string;
}

/**
 * Companies DTOs.
 */

// Api part

export interface ICompanyBaseDto {
  inn: string;
  kpp?: string;
  name: string;
  is_blocked: boolean;
}
export interface ICompanyCreateDto extends ICompanyBaseDto {}

export interface ICompanyUpdateDto {
  kpp?: string;
  name?: string;
  is_blocked?: boolean;
}

export interface ICompanyRsDto extends ICompanyBaseDto {
  id: string;
}

export interface ICompanyExRsDto extends ICompanyRsDto {
  bank_accounts: IBankAccountExRsDto[];
}

// Presentation part

export interface ICompanyDisplayDto extends ICompanyRsDto {}

/**
 * Bank Accounts DTOs.
 */

export interface IBankAccountBaseDto {
  account_number: string;
  corresp_account?: string;
  bic: string;
  bank_name: string;
  bank_city?: string;
  is_main: boolean;
}
export interface IBankAccountCreateDto extends IBankAccountBaseDto {
  company__id: string;
}

export interface IBankAccountExRsDto extends IBankAccountBaseDto {
  id: string;
  company: ICompanyRsDto;
}

export type IUpdateBankAccountDto = Partial<IBankAccountCreateDto>;

export interface ICreateExpenseItemDto {
  name: string;
}

export interface IExpenseItemRsDto extends ICreateExpenseItemDto {
  id: string;
}

export type IUpdateExpenseItemDto = Omit<Partial<IPaymentRequestRsDto>, 'id'>;

/**
 * Payment DTOs.
 */

// Api part

export enum PaymentStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

interface IPaymentRequestBaseDto {
  payment_date: string;
  amount: string;
  description: string;
  status: PaymentStatus;
  is_blocked: boolean;
  is_paid: boolean;
  creator_id: string;
  creator_name: string;
}

export interface ICreatePaymentRequestDto extends IPaymentRequestBaseDto {
  bank_account__id: string;
  expense_item__id: string;
}

export interface IPaymentRequestRsDto extends IPaymentRequestBaseDto {
  id: string;
  bank_account__bank_name: string;
  bank_account__account_number: string;
  company__inn: string;
  company__kpp?: string;
  company__name: string;
  expense_item__name: string;
}

export interface IUpdatePaymentRequestDto {
  bank_account__id?: string;
  expense_item__id?: string;
  payment_date?: string;
  description?: string;
  amount?: string;
  status?: PaymentStatus;
  is_blocked?: boolean;
  is_paid?: boolean;
  creator_id?: string;
  creator_name?: string;
}

// Presentation part

export interface IPaymentRequestsTableDisplayDto extends IPaymentRequestBaseDto {
  id: string;
  company_name: string;
  account_number?: string;
  bank_name?: string;
  expense_item: string;
}

export interface IPaymentCreateDialog {
  payment_date?: string;
  amount?: string;
  description?: string;
  company_name?: string;
  account_number?: string;
  bank_name?: string;
  expense_item?: string;
  creator_id?: string;
  creator_name?: string;
}

export interface IPaymentUpdateDialog extends IPaymentRequestsTableDisplayDto {}

export interface IPaymentRegistryRq {
  payer_bank_account_id: string;
  items_ids: string[];
}
