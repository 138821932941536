import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import React, { FC } from 'react';
import { DialogActions } from '@mui/material';

interface IPaymentRequestCreateDialogActionProps {
  onSubmit: () => void;
  onClose: () => void;
  disabled?: boolean;
}

export const CreateDialogAction: FC<IPaymentRequestCreateDialogActionProps> = ({ onSubmit, disabled, onClose }) => {
  return (
    <DialogActions>
      <Button
        onClick={onSubmit}
        disabled={disabled}
        variant="outlined"
        color="primary"
        startIcon={<AddIcon />}
        sx={{
          textTransform: 'none',
          borderRadius: 2,
          borderWidth: 2,
          padding: '6px 16px',
          '&:hover': {
            borderWidth: 2,
          },
        }}
      >
        Добавить
      </Button>
      <Button
        onClick={onClose}
        variant="outlined"
        sx={{
          textTransform: 'none',
          borderRadius: 2,
          borderWidth: 2,
          padding: '6px 16px',
          '&:hover': {
            borderWidth: 2,
          },
        }}
      >
        Отмена
      </Button>
    </DialogActions>
  );
};
