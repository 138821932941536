import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { DialogContent, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import React, { FC, useCallback } from 'react';
import { IPaymentCreateDialog } from '@src/service/types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import { paymentRequestTableStore } from '@src/components/Tables/PaymentRequestsTable/store/PaymentRequestTableStore';
import { DialogFormAutocomplete } from '@src/components/Tables/PaymentRequestsTable/components/DialogFormAutocomlete/DialogFormAutocomplete';

interface IPaymentRequestDialogFormProps {
  formData: IPaymentCreateDialog;
  onFormDataChange: (value: string, field: keyof IPaymentCreateDialog) => void;
}

export const CreateDialogForm: FC<IPaymentRequestDialogFormProps> = ({ formData, onFormDataChange }) => {
  /**
   * Handlers
   */

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: keyof IPaymentCreateDialog) => {
      if (e.target.value) {
        onFormDataChange(e.target.value, field);
      }
    },
    [onFormDataChange],
  );

  const handleCreatorChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const _creator_value = e.target.value;
      const _creator_id =
        _creator_value === paymentRequestTableStore.user?.fio
          ? paymentRequestTableStore.user?.id
          : paymentRequestTableStore.usersList.find(_user => _user.fio === _creator_value)?.id;
      if (_creator_value && _creator_id) {
        onFormDataChange(_creator_value, 'creator_name');
        onFormDataChange(_creator_id, 'creator_id');
      }
    },
    [onFormDataChange],
  );

  /**
   * Render
   */

  return (
    <DialogContent>
      <Box
        mt={1}
        sx={{
          display: 'flex',
          width: '100%',
          flexGrow: 1,
        }}
      >
        <Grid container spacing={1.5}>
          <DialogFormAutocomplete formData={formData} onFormDataChange={onFormDataChange} />
          <Grid xs={12} md={7}>
            <TextField
              label={'Назначение платежа'}
              type={'text'}
              rows={9}
              multiline={true}
              fullWidth={true}
              onChange={e => handleInputChange(e, 'description')}
              value={formData?.description || ''}
            />
          </Grid>
          <Grid container spacing={1} xs={12} md={5}>
            <Stack width="100%" spacing={1.5} mt={0.5}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                <DatePicker
                  disablePast
                  format={'DD.MM.YYYY'}
                  label="Дата"
                  value={formData?.payment_date ? dayjs(formData.payment_date) : dayjs()}
                  onChange={newValue => {
                    const dateStr = newValue ? newValue.format('YYYY-MM-DD') : '';
                    onFormDataChange(dateStr, 'payment_date');
                  }}
                  slotProps={{ textField: { fullWidth: true, required: true } }}
                />
              </LocalizationProvider>
              <TextField
                fullWidth={true}
                name={'amount'}
                label={'Сумма'}
                type="number"
                required={true}
                value={formData?.amount || ''}
                InputProps={{ startAdornment: <InputAdornment position="start">₽</InputAdornment> }}
                onChange={e => handleInputChange(e, 'amount')}
              />
              <TextField
                label={'Статья'}
                select={true}
                required={true}
                value={formData?.expense_item || ''}
                onChange={e => handleInputChange(e, 'expense_item')}
                fullWidth={true}
              >
                {paymentRequestTableStore.expenseList.map(expense => (
                  <MenuItem key={expense.id} value={expense.name}>
                    {expense.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label={'Ответственный'}
                select={true}
                required={true}
                value={formData?.creator_name || paymentRequestTableStore.user?.fio}
                onChange={e => handleCreatorChange(e)}
                fullWidth={true}
              >
                {paymentRequestTableStore.usersList.map(_user => (
                  <MenuItem key={`userMenuItem${_user.id}`} value={_user.fio}>
                    {_user.fio}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </DialogContent>
  );
};
