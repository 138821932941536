import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { RangeDatePicker } from '@src/components/RangeDatePicker/RangeDatePicker';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { Dayjs } from 'dayjs';
import {
  CheckCircleOutline as ApproveIcon,
  CancelOutlined as RejectIcon,
  DeleteOutline as DeleteIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';

const baseButtonStyle: SxProps = {
  borderRadius: 2,
  borderWidth: 2,
  padding: '6px',
  minWidth: 0,
};

interface PaymentRqMainActionsProps {
  onCreate: () => void;
  onApprove: () => void;
  onReject: () => void;
  onDelete: () => void;
  onDownload: () => void;
  onDateRangeChange: (range: DateRange<Dayjs>) => void;
  dateRange: DateRange<Dayjs>;
  downloadDisable: boolean;
}

export const PaymentRqMainActions: FC<PaymentRqMainActionsProps> = observer(
  ({ onCreate, onApprove, onReject, onDelete, onDownload, onDateRangeChange, dateRange, downloadDisable }) => {
    return (
      <Stack direction={'row'} spacing={2} mb={2} alignItems={'center'}>
        <Box sx={{ flexGrow: 1 }}>
          <RangeDatePicker date={dateRange} onDateChanged={onDateRangeChange} />
        </Box>
        <Button
          onClick={onCreate}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          sx={{
            ...baseButtonStyle,
            '&:hover': {
              borderWidth: 2,
              borderColor: 'error.dark',
            },
          }}
        >
          Создать заявку
        </Button>
        <Button
          onClick={onDownload}
          disabled={downloadDisable}
          variant="outlined"
          color="primary"
          aria-label="download"
          startIcon={<DownloadIcon />}
          sx={{
            ...baseButtonStyle,
            '&:hover': {
              borderWidth: 2,
            },
          }}
        >
          Выгрузить реестр
        </Button>
        <Button
          onClick={onApprove}
          variant="outlined"
          color="success"
          startIcon={<ApproveIcon />}
          sx={{
            ...baseButtonStyle,
            '&:hover': {
              borderColor: 'success.dark',
              borderWidth: 2,
            },
          }}
        >
          Согласовать
        </Button>
        <Button
          onClick={onReject}
          variant="outlined"
          color="error"
          startIcon={<RejectIcon />}
          sx={{
            ...baseButtonStyle,
            '&:hover': {
              borderColor: 'error.dark',
              borderWidth: 2,
            },
          }}
        >
          Отказать
        </Button>
        <Button
          onClick={onDelete}
          variant="outlined"
          color="error"
          aria-label="delete"
          sx={{
            ...baseButtonStyle,
            '&:hover': {
              borderColor: 'error.dark',
              borderWidth: 2,
            },
          }}
        >
          <DeleteIcon />
        </Button>
      </Stack>
    );
  },
);
