import { IBankAccountCreateDto } from '@src/service/types';
import React, { FC, useEffect } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import { Close as CloseIcon } from '@mui/icons-material';

interface IAddBankAccountDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: IBankAccountCreateDto) => void;
  companyId: string;
}

interface IFormInputs {
  account_number: string;
  corresp_account: string;
  bic: string;
  bank_name: string;
  bank_city: string;
  company__id: string;
  is_main: boolean;
}

const validators = {
  account_number: {
    required: 'Расчетный счет обязателен',
    pattern: {
      value: /^[0-9]{20}$/,
      message: 'Расчетный счет должен состоять из 20 цифр',
    },
  },
  corresp_account: {
    required: 'Корреспондентский счет обязателен',
    pattern: {
      value: /^[0-9]{20}$/,
      message: 'Корреспондентский счет должен состоять из 20 цифр',
    },
  },
  bic: {
    required: 'БИК обязателен',
    pattern: {
      value: /^[0-9]{9}$/,
      message: 'БИК должен состоять из 9 цифр',
    },
  },
  bank_name: {
    required: 'Наименование банка обязательно',
    maxLength: {
      value: 255,
      message: 'Максимальная длина 255 символов',
    },
  },
  bank_city: {
    required: 'Город банка обязателен',
    maxLength: {
      value: 100,
      message: 'Максимальная длина 100 символов',
    },
  },
  company__id: {
    required: 'ID компании обязателен',
  },
  is_main: {},
};

const buttonSx = {
  textTransform: 'none',
  borderRadius: 2,
  borderWidth: 2,
  padding: '6px 16px',
  '&:hover': {
    borderWidth: 2,
  },
};

export const AddBankAccountDialog: FC<IAddBankAccountDialogProps> = ({ isOpen, onClose, onSubmit, companyId }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, errors },
  } = useForm<IFormInputs>({
    mode: 'onChange',
    defaultValues: { is_main: false, company__id: companyId },
  });

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle variant={'h5'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Добавление банковского счета
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" spacing={1.5}>
            <input type="hidden" {...register('company__id')} />
            <input type="hidden" {...register('is_main')} />
            <TextField
              {...register('account_number', validators.account_number)}
              autoFocus
              margin="dense"
              label={'Расчетный счет'}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                maxLength: 20,
              }}
              error={Boolean(errors.account_number)}
              helperText={errors.account_number?.message || 'Должен состоять из 20 цифр'}
            />
            <TextField
              {...register('corresp_account', validators.corresp_account)}
              margin="dense"
              label={'Корреспондентский счет'}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                maxLength: 20,
              }}
              error={Boolean(errors.corresp_account)}
              helperText={errors.corresp_account?.message || 'Должен состоять из 20 цифр'}
            />
            <TextField
              {...register('bic', validators.bic)}
              margin="dense"
              label={'БИК'}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                maxLength: 9,
              }}
              error={Boolean(errors.bic)}
              helperText={errors.bic?.message || 'Должен состоять из 9 цифр'}
            />
            <TextField
              {...register('bank_name', validators.bank_name)}
              margin="dense"
              label={'Наименование банка'}
              type="text"
              fullWidth
              variant="standard"
              error={Boolean(errors.bank_name)}
              helperText={errors.bank_name?.message}
            />
            <TextField
              {...register('bank_city', validators.bank_city)}
              margin="dense"
              label={'Город банка'}
              type="text"
              fullWidth
              variant="standard"
              error={Boolean(errors.bank_city)}
              helperText={errors.bank_city?.message}
            />
            <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
              <Button type={'button'} onClick={onClose} sx={buttonSx} variant="outlined">
                Отмена
              </Button>
              <Button type={'submit'} sx={buttonSx} disabled={!isDirty || !isValid} variant="outlined" color="primary">
                Добавить
              </Button>
            </Stack>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};
