import Grid from '@mui/material/Unstable_Grid2';
import { Autocomplete, TextField } from '@mui/material';
import React, { FC, useCallback, useEffect } from 'react';
import { IBankAccountExRsDto, IPaymentCreateDialog } from '@src/service/types';
import { paymentRequestTableStore } from '@src/components/Tables/PaymentRequestsTable/store/PaymentRequestTableStore';

interface IPaymentRequestDialogFormProps {
  formData: IPaymentCreateDialog;
  onFormDataChange: (value: string, field: keyof IPaymentCreateDialog) => void;
}

interface BankAutocompleteOption {
  account: IBankAccountExRsDto;
  output: string;
}

export const DialogFormAutocomplete: FC<IPaymentRequestDialogFormProps> = ({ formData, onFormDataChange }) => {
  /**
   * States
   */

  const [bankAccountsList, setBankAccountsList] = React.useState<BankAutocompleteOption[]>([]);
  const [defaultBankAccount, setDefaultBankAccount] = React.useState<BankAutocompleteOption | null>(null);

  /**
   * Effects
   */
  useEffect(() => {
    let _accounts: BankAutocompleteOption[] = [];
    if (formData.company_name) {
      const _bankAccounts = paymentRequestTableStore.companyBankAccountsList.get(formData.company_name);
      if (_bankAccounts) {
        const banksAutocompleteOptions: BankAutocompleteOption[] = _bankAccounts.map(acc => ({
          account: acc,
          output: [acc.bank_name, acc.account_number].join(' | '),
        }));
        _accounts = banksAutocompleteOptions;
        setBankAccountsList(banksAutocompleteOptions);
      }
      if (formData.account_number) {
        const _defaultBankAccount = _accounts.find(b => b.account.account_number === formData.account_number);
        setDefaultBankAccount(_defaultBankAccount ?? null);
      }
    }
  }, [formData.account_number, formData.company_name]);

  /**
   * Handlers
   */

  const handleCompanyInputChange = useCallback(
    (newValue: string | null) => {
      if (newValue && newValue !== formData.company_name && paymentRequestTableStore.companyList.has(newValue)) {
        onFormDataChange(newValue, 'company_name');
      }
      if (newValue) {
        const _bankAccounts = paymentRequestTableStore.companyBankAccountsList.get(newValue);
        if (_bankAccounts) {
          const banksAutocompleteOptions: BankAutocompleteOption[] = _bankAccounts.map(acc => ({
            account: acc,
            output: [acc.bank_name, acc.account_number].join(' | '),
          }));
          const _defaultBankAccount = banksAutocompleteOptions.find(b => b.account.is_main);
          setBankAccountsList(banksAutocompleteOptions);
          if (_defaultBankAccount) setDefaultBankAccount(_defaultBankAccount);
          onFormDataChange(_defaultBankAccount?.account.account_number ?? '', 'account_number');
        }
      }
    },
    [formData.company_name, onFormDataChange],
  );

  const handleBankAccountChange = useCallback(
    (newValue: BankAutocompleteOption | null) => {
      if (newValue) {
        onFormDataChange(newValue.account.account_number, 'account_number');
        onFormDataChange(newValue.account.bank_name, 'bank_name');
      }
    },
    [onFormDataChange],
  );

  /**
   * Render
   */

  return (
    <>
      <Grid>
        <Autocomplete
          key={'company_name'}
          fullWidth={true}
          options={[...paymentRequestTableStore.companyList]}
          getOptionDisabled={option =>
            paymentRequestTableStore.bankAccountsList
              .filter(b => b.company.is_blocked)
              .map(b => b.company.name)
              .some(c => c === option)
          }
          value={formData?.company_name ?? null}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={(_, newValue) => handleCompanyInputChange(newValue)}
          //todo: need update to mui 7 to use auto width
          sx={{
            width: {
              xs: '364px',
              sm: '552px',
              md: '852px',
            },
          }}
          renderInput={params => (
            <TextField
              {...params}
              label="Компания"
              required={true}
              error={formData.company_name ? !paymentRequestTableStore.companyList.has(formData.company_name) : true}
            />
          )}
        />
      </Grid>
      <Grid>
        <Autocomplete
          key={'bank_account'}
          fullWidth={true}
          options={bankAccountsList}
          getOptionDisabled={option =>
            paymentRequestTableStore.bankAccountsList
              .filter(b => b.company.is_blocked)
              .some(c => c.account_number === option.account.account_number)
          }
          getOptionLabel={(option: BankAutocompleteOption) => option.output}
          value={defaultBankAccount}
          isOptionEqualToValue={(option, value) => option.account.account_number === value.account.account_number}
          onChange={(_, newValue) => handleBankAccountChange(newValue)}
          //todo: need update to mui 7 to use auto width
          sx={{
            width: {
              xs: '364px',
              sm: '552px',
              md: '852px',
            },
          }}
          renderInput={params => <TextField {...params} label="Счет" required={true} />}
        />
      </Grid>
    </>
  );
};
