import { makeAutoObservable } from 'mobx';
import { IClientDocumentsTableDto, IRunDto, ITransportDataTableDto } from '@src/service/types';

class CWsStore {
  private _wsRuns: IRunDto[] = [];
  private _wsTransportData: ITransportDataTableDto[] = [];
  private _wsClientDocumentsData: IClientDocumentsTableDto[] = [];
  private _action: string | undefined = undefined;
  private _item_id: string | undefined = undefined;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  init(): void {
    console.log('Websocket Store init...');
  }

  get wsRuns(): IRunDto[] {
    return this._wsRuns;
  }

  set wsRuns(value: IRunDto[]) {
    this._wsRuns = value;
  }

  get action(): string | undefined {
    return this._action;
  }

  set action(value: string) {
    this._action = value;
  }

  get wsClientDocumentsData(): IClientDocumentsTableDto[] {
    return this._wsClientDocumentsData;
  }

  set wsClientDocumentsData(value: IClientDocumentsTableDto[]) {
    this._wsClientDocumentsData = value;
  }

  get wsTransportData(): ITransportDataTableDto[] {
    return this._wsTransportData;
  }

  set wsTransportData(value: ITransportDataTableDto[]) {
    this._wsTransportData = value;
  }

  get item_id(): string | undefined {
    return this._item_id;
  }

  set item_id(value: string | undefined) {
    this._item_id = value;
  }

  public updateRuns(item_id: string, model: string, action: string, value: object[]) {
    this.item_id = item_id;
    this.action = action;

    console.log(item_id, model, action, value);
    if (model === 'ClientDocumentsTable') {
      this.wsClientDocumentsData = value as IClientDocumentsTableDto[];
    } else if (model === 'TransportDataTable') {
      this.wsTransportData = value as ITransportDataTableDto[];
    } else if (model === 'RunExtended') {
      this.wsRuns = value as IRunDto[];
    } else {
      console.log('Unknown websocket model');
    }
  }
}

export const WsStore = new CWsStore();
