import { Action } from '@src/store/action';

import { ICompanyCreateDto, ICompanyExRsDto, ICompanyUpdateDto, ICompanyApiService } from '@src/service/types';

class ClCompanyApiService implements ICompanyApiService {
  private readonly HOST_URL = '/api/v1/company';
  private GetCompanyAction = new Action<string, ICompanyExRsDto>();
  private GetAllCompaniesAction = new Action<void, ICompanyExRsDto[]>();
  private CreateCompanyAction = new Action<ICompanyCreateDto, ICompanyExRsDto>();
  private UpdateCompanyAction = new Action<ICompanyUpdateDto, ICompanyExRsDto>();
  private DeleteCompanyAction = new Action<string, boolean>();

  async getCompany(id: string): Promise<ICompanyExRsDto> {
    const result = await this.GetCompanyAction.callAction(`${this.HOST_URL}/${id}`, 'GET');

    return result as ICompanyExRsDto;
  }

  async getAllCompanies(): Promise<ICompanyExRsDto[]> {
    const result = await this.GetAllCompaniesAction.callAction(`${this.HOST_URL}/get_all`, 'GET');

    return result as ICompanyExRsDto[];
  }

  async createCompany(dto: ICompanyCreateDto): Promise<ICompanyExRsDto> {
    const result = await this.CreateCompanyAction.callAction(`${this.HOST_URL}/create`, 'POST', dto);

    return result as ICompanyExRsDto;
  }

  async updateCompany(id: string, dto: ICompanyUpdateDto): Promise<ICompanyExRsDto> {
    const result = await this.UpdateCompanyAction.callAction(`${this.HOST_URL}/${id}`, 'PATCH', dto);

    return result as ICompanyExRsDto;
  }

  async deleteCompany(id: string): Promise<boolean> {
    const result = await this.DeleteCompanyAction.callAction(`${this.HOST_URL}/${id}`, 'DELETE');

    return result as boolean;
  }
}

export const CompanyApiService: ICompanyApiService = new ClCompanyApiService();
