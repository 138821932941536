import { makeAutoObservable } from 'mobx';

import { nowStr } from '@src/utils/date_utils';

import { IUserSettings } from '@src/store/types';
import { SettingsStore } from '@src/store/SettingsStore';
import {
  ITransportDataTableUpdateRqPartial,
  ITransportDataTableDto,
  ITransportDataTableUpdateRq,
} from '@src/service/types';
import { TransportDataTableApiService } from '@src/service/TransportDataTableApiService';

class CTransportDataTableStore {
  // список Run
  private _list: ITransportDataTableDto[] = [];
  private _updatedList: ITransportDataTableDto[] = [];
  // Флаг состояния формирования списка Run
  private _isPendingList = false;
  // Флаг состояния выполнения действий с Run
  private _isPendingActions = false;
  // Стор с настройками пользователя
  private _settingsStore: SettingsStore | undefined;
  private _userSettings: IUserSettings | undefined;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.settingsStore = new SettingsStore('table_4_');
    this.userSettings = this.settingsStore.currentSettings;
  }

  async init(): Promise<void> {
    console.log('TransportData Table Store init...');
    await this.loadListRuns();
  }

  // #region getter/setter

  get settingsStore(): SettingsStore | undefined {
    return this._settingsStore;
  }

  set settingsStore(value: SettingsStore | undefined) {
    this._settingsStore = value;
  }

  get userSettings(): IUserSettings {
    return this._userSettings ?? ({} as IUserSettings);
  }

  set userSettings(value: IUserSettings | undefined) {
    this._userSettings = value;
  }

  get list(): ITransportDataTableDto[] {
    return this._list;
  }

  private set list(value: ITransportDataTableDto[]) {
    this._list = value;
  }

  get updatedList(): ITransportDataTableDto[] {
    return this._updatedList;
  }

  set updatedList(value: ITransportDataTableDto[]) {
    this._updatedList = value;
  }

  get isPendingList(): boolean {
    return this._isPendingList;
  }

  private set isPendingList(value: boolean) {
    this._isPendingList = value;
  }

  get isPendingActions(): boolean {
    return this._isPendingActions;
  }

  private set isPendingActions(value: boolean) {
    this._isPendingActions = value;
  }

  // #endregion

  // #region business logic (BL)

  public getRunById(id: number | string): ITransportDataTableDto | undefined {
    return this.updatedList.find(run => run.item_id == id);
  }

  public async updateRun(dtos: ITransportDataTableUpdateRq[]): Promise<boolean> {
    try {
      this.isPendingActions = true;

      const res = await TransportDataTableApiService.updateMultipleRun(dtos);
      if (res) {
        this.updatedList = this.updatedList.map(dto => {
          const found = dtos.find(d => d.item_id === dto.item_id);

          return found ? { ...dto, ...found } : dto;
        });
      }

      return res;
    } finally {
      this.isPendingActions = false;
    }
  }

  public async reloadListRuns(): Promise<void> {
    await this.loadListRuns();
  }

  private async loadListRuns(): Promise<void> {
    try {
      this.isPendingList = true;
      const dateStartStr = this.settingsStore?.dateStartStr ?? nowStr();
      const dateEndStr = this.settingsStore?.dateEndStr ?? nowStr();

      this.list = await TransportDataTableApiService.getListRuns({ start_day: dateStartStr, end_day: dateEndStr });
      this.updatedList = this.list;
    } finally {
      this.isPendingList = false;
    }
  }

  async mutateManyWithSameValue(updatedItems: ITransportDataTableUpdateRqPartial[]): Promise<boolean> {
    try {
      this.isPendingActions = true;
      const success = await TransportDataTableApiService.mutateManyWithSameValues(updatedItems);
      if (success) {
        this.updatedList = this.updatedList.map(dto => {
          const found = updatedItems.find(d => d.item_id === dto.item_id.toString());

          return found ? { ...dto, ...found } : dto;
        });
      }

      return success;
    } finally {
      this.isPendingActions = false;
    }
  }

  public async addPathListToMany(ids: string[], newValue: string): Promise<boolean> {
    const _updates: ITransportDataTableUpdateRqPartial[] = [];
    ids.forEach(id => _updates.push({ item_id: id, pl_document_name: newValue }));

    return await this.mutateManyWithSameValue(_updates);
  }

  public async addInvoiceNumberToMany(ids: string[], newValue: string): Promise<boolean> {
    const _updates: ITransportDataTableUpdateRqPartial[] = [];
    ids.forEach(id => _updates.push({ item_id: id, carrier_invoice: newValue }));

    return await this.mutateManyWithSameValue(_updates);
  }

  public async addRegeditNumberToMany(ids: string[], newValue: string): Promise<boolean> {
    const _updates: ITransportDataTableUpdateRqPartial[] = [];
    ids.forEach(id => _updates.push({ item_id: id, registry_document_name: newValue }));

    return await this.mutateManyWithSameValue(_updates);
  }

  public async addTnToMany(ids: string[], newValue: string): Promise<boolean> {
    const _updates: ITransportDataTableUpdateRqPartial[] = [];
    ids.forEach(id => _updates.push({ item_id: id, tn_document_name: `${newValue}${id.toString()}` }));

    return await this.mutateManyWithSameValue(_updates);
  }

  public async setValueColor(updatedItems: ITransportDataTableUpdateRqPartial[]): Promise<boolean> {
    return await this.mutateManyWithSameValue(updatedItems);
  }

  // #endregion
}

export const TransportDataTableStore = new CTransportDataTableStore();
