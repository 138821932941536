import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ProgressBar } from '@src/components/ProgressBar/ProgressBar';
import { observer } from 'mobx-react-lite';
import { PageProgressBar } from '@src/components/PageProgressBar/PageProgressBar';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-premium';
import { DataTableGrid } from '@src/components/DataTable/DataTableGrid';
import { companyTableStore } from '@src/components/Tables/CompanyTable/store/CompanyTableStore';
import Box from '@mui/material/Box';
import { filterOperators } from '@src/utils/tables/utils';
import { ICompanyExRsDto, ICompanyCreateDto } from '@src/service/types';
import { AddNewCompanyDialog } from '@src/components/Tables/CompanyTable/components/AddNewCompanyDialog';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';

import { companyMappers } from '@src/utils/mappers/tables/PaymentRequestMappers';

import { UpdateCompanyDialog } from './components/UpdateCompanyDialog';

export const CompanyTable: FC = observer(() => {
  const { isPendingList, init, isPendingActions, userSettings, updateCompany, list, createCompany, deleteCompany } =
    companyTableStore;

  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [isUpdateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<ICompanyExRsDto | null>(null);

  const handleRowDoubleClick = (params: GridRowParams) => {
    const _item = list.find(c => c.id === params.row.id);
    setSelectedCompany(_item ?? null);
    if (_item) setUpdateDialogOpen(true);
  };

  useEffect(() => {
    void init();
  }, [init]);

  /**
   * Table
   */

  const columns: GridColDef[] = useMemo(() => {
    const cols: GridColDef[] = [
      {
        field: 'id',
        headerName: 'ИД Компании',
        description: 'ИД Компании',
        flex: 1,
        minWidth: 20,
        type: 'string',
        align: 'center',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'name',
        headerName: 'Компания',
        description: 'Компания',
        flex: 2,
        minWidth: 100,
        type: 'string',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'inn',
        headerName: 'ИНН',
        description: 'ИНН',
        flex: 3,
        minWidth: 30,
        type: 'string',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'kpp',
        headerName: 'КПП',
        description: 'КПП',
        flex: 2,
        minWidth: 30,
        type: 'string',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'is_blocked',
        headerName: 'Блокировка',
        description: 'Блокировка компании',
        flex: 1,
        minWidth: 10,
        type: 'boolean',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
    ];

    return cols;
  }, []);

  const rows = useMemo(() => {
    return list?.map(item => companyMappers.toDisplay(item));
  }, [list]);

  /**
   * Handlers
   */

  const handleCreateSubmit = useCallback(
    async (data: ICompanyCreateDto) => {
      if (data.inn && data.name) {
        if (data.kpp === '') data.kpp = undefined;
        const success = await createCompany(data);
        setAddDialogOpen(false);
        if (success) toast.success('Компания успешно создана');
      } else {
        data.inn || toast.error('ИНН должен быть заполнен');
        data.name || toast.error('Наименование должно быть заполнено');
      }
    },
    [createCompany],
  );

  const handleUpdateSubmit = useCallback(
    async (data: ICompanyExRsDto) => {
      const _companyId = data.id;
      const _updateData = companyMappers.toUpdateDto(data);
      const success = await updateCompany(_companyId, _updateData);
      if (success) {
        toast.success('Компания успешно обновлена');
        setUpdateDialogOpen(false);
      } else {
        toast.error('Ошибка при обновлении компании');
      }
    },
    [updateCompany],
  );

  const handleDeleteSubmit = useCallback(async () => {
    if (selectedCompany) {
      const success = await deleteCompany(selectedCompany.id);
      if (success) {
        toast.success('Компания удалена');
        setUpdateDialogOpen(false);
      } else {
        toast.error('Ошибка при удалении компании');
      }
    }
  }, [deleteCompany, selectedCompany]);

  /**
   * render
   */

  return (
    <>
      <Stack direction="row" alignItems="center" mb={5}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h4">Компании-контрагенты</Typography>
        </Box>
        <ProgressBar isLoading={isPendingActions} />
      </Stack>
      <Stack direction="row" justifyContent="flex-end" mb={2}>
        <Button variant="contained" startIcon={<AddIcon />} onClick={() => setAddDialogOpen(true)}>
          Добавить компанию
        </Button>
      </Stack>
      <AddNewCompanyDialog
        isOpen={isAddDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        onSubmit={handleCreateSubmit}
      />
      {selectedCompany && (
        <UpdateCompanyDialog
          isOpen={isUpdateDialogOpen}
          onClose={() => setUpdateDialogOpen(false)}
          onSubmit={handleUpdateSubmit}
          onDelete={handleDeleteSubmit}
          initialData={selectedCompany}
          disabled={isPendingActions}
        />
      )}
      <PageProgressBar isLoading={isPendingList}>
        <DataTableGrid
          columns={columns}
          isSimpleTable={false}
          rows={rows}
          checkboxSelection={false}
          hideFooterSelectedRowCount={true}
          rowHeight={30}
          tablePageModel={userSettings.tablePageModel}
          tableFilterModel={userSettings.tableFilterModel}
          tableSortModel={userSettings.tableSortModel}
          tableDensityMode={userSettings.tableDensityMode}
          tableColumnsWidth={userSettings.columnsWidth}
          tableColumnsOrder={userSettings.columnsOrder}
          saveTablePageData={userSettings.saveTablePageData}
          saveTableSortData={userSettings.saveTableSortData}
          saveTableFilterData={userSettings.saveTableFilterData}
          saveTableDensityMode={userSettings.saveTableDensityMode}
          saveTableColumnsWidth={userSettings.saveColumnsWidth}
          saveTableColumnsOrder={userSettings.saveColumnsOrder}
          onRowDoubleClick={handleRowDoubleClick}
          exportFileName={'Компании'}
          isLoading={isPendingList}
        />
      </PageProgressBar>
    </>
  );
});
