import { Action } from '@src/store/action';

import {
  IExpenseItemApiService,
  ICreateExpenseItemDto,
  IExpenseItemRsDto,
  IUpdateExpenseItemDto,
} from '@src/service/types';

class CExpenseItemApiService implements IExpenseItemApiService {
  private readonly HOST_URL = '/api/v1/expense_item';
  private getAllAction = new Action<void, IExpenseItemRsDto[]>();
  private createAction = new Action<ICreateExpenseItemDto, IExpenseItemRsDto>();
  private updateAction = new Action<IUpdateExpenseItemDto, IExpenseItemRsDto>();
  private deleteAction = new Action<string, boolean>();

  async getAll(): Promise<IExpenseItemRsDto[]> {
    const result = await this.getAllAction.callAction(`${this.HOST_URL}/get_all`, 'GET');

    return result as IExpenseItemRsDto[];
  }

  async createExpenseItem(dto: ICreateExpenseItemDto): Promise<IExpenseItemRsDto> {
    const result = await this.createAction.callAction(`${this.HOST_URL}/create`, 'POST', dto);

    return result as IExpenseItemRsDto;
  }

  async updateExpenseItem(id: string, dto: IUpdateExpenseItemDto): Promise<IExpenseItemRsDto> {
    const result = await this.updateAction.callAction(`${this.HOST_URL}/${id}`, 'PATCH', dto);

    return result as IExpenseItemRsDto;
  }

  async deleteExpenseItem(id: string): Promise<boolean> {
    const result = await this.deleteAction.callAction(`${this.HOST_URL}/${id}`, 'DELETE');

    return result as boolean;
  }
}

export const ExpenseItemApiService: IExpenseItemApiService = new CExpenseItemApiService();
