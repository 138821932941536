import React, { FC } from 'react';
import Button from '@mui/material/Button';
import { ButtonGroup } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface ITableColorPickerProps {
  onClick: (value: number) => void;
}

export const TableColorPicker: FC<ITableColorPickerProps> = (props: ITableColorPickerProps) => {
  const { onClick } = props;
  const theme = useTheme();

  return (
    <ButtonGroup variant="outlined" aria-label="">
      <Button
        sx={{ backgroundColor: theme.palette.info.dark, ':hover': theme.palette.info.dark }}
        onClick={() => onClick(2)}
      ></Button>
      <Button
        sx={{ backgroundColor: theme.palette.info.light, ':hover': theme.palette.info.light }}
        onClick={() => onClick(1)}
      ></Button>
      <Button sx={{ backgroundColor: 'transparent' }} onClick={() => onClick(0)}></Button>
    </ButtonGroup>
  );
};
