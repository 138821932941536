import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { DialogActions, Stack } from '@mui/material';
import React, { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';

interface IPaymentRequestUpdateDialogActionProps {
  onSubmit: () => void;
  onDelete: () => void;
  onClose: () => void;
  onDuplicate: () => void;
  disabledSubmit?: boolean;
  disabledDuplicate?: boolean;
}

export const UpdateDialogAction: FC<IPaymentRequestUpdateDialogActionProps> = ({
  onSubmit,
  disabledSubmit = false,
  disabledDuplicate = false,
  onClose,
  onDelete,
  onDuplicate,
}) => {
  return (
    <DialogActions>
      <Button
        onClick={onDelete}
        variant="outlined"
        color="error"
        aria-label="delete"
        sx={{
          borderRadius: 2,
          borderWidth: 2,
          padding: '6px',
          minWidth: 0,
          marginRight: 'auto',
          '&:hover': {
            borderColor: 'error.dark',
            borderWidth: 2,
          },
        }}
      >
        <DeleteIcon />
      </Button>
      <Stack direction="row" spacing={1}>
        <Button
          onClick={onDuplicate}
          disabled={disabledDuplicate}
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          sx={{
            textTransform: 'none',
            borderRadius: 2,
            borderWidth: 2,
            padding: '6px 16px',
            '&:hover': {
              borderWidth: 2,
            },
          }}
        >
          Дублировать
        </Button>
        <Button
          onClick={onSubmit}
          disabled={disabledSubmit}
          variant="outlined"
          color="primary"
          sx={{
            textTransform: 'none',
            borderRadius: 2,
            borderWidth: 2,
            padding: '6px 16px',
            '&:hover': {
              borderWidth: 2,
            },
          }}
        >
          Изменить
        </Button>

        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            textTransform: 'none',
            borderRadius: 2,
            borderWidth: 2,
            padding: '6px 16px',
            '&:hover': {
              borderWidth: 2,
            },
          }}
        >
          Отмена
        </Button>
      </Stack>
    </DialogActions>
  );
};
