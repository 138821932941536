/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ProgressBar } from '@src/components/ProgressBar/ProgressBar';
import { observer } from 'mobx-react-lite';
import { PageProgressBar } from '@src/components/PageProgressBar/PageProgressBar';
import { GridCellSelectionModel, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { DataTableGrid, ICustomToolbarButtonProps } from '@src/components/DataTable/DataTableGrid';
import { TransportDataTableFilter } from '@src/components/Tables/TransportDataTable/components/TransportDataTableFilter/TransportDataTableFilter';
import { TransportDataTableStore } from '@src/components/Tables/TransportDataTable/store/TransportDataTableStore';
import Box from '@mui/material/Box';
import { GridAggregationModel } from '@mui/x-data-grid-premium/hooks/features/aggregation/gridAggregationInterfaces';
import { AlertInputDialog, IAlertInputDialogContent } from '@src/components/AlertInputDialog/AlertInputDialog';
import { dateColumnType, filterOperators } from '@src/utils/tables/utils';
import {
  ITransportDataTableDto,
  ITransportDataTableUpdateRq,
  ITransportDataTableUpdateRqPartial,
} from '@src/service/types';
import dayjs from 'dayjs';
import { DictStore } from '@src/store/DictStore';
import { toStr } from '@src/utils/date_utils';
import { WsStore } from '@src/store/WsStore';

type ColorFields = keyof Pick<ITransportDataTableDto, 'weight_color' | 'weight_arrival_color'>;

export const TransportDataTable: FC = observer(() => {
  const {
    isPendingList,
    init,
    isPendingActions,
    userSettings,
    reloadListRuns,
    updateRun,
    list,
    updatedList,
    getRunById,
    addPathListToMany,
    addInvoiceNumberToMany,
    addRegeditNumberToMany,
    addTnToMany,
    setValueColor,
  } = TransportDataTableStore;
  const { carNumberMap, driverFioMap } = DictStore;
  const { wsTransportData, action, item_id } = WsStore;

  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [dialogContent, setDialogContent] = useState<IAlertInputDialogContent>();
  const [viewMode, setViewMode] = useState<boolean>(userSettings.viewMode);
  const [visibilityModel, setVisibilityModel] = useState(userSettings.tableVisibilityModel);
  const [filterModel, setFilterModel] = useState(userSettings.tableFilterModel);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>();
  const [cellSelectionModel, setCellSelectionModel] = useState<GridCellSelectionModel>();
  const [rowsForUpdate, setRowsForUpdate] = useState<object[] | undefined>(undefined);

  useEffect(() => {
    void init();
  }, [init]);

  useEffect(() => {
    if (wsTransportData && wsTransportData.length > 0) {
      setRowsForUpdate(makeRows(wsTransportData));
    }
  }, [wsTransportData]);

  useEffect(() => {
    if (action === 'delete' && item_id !== undefined) {
      setRowsForUpdate([{ id: item_id, _action: 'delete' }]);
    }
  }, [action, item_id]);

  const isRowsSelected = useMemo(
    () => rowSelectionModel !== undefined && rowSelectionModel.length > 0,
    [rowSelectionModel],
  );

  const columns: GridColDef[] = useMemo(() => {
    const cols: GridColDef[] = [
      {
        field: 'client',
        headerName: 'Клиент',
        description: 'Клиент',
        flex: 8,
        minWidth: 100,
        type: 'string',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        resizable: true,
        groupable: false,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'cargo',
        headerName: 'Груз',
        description: 'Груз',
        flex: 8,
        minWidth: 100,
        type: 'string',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        resizable: true,
        groupable: false,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'route',
        headerName: 'Маршрут',
        description: 'Маршрут',
        flex: 2,
        minWidth: 100,
        type: 'string',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'item_id',
        headerName: 'ИД рейса',
        description: 'ИД рейса',
        flex: 1,
        minWidth: 100,
        type: 'string',
        align: 'center',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'driver_fio',
        headerName: 'Водитель',
        description: 'Водитель',
        flex: 2,
        minWidth: 100,
        type: 'singleSelect',
        align: 'center',
        editable: false,
        resizable: true,
        groupable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
        valueFormatter: value => value ?? '',
      },
      {
        field: 'car_plate_number',
        headerName: 'Машина',
        description: 'Машина',
        flex: 1,
        minWidth: 100,
        type: 'singleSelect',
        align: 'center',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'tn_document_name',
        headerName: 'Номер ТН',
        description: 'Номер ТН',
        flex: 1,
        minWidth: 100,
        type: 'string',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'pl_document_name',
        headerName: 'Номер ПЛ',
        description: 'Номер ПЛ',
        flex: 1,
        minWidth: 100,
        type: 'string',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'registry_document_name',
        headerName: 'Номер реестра',
        description: 'Номер реестра',
        flex: 1,
        minWidth: 100,
        type: 'string',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'carrier_invoice',
        headerName: 'УПД Перевозчика',
        description: 'УПД Перевозчика',
        flex: 1,
        minWidth: 100,
        type: 'string',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'weight_arrival',
        headerName: 'Вес прибытия',
        description: 'Вес прибытия',
        flex: 1,
        minWidth: 100,
        type: 'number',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'weight',
        headerName: 'Вес отправления',
        description: 'Вес отправления',
        flex: 1,
        minWidth: 100,
        type: 'number',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'date_departure',
        headerName: 'Дата отправления',
        description: 'Дата отправления',
        flex: 1,
        ...dateColumnType,
        minWidth: 100,
        type: 'date',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
        valueGetter: value => value && new Date(value),
        valueFormatter: value => value && dayjs(value).format('DD.MM.YYYY'),
      },
      {
        field: 'date_arrival',
        headerName: 'Дата прибытия',
        description: 'Дата прибытия',
        flex: 1,
        ...dateColumnType,
        minWidth: 100,
        type: 'date',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
        valueGetter: value => value && new Date(value),
        valueFormatter: value => value && dayjs(value).format('DD.MM.YYYY'),
      },
      {
        field: 'car_owner',
        headerName: 'Перевозчик',
        description: 'Перевозчик',
        flex: 1,
        minWidth: 200,
        type: 'string',
        align: 'center',
        editable: false,
        resizable: true,
        groupable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'carrier_price',
        headerName: 'Ставка перевозчика',
        description: 'Ставка перевозчика',
        flex: 1,
        minWidth: 100,
        type: 'number',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'trailer_description',
        headerName: 'Прицеп',
        description: 'Прицеп',
        flex: 1,
        minWidth: 200,
        type: 'string',
        align: 'center',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
    ];

    return cols;
  }, []);

  const makeRows = (list: ITransportDataTableDto[]) => {
    return list?.map(item => ({
      id: item.item_id,
      item_id: item.item_id,
      car_plate_number: item.car_plate_number,
      driver_fio: item.driver_fio,
      date_departure: item.date_departure,
      date_arrival: item.date_arrival,
      weight: item.weight ? parseFloat(item.weight?.toString() ?? 0) : null,
      weight_arrival: item.weight_arrival ? parseFloat(item.weight_arrival?.toString() ?? 0) : null,
      weight_color: item.weight_color,
      weight_arrival_color: item.weight_arrival_color,
      client: item.client,
      cargo: item.cargo,
      route: item.route,
      tn_document_name: item.tn_document_name,
      pl_document_name: item.pl_document_name,
      registry_document_name: item.registry_document_name,
      carrier_invoice: item.carrier_invoice,
      car_owner: item.car_owner,
      carrier_price: item.carrier_price,
      trailer_description: item.trailer_description,
    }));
  };

  const rows = useMemo(() => {
    return makeRows(list);
  }, [list]);

  const aggregationFields: GridAggregationModel = {
    weight: 'sum',
    weight_arrival: 'sum',
  };

  const prepareDtoForUpdate = useCallback(
    (dtos: ITransportDataTableDto[]) => {
      return dtos.map(
        dto =>
          ({
            ...dto,
            weight: dto.weight ? (dto.weight.toString() === '' ? null : dto.weight) : null,
            weight_arrival: dto.weight_arrival
              ? dto.weight_arrival.toString() === ''
                ? null
                : dto.weight_arrival
              : null,
            car_id: carNumberMap.get(dto.car_plate_number),
            driver_id: driverFioMap.get(dto.driver_fio),
            date_arrival: dto.date_arrival ? toStr(dto.date_arrival) : dto.date_arrival,
            date_departure: dto.date_departure ? toStr(dto.date_departure) : dto.date_departure,
            carrier_price: dto.carrier_price ? (dto.carrier_price.toString() === '' ? null : dto.carrier_price) : null,
          }) as ITransportDataTableUpdateRq,
      );
    },
    [carNumberMap, driverFioMap],
  );

  const handleUpdate = useCallback(
    async (obj: any): Promise<boolean> => {
      const itemFromList = updatedList.find(item => item.item_id === obj.item_id);
      const weight_color = obj.weight != itemFromList?.weight ? 2 : obj.weight_color;
      const weight_arrival_color = obj.weight_arrival != itemFromList?.weight_arrival ? 2 : obj.weight_arrival_color;
      const result = {
        ...obj,
        weight_color,
        weight_arrival_color,
      };
      await updateRun(prepareDtoForUpdate([result]));

      return result;
    },
    [prepareDtoForUpdate, updateRun, updatedList],
  );

  useEffect(() => {
    const field = 'car_owner';
    const value = 'ООО "РВ-Тариф"';
    const eqOp = 'equals';
    const noEqOp = 'not equals';

    const currentFilterItems = filterModel?.items || [];

    const baseFilterItems = currentFilterItems.filter(
      item => !(item.field === field && item.value === value && (item.operator === eqOp || item.operator === noEqOp)),
    );

    const updatedFilterItems = [
      ...baseFilterItems,
      {
        field,
        operator: viewMode ? eqOp : noEqOp,
        value,
        id: `${field}_${value}`,
      },
    ];

    const updatedFilterModel = {
      ...filterModel,
      items: updatedFilterItems,
      quickFilterExcludeHiddenColumns: true,
    };

    if (JSON.stringify(filterModel?.items) !== JSON.stringify(updatedFilterItems)) {
      setFilterModel(updatedFilterModel);
      userSettings.saveTableFilterData(updatedFilterModel);
    }
  }, [filterModel, userSettings, viewMode]);

  const handleChangeMode = (): void => {
    const mode = !viewMode;
    const updatedVisibilityModel = {
      ...visibilityModel,
      pl_document_name: mode,
      registry_document_name: !mode,
      car_owner: !mode,
      carrier_invoice: !mode,
      carrier_price: !mode,
    };

    setViewMode(mode);
    setVisibilityModel(updatedVisibilityModel);
    userSettings.saveViewMode(mode);
    userSettings.saveTableVisibilityData(updatedVisibilityModel);
  };

  const handleGenerateTransportNumber = useCallback(() => {
    if (rowSelectionModel && rowSelectionModel.length > 0) {
      const _ids: string[] = [...rowSelectionModel].map(id => String(id));
      const _items = updatedList.filter(item => _ids.some(id => id === item.item_id.toString()));

      setDialogContent({
        title: 'Генерация номеров ТН',
        label: 'Префикс',
        content: 'Сгенерировать новые номера ТН для выбранных рейсов',
        handleApply: value => {
          (async () => {
            try {
              const success = await addTnToMany(_ids, value);
              if (success) {
                const updatedItems = _items.map(item => ({
                  ...item,
                  tn_document_name: `${value}${item.item_id.toString()}`,
                }));
                setRowsForUpdate(makeRows(updatedItems));
              }
            } catch (error) {
              console.error('Error applying changes:', error);
            }
          })();
        },
      });
      setDialogIsOpen(true);
    }
  }, [addTnToMany, rowSelectionModel, updatedList]);

  const handleAddRegeditNumber = useCallback(() => {
    if (rowSelectionModel && rowSelectionModel.length > 0) {
      const _ids: string[] = [...rowSelectionModel].map(id => String(id));
      const _items = updatedList.filter(item => _ids.some(id => id === item.item_id.toString()));

      setDialogContent({
        title: 'Добавление реестра',
        label: 'Номер реестра',
        content: 'Добавить реестр для выбранных рейсов',
        handleApply: value => {
          (async () => {
            try {
              const success = await addRegeditNumberToMany(_ids, value);
              if (success) {
                const updatedItems = _items.map(item => ({ ...item, registry_document_name: value }));
                setRowsForUpdate(makeRows(updatedItems));
              }
            } catch (error) {
              console.error('Error applying changes:', error);
            }
          })();
        },
      });
      setDialogIsOpen(true);
    }
  }, [addRegeditNumberToMany, rowSelectionModel, updatedList]);

  const handleAddInvoiceNumber = useCallback(() => {
    if (rowSelectionModel && rowSelectionModel.length > 0) {
      const _ids: string[] = [...rowSelectionModel].map(id => String(id));
      const _items = updatedList.filter(item => _ids.some(id => id === item.item_id.toString()));

      setDialogContent({
        title: 'Добавление УПД',
        label: 'Номер УПД',
        content: 'Добавить УПД для выбранных рейсов',
        handleApply: value => {
          (async () => {
            try {
              const success = await addInvoiceNumberToMany(_ids, value);
              if (success) {
                const updatedItems = _items.map(item => ({ ...item, carrier_invoice: value }));
                setRowsForUpdate(makeRows(updatedItems));
              }
            } catch (error) {
              console.error('Error applying changes:', error);
            }
          })();
        },
      });
      setDialogIsOpen(true);
    }
  }, [addInvoiceNumberToMany, rowSelectionModel, updatedList]);

  const handleAddPathListNumber = useCallback(() => {
    if (rowSelectionModel && rowSelectionModel.length > 0) {
      const _ids: string[] = [...rowSelectionModel].map(id => String(id));
      const _items = updatedList.filter(item => _ids.some(id => id === item.item_id.toString()));

      setDialogContent({
        title: 'Добавление ПЛ',
        label: 'Номер путевого листа',
        content: 'Добавить ПЛ для выбранных рейсов',
        handleApply: value => {
          (async () => {
            try {
              const success = await addPathListToMany(_ids, value);
              if (success) {
                const updatedItems = _items.map(item => ({ ...item, pl_document_name: value }));
                setRowsForUpdate(makeRows(updatedItems));
              }
            } catch (error) {
              console.error('Error applying changes:', error);
            }
          })();
        },
      });
      setDialogIsOpen(true);
    }

    setDialogIsOpen(true);
  }, [addPathListToMany, rowSelectionModel, updatedList]);

  const customToolbarButtons: ICustomToolbarButtonProps[] = useMemo(() => {
    const result: ICustomToolbarButtonProps[] = [
      viewMode
        ? {
            text: 'Добавить ПЛ',
            onClick: handleAddPathListNumber,
            disabled: !isRowsSelected,
          }
        : {
            text: 'Добавить реестр',
            onClick: handleAddRegeditNumber,
            disabled: !isRowsSelected,
          },
      {
        text: 'Сгенерировать номера ТН',
        onClick: handleGenerateTransportNumber,
        disabled: !isRowsSelected,
      },
    ];
    if (!viewMode) {
      result.push({
        text: 'Добавить УПД',
        onClick: handleAddInvoiceNumber,
        disabled: !isRowsSelected,
      });
    }

    return result;
  }, [
    viewMode,
    handleAddPathListNumber,
    isRowsSelected,
    handleAddRegeditNumber,
    handleGenerateTransportNumber,
    handleAddInvoiceNumber,
  ]);

  const onColorPicked = useCallback(
    async (colorValue: number) => {
      if (!cellSelectionModel) {
        return;
      }
      const backendUpdateData: ITransportDataTableUpdateRqPartial[] = [];
      const updateItems: ITransportDataTableDto[] = [];

      Object.entries(cellSelectionModel).forEach(([id, fields]) => {
        const item = getRunById(id);
        if (item) {
          const itemUpdate: ITransportDataTableUpdateRqPartial = { item_id: item.item_id };
          Object.entries(fields).forEach(([field, isSelected]) => {
            if (isSelected && (field === 'weight' || field === 'weight_arrival')) {
              const colorField: ColorFields = `${field}_color`;
              if (item[colorField] !== colorValue && !(item[colorField] === undefined && colorValue === 0)) {
                itemUpdate[colorField] = colorValue;
              }
            }
          });
          backendUpdateData.push(itemUpdate);
          updateItems.push({ ...item, ...itemUpdate });
        }
      });

      if (backendUpdateData.length > 0 && updateItems.length > 0) {
        try {
          const success = await setValueColor(backendUpdateData);

          if (success) setRowsForUpdate(makeRows(updateItems));
        } catch (error) {
          console.error('Error applying changes:', error);
        }
      }
    },
    [cellSelectionModel, getRunById, setValueColor],
  );

  return (
    <>
      <Stack direction="row" alignItems="center" mb={5}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h4">Внесение транспортных данных</Typography>
          <Typography variant="subtitle2">Внесение ТН</Typography>
        </Box>
        <ProgressBar isLoading={isPendingActions} />
      </Stack>
      <TransportDataTableFilter
        date={userSettings.filterDateRange}
        onDateChanged={range => {
          userSettings.saveFilterDateRange(range);
          void reloadListRuns();
        }}
        viewMode={viewMode}
        onChangeViewMode={handleChangeMode}
        onColorPicked={onColorPicked}
      />
      <PageProgressBar isLoading={isPendingList}>
        <DataTableGrid
          columns={columns}
          rows={rows}
          rowsForUpdate={rowsForUpdate}
          editMode={'cell'}
          checkboxSelection={true}
          hideFooterSelectedRowCount={false}
          rowHeight={30}
          tablePageModel={userSettings.tablePageModel}
          tableFilterModel={filterModel}
          tableSortModel={userSettings.tableSortModel}
          tableVisibilityModel={visibilityModel}
          tableDensityMode={userSettings.tableDensityMode}
          tableColumnsWidth={userSettings.columnsWidth}
          tableColumnsOrder={userSettings.columnsOrder}
          saveTablePageData={userSettings.saveTablePageData}
          saveTableVisibilityData={userSettings.saveTableVisibilityData}
          saveTableSortData={userSettings.saveTableSortData}
          saveTableFilterData={userSettings.saveTableFilterData}
          saveTableDensityMode={userSettings.saveTableDensityMode}
          saveTableColumnsWidth={userSettings.saveColumnsWidth}
          saveTableColumnsOrder={userSettings.saveColumnsOrder}
          handleSveTableFilterData={setFilterModel}
          mutationUpdate={handleUpdate}
          exportFileName={'Внесение транспортных данных'}
          isLoading={isPendingList}
          rowGroupingColumnMode={'multiple'}
          rowGroupingFields={viewMode ? ['driver_fio'] : ['car_owner']}
          aggregationFields={aggregationFields}
          toolbarCustomButtons={customToolbarButtons}
          rowSelectionModel={rowSelectionModel}
          onChangeRowSelectionModel={setRowSelectionModel}
          onChangeCellSelectionModel={setCellSelectionModel}
          cellsBackgroundColorsForFields={['weight', 'weight_arrival']}
          notBooleanAsUpdateResult
        />
      </PageProgressBar>
      <AlertInputDialog
        title={dialogContent?.title ?? ''}
        label={dialogContent?.label ?? ''}
        content={dialogContent?.content ?? ''}
        handleApply={dialogContent?.handleApply ?? (() => undefined)}
        handleCancel={() => setDialogIsOpen(false)}
        isOpen={dialogIsOpen}
      />
    </>
  );
});
