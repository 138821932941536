/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useMemo, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ProgressBar } from '@src/components/ProgressBar/ProgressBar';
import { DictStore } from '@src/store/DictStore';
import { observer } from 'mobx-react-lite';
import { PageProgressBar } from '@src/components/PageProgressBar/PageProgressBar';
import { GridCellSelectionModel, GridColDef } from '@mui/x-data-grid-premium';
import { DataTableGrid } from '@src/components/DataTable/DataTableGrid';
import { EditRunsTableFilter } from '@src/components/Tables/EditRunsTable/components/EditRunsTableFilter/EditRunsTableFilter';
import { EditRunsTableStore } from '@src/components/Tables/EditRunsTable/store/EditRunsTableStore';
import Box from '@mui/material/Box';
import { dateColumnType, filterOperators } from '@src/utils/tables/utils';
import { WsStore } from '@src/store/WsStore';
import { IRunDto } from '@src/service/types';
import dayjs from 'dayjs';

export const EditRunsTable: FC = observer(() => {
  const {
    isPendingList,
    init,
    isPendingActions,
    userSettings,
    reloadDocuments,
    updateRun,
    deleteRun,
    createRun,
    list,
    getRunById,
  } = EditRunsTableStore;
  const { wsRuns, action, item_id } = WsStore;
  const { isLoading, driverIdList, carNumberList, carNumberMap, driverFioMap } = DictStore;
  const [selectedRowId, setSelectedRowId] = useState('0');
  const [rowsForUpdate, setRowsForUpdate] = useState<object[] | undefined>(undefined);
  const [cellSelectionModel, setCellSelectionModel] = useState<GridCellSelectionModel>();

  useEffect(() => {
    void init();
  }, [init]);

  useEffect(() => {
    if (wsRuns && wsRuns.length > 0) {
      setRowsForUpdate(makeRows(wsRuns));
    }
  }, [wsRuns]);

  useEffect(() => {
    if (action === 'delete' && item_id !== undefined) {
      setRowsForUpdate([{ id: item_id, _action: 'delete' }]);
    }
  }, [action, item_id]);

  const columns: GridColDef[] = useMemo(() => {
    const cols: GridColDef[] = [
      {
        field: 'run_id',
        headerName: 'ИД рейса',
        description: 'ИД рейса',
        flex: 1,
        minWidth: 100,
        type: 'string',
        align: 'center',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'client',
        headerName: 'Клиент',
        description: 'Клиент',
        flex: 2,
        minWidth: 100,
        type: 'string',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'route',
        headerName: 'Маршрут',
        description: 'Маршрут',
        flex: 3,
        minWidth: 100,
        type: 'string',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'cargo',
        headerName: 'Груз',
        description: 'Груз',
        flex: 2,
        minWidth: 100,
        type: 'string',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'weight',
        headerName: 'Вес погрузки',
        description: 'Вес погрузки',
        flex: 1,
        minWidth: 100,
        type: 'string',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'weight_arrival',
        headerName: 'Вес выгрузки',
        description: 'Вес выгрузки',
        flex: 1,
        minWidth: 100,
        type: 'string',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'car',
        headerName: 'Машина',
        description: 'Машина',
        flex: 1,
        minWidth: 100,
        type: 'singleSelect',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'owner',
        headerName: 'Владелец',
        description: 'Владелец',
        flex: 1,
        minWidth: 100,
        type: 'string',
        align: 'center',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'driver',
        headerName: 'Водитель',
        description: 'Водитель',
        flex: 2,
        minWidth: 100,
        type: 'singleSelect',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
        valueFormatter: value => value ?? '',
      },
      {
        field: 'date_departure',
        headerName: 'Дата погрузки',
        description: 'Дата погрузки',
        flex: 1,
        ...dateColumnType,
        minWidth: 100,
        type: 'date',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
        valueGetter: value => value && new Date(value),
        valueFormatter: value => value && dayjs(value).format('DD.MM.YYYY'),
      },
      {
        field: 'date_arrival',
        headerName: 'Дата выгрузки',
        description: 'Дата выгрузки',
        flex: 1,
        ...dateColumnType,
        minWidth: 100,
        type: 'date',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
        valueGetter: value => value && new Date(value),
        valueFormatter: value => value && dayjs(value).format('DD.MM.YYYY'),
      },
    ];

    return cols;
  }, []);

  const makeRows = (list: IRunDto[]) => {
    return list?.map(item => ({
      id: item.item_id,
      run_id: item.item_id,
      car: item.car_plate_number,
      owner: item.car_owner,
      driver: item.driver_fio,
      date_departure: item.date_departure,
      date_arrival: item.date_arrival,
      client: item.invoice.client,
      cargo: item.invoice.cargo,
      route: item.invoice.route,
      weight: item.weight,
      weight_arrival: item.weight_arrival,
    }));
  };

  const rows = useMemo(() => {
    return makeRows(list);
  }, [list]);

  const handleCopyRowClick = (): void => {
    const entry = list.find(r => r.item_id === selectedRowId);
    if (entry === undefined) return;

    void createRun(entry);

    setSelectedRowId('0');
  };

  const handleRowClick = (id: string): void => {
    setSelectedRowId(id);
  };

  const handleRowDelete = (): void => {
    void deleteRun(selectedRowId);
    setSelectedRowId('0');
  };

  const handleUpdate = async (obj: any): Promise<boolean> => {
    const rowId = obj.run_id;
    const weight = obj.weight ? (obj.weight.toString() === '' ? null : obj.weight) : null;
    const weight_arrival = obj.weight_arrival
      ? obj.weight_arrival.toString() === ''
        ? null
        : obj.weight_arrival
      : null;
    const car_id = carNumberMap.get(obj.car);
    const driver_id = obj.driver !== undefined ? driverFioMap.get(obj.driver) : undefined;

    if (rowId === undefined) return false;
    const entry = getRunById(rowId);
    if (entry === undefined) return false;

    return await updateRun({
      ...entry,
      weight,
      weight_arrival,
      car_id: car_id !== undefined ? car_id : entry.car_id,
      driver_id: driver_id !== undefined ? driver_id : entry.driver_id,
      date_arrival: obj.date_arrival,
      date_departure: obj.date_departure,
    });
  };

  const options = useMemo(() => {
    const res = new Map<string, string[]>();
    res.set('car', carNumberList);
    res.set('driver', driverIdList);

    return res;
  }, [carNumberList, driverIdList]);

  const isDeleteRowDialogDisabled = useMemo(() => {
    const val = cellSelectionModel ? Object.values(cellSelectionModel)[0] : undefined;
    const selectedCellFieldName = val ? Object.keys(val)[0] : undefined;
    const selectedCellFieldEditable = columns.find(column => column.field === selectedCellFieldName)?.editable;

    return selectedRowId === '0' || selectedCellFieldEditable || false;
  }, [cellSelectionModel, columns, selectedRowId]);

  return (
    <>
      <Stack direction="row" alignItems="center" mb={5}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h4">Внесение информации о выставлении рейса заказчику</Typography>
          <Typography variant="subtitle2">Изменение рейса</Typography>
        </Box>
        <ProgressBar isLoading={isPendingActions} />
      </Stack>
      <EditRunsTableFilter
        date={userSettings.filterDateRange}
        onDateChanged={range => {
          userSettings.saveFilterDateRange(range);
          void reloadDocuments();
        }}
        handleApplyDelete={handleRowDelete}
        disableDeleteDialog={isDeleteRowDialogDisabled}
        copyBtnDisabled={selectedRowId === '0'}
        onCopyBtnClick={handleCopyRowClick}
      />
      <PageProgressBar isLoading={isLoading ?? isPendingList}>
        <DataTableGrid
          columns={columns}
          rows={rows}
          editMode={'cell'}
          checkboxSelection={false}
          hideFooterSelectedRowCount={true}
          rowHeight={30}
          tablePageModel={userSettings.tablePageModel}
          tableFilterModel={userSettings.tableFilterModel}
          tableSortModel={userSettings.tableSortModel}
          tableVisibilityModel={userSettings.tableVisibilityModel}
          tableDensityMode={userSettings.tableDensityMode}
          tableColumnsWidth={userSettings.columnsWidth}
          tableColumnsOrder={userSettings.columnsOrder}
          saveTablePageData={userSettings.saveTablePageData}
          saveTableVisibilityData={userSettings.saveTableVisibilityData}
          saveTableSortData={userSettings.saveTableSortData}
          saveTableFilterData={userSettings.saveTableFilterData}
          saveTableDensityMode={userSettings.saveTableDensityMode}
          saveTableColumnsWidth={userSettings.saveColumnsWidth}
          saveTableColumnsOrder={userSettings.saveColumnsOrder}
          mutationUpdate={handleUpdate}
          onRowClick={handleRowClick}
          rowsForUpdate={rowsForUpdate}
          optionsForEditField={options}
          onChangeCellSelectionModel={setCellSelectionModel}
          exportFileName={'Внесение информации о выставлении рейса заказчику'}
          isLoading={isLoading ?? isPendingList}
        />
      </PageProgressBar>
    </>
  );
});
