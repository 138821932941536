import {
  IBankAccountExRsDto,
  ICreatePaymentRequestDto,
  IExpenseItemRsDto,
  IPaymentRequestRsDto,
  IUpdatePaymentRequestDto,
  IUserMinRsDto,
  PaymentStatus,
  IPaymentRegistryRq,
} from '@src/service/types';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { SettingsStore } from '@src/store/SettingsStore';
import { IUserSettings } from '@src/store/types';
import { computed, makeAutoObservable } from 'mobx';
import { nowStr } from '@src/utils/date_utils';
import { bankAccountApiService } from '@src/service/BankAccountApiService';
import { PaymentRequestApiService } from '@src/service/PaymentRequestApiService';
import { ExpenseItemApiService } from '@src/service/ExpenseItemApiService';
import { userApiService } from '@src/service/UserApiService';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { Dayjs } from 'dayjs';

interface DownloadDialogStatistics {
  sum: number;
  approved: number;
  rejected: number;
  on_review: number;
  all_count: number;
}

class CPaymentRequestTableStore {
  // список PaymentRequest
  private _list: IPaymentRequestRsDto[] = [];
  private _expenseList: IExpenseItemRsDto[] = [];
  // Флаг состояния загрузки данных
  private _loadingOperations = 0;

  // Флаг состояния выполнения действий с PaymentRequest
  private _isPendingActions = false;
  private _isDownloadRegistry = false;
  // Стор с настройками пользователя
  private _settingsStore: SettingsStore | undefined;
  private _dateRange: DateRange<Dayjs>;

  // Пользователи
  private _user: IUserMinRsDto | undefined;
  private _usersList: IUserMinRsDto[] = [];

  // Банковские счета
  private _bankAccountsList: IBankAccountExRsDto[] = [];
  private _myBankAccountsList: IBankAccountExRsDto[] = [];

  // Статистика выгрузки реестра
  private _registryStatistics: DownloadDialogStatistics = {
    sum: 0,
    approved: 0,
    rejected: 0,
    on_review: 0,
    all_count: 0,
  };

  constructor() {
    makeAutoObservable(
      this,
      {
        settingsStore: computed,
        userSettings: computed,
      },
      { autoBind: true },
    );
    this.settingsStore = new SettingsStore('PaymentRequestTable_');
    this._dateRange = this.settingsStore.currentSettings.filterDateRange;
  }

  async init(): Promise<void> {
    await Promise.all([
      this.loadListPR(),
      this.loadExpenseItems(),
      this.loadBankAccounts(),
      this.loadUsers(),
      this.loadMe(),
      this.loadMyBankAccounts(),
    ]);
    console.log('PaymentRequest Table Store init...');
  }

  /**
   * Getter/setter
   */

  get settingsStore(): SettingsStore | undefined {
    return this._settingsStore;
  }

  set settingsStore(value: SettingsStore | undefined) {
    this._settingsStore = value;
  }

  get userSettings(): IUserSettings {
    return this.settingsStore?.currentSettings ?? ({} as IUserSettings);
  }

  get list(): IPaymentRequestRsDto[] {
    return this._list;
  }

  private set list(value: IPaymentRequestRsDto[]) {
    this._list = value;
  }

  get expenseList(): IExpenseItemRsDto[] {
    return this._expenseList;
  }

  private set expenseList(value: IExpenseItemRsDto[]) {
    this._expenseList = value;
  }
  get bankAccountsList(): IBankAccountExRsDto[] {
    return this._bankAccountsList;
  }

  private set bankAccountsList(value: IBankAccountExRsDto[]) {
    this._bankAccountsList = value;
  }

  get myBankAccountsList(): IBankAccountExRsDto[] {
    return this._myBankAccountsList;
  }

  private set myBankAccountsList(value: IBankAccountExRsDto[]) {
    this._myBankAccountsList = value;
  }

  get isPendingActions(): boolean {
    return this._isPendingActions;
  }

  private set isPendingActions(value: boolean) {
    this._isPendingActions = value;
  }

  get isDownloadRegistry(): boolean {
    return this._isDownloadRegistry;
  }

  private set isDownloadRegistry(value: boolean) {
    this._isDownloadRegistry = value;
  }

  get user(): IUserMinRsDto | undefined {
    return this._user;
  }

  set user(value: IUserMinRsDto | undefined) {
    this._user = value;
  }

  get usersList(): IUserMinRsDto[] {
    return this._usersList;
  }

  set usersList(value: IUserMinRsDto[]) {
    this._usersList = value;
  }

  get companyList(): Set<string> {
    return new Set<string>([...this.bankAccountsList.map(b => b.company.name)]);
  }

  get companyBankAccountsList(): Map<string, IBankAccountExRsDto[]> {
    const map = new Map<string, IBankAccountExRsDto[]>();
    this.companyList.forEach(company => {
      map.set(
        company,
        this.bankAccountsList.filter(b => b.company.name === company),
      );
    });

    return map;
  }
  get dateRange(): DateRange<Dayjs> {
    return this._dateRange;
  }

  set dateRange(value: DateRange<Dayjs>) {
    this._dateRange = value;
  }

  get registryStatistics(): DownloadDialogStatistics {
    return this._registryStatistics;
  }

  set registryStatistics(value: DownloadDialogStatistics) {
    this._registryStatistics = value;
  }

  /**
   * Loading tracker
   */

  get isLoading() {
    return this._loadingOperations > 0;
  }

  private startLoading() {
    this._loadingOperations++;
  }

  private stopLoading() {
    this._loadingOperations--;
  }

  /**
   * Business logics
   */

  private async loadListPR(): Promise<void> {
    try {
      this.startLoading();
      const dateStartStr = this.settingsStore?.dateStartStr ?? nowStr();
      const dateEndStr = this.settingsStore?.dateEndStr ?? nowStr();
      this.list = await PaymentRequestApiService.getDatedList({ start_day: dateStartStr, end_day: dateEndStr });
    } finally {
      this.stopLoading();
    }
  }

  private async loadBankAccounts(): Promise<void> {
    try {
      this.startLoading();

      this.bankAccountsList = await bankAccountApiService.getAll();
    } finally {
      this.stopLoading();
    }
  }
  private async loadMyBankAccounts(): Promise<void> {
    try {
      this.startLoading();

      this.myBankAccountsList = await bankAccountApiService.getMyBankAccounts();
    } finally {
      this.stopLoading();
    }
  }
  private async loadExpenseItems(): Promise<void> {
    try {
      this.startLoading();

      this.expenseList = await ExpenseItemApiService.getAll();
    } finally {
      this.stopLoading();
    }
  }

  private async loadUsers(): Promise<void> {
    try {
      this.startLoading();

      this.usersList = await userApiService.getAll();
    } finally {
      this.stopLoading();
    }
  }

  private async loadMe(): Promise<void> {
    try {
      this.startLoading();

      this.user = await userApiService.getMe();
    } finally {
      this.stopLoading();
    }
  }

  public async reloadListPR(): Promise<void> {
    await this.loadListPR();
  }

  public async createPaymentRequest(dto: ICreatePaymentRequestDto): Promise<boolean> {
    try {
      this.isPendingActions = true;
      const result = await PaymentRequestApiService.createPaymentRequest(dto);
      if (result) {
        this.list = [...this.list, result];

        return true;
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isPendingActions = false;
    }

    return false;
  }

  public async updatePaymentRequest(id: string, dto: IUpdatePaymentRequestDto): Promise<boolean> {
    try {
      this.isPendingActions = true;
      const result = await PaymentRequestApiService.updatePaymentRequest(id, dto);

      // Обновляем элемент в списке
      this.list = this.list.map(item => (item.id === id ? result : item));

      return true;
    } catch (e) {
      console.error(e);

      return false;
    } finally {
      this.isPendingActions = false;
    }
  }

  public async deletePaymentRequest(id: string): Promise<boolean> {
    try {
      this.isPendingActions = true;
      const result = await PaymentRequestApiService.deletePaymentRequest(id);
      if (result) {
        this.list = this.list.filter(item => item.id !== id);
      }

      return result;
    } finally {
      this.isPendingActions = false;
    }
  }

  public async approveMany(ids: string[]) {
    try {
      this.isPendingActions = true;
      const success = await PaymentRequestApiService.approveMany(ids);
      if (success) {
        const idsSet = new Set(ids);
        this.list = this.list.map(item =>
          idsSet.has(String(item.id)) ? { ...item, status: PaymentStatus.APPROVED } : item,
        );
      }

      return true;
    } catch (e) {
      console.error(e);

      return false;
    } finally {
      this.isPendingActions = false;
    }
  }

  public async rejectMany(ids: string[]) {
    try {
      this.isPendingActions = true;
      const success = await PaymentRequestApiService.rejectMany(ids);
      if (success) {
        const idsSet = new Set(ids);
        this.list = this.list.map(item =>
          idsSet.has(String(item.id)) ? { ...item, status: PaymentStatus.REJECTED } : item,
        );
      }

      return true;
    } catch (e) {
      console.error(e);

      return false;
    } finally {
      this.isPendingActions = false;
    }
  }

  public async deleteMany(ids: string[]) {
    try {
      this.isPendingActions = true;
      const success = await PaymentRequestApiService.deleteMany(ids);
      if (success) {
        const deletedIdsSet = new Set(ids);
        this.list = this.list.filter(item => !deletedIdsSet.has(String(item.id)));
      }

      return true;
    } catch (e) {
      console.error(e);

      return false;
    } finally {
      this.isPendingActions = false;
    }
  }

  public async downloadPaymentsRegistry(bankAccountId: string, ids: string[]) {
    try {
      // drop ids with not PaymentStatus.APPROVED

      const approvedIds = ids.filter(
        id => this.list.find(item => item.id.toString() === id)?.status === PaymentStatus.APPROVED,
      );

      const dto: IPaymentRegistryRq = { payer_bank_account_id: bankAccountId, items_ids: approvedIds };
      this.isDownloadRegistry = true;
      const success = await PaymentRequestApiService.downloadPaymentsRegistry(dto);
      if (success) return true;
    } catch (e) {
      console.error(e);

      return false;
    } finally {
      this.isDownloadRegistry = false;
    }
  }
  async updateDateRange(range: DateRange<Dayjs>) {
    this.dateRange = range;
    this.userSettings.saveFilterDateRange(range);
    await this.reloadListPR();
  }

  public updateRegistryStatistics = (selectedIds: GridRowSelectionModel) => {
    this.registryStatistics = this.list
      .filter(row => selectedIds.some(id => id.toString() === row.id.toString()))
      .reduce(
        (stats: DownloadDialogStatistics, item) => {
          stats.all_count += 1;
          if (item.status === PaymentStatus.APPROVED) {
            stats.sum += parseFloat(item.amount);
            stats.approved += 1;
          } else if (item.status === PaymentStatus.REJECTED) {
            stats.rejected += 1;
          } else if (item.status === PaymentStatus.PENDING) {
            stats.on_review += 1;
          }
          
return stats;
        },
        { sum: 0, approved: 0, rejected: 0, on_review: 0, all_count: 0 },
      );
  };
}

export const paymentRequestTableStore = new CPaymentRequestTableStore();
