import { Action } from '@src/store/action';

import {
  IDaysRqDto,
  ITransportDataTableApiService,
  ITransportDataTableDto,
  ITransportDataTableUpdateRq,
  ITransportDataTableUpdateRqPartial,
} from '@src/service/types';

class CTransportDataTableApiService implements ITransportDataTableApiService {
  private readonly HOST_URL = '/api/v2/transport_data_table';

  private GetRunListAction = new Action<IDaysRqDto, ITransportDataTableDto[]>({ isInterrupted: true });
  private UpdateMultipleRunAction = new Action<ITransportDataTableUpdateRq[], boolean>();
  private UpdateManyPartialDtoAction = new Action<ITransportDataTableUpdateRqPartial[], boolean>();

  /**
   * Получить список записей Runs
   */
  async getListRuns(dto: IDaysRqDto): Promise<ITransportDataTableDto[]> {
    const result = await this.GetRunListAction.callAction(
      `${this.HOST_URL}/get_dated?date_from=${dto.start_day}&date_to=${dto.end_day}`,
      'GET',
    );

    if (result === true || !result) {
      return [];
    }

    return result as ITransportDataTableDto[];
  }

  /**
   * Обновить данные Run
   */
  async updateMultipleRun(dto: ITransportDataTableUpdateRq[]): Promise<boolean> {
    const result = await this.UpdateMultipleRunAction.callAction(`${this.HOST_URL}/multiple_update`, 'PATCH', dto);

    return result !== undefined;
  }

  async mutateManyWithSameValues(dto: ITransportDataTableUpdateRqPartial[]): Promise<boolean> {
    const result = await this.UpdateManyPartialDtoAction.callAction(`${this.HOST_URL}/multiple_update`, 'PATCH', dto);

    return result !== undefined;
  }
}

export const TransportDataTableApiService: ITransportDataTableApiService = new CTransportDataTableApiService();
