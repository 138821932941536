import { Dialog, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import { ICompanyCreateDto } from '@src/service/types';
import AddIcon from '@mui/icons-material/Add';

interface IFormInputs {
  name: string;
  inn: string;
  kpp?: string;
  is_blocked: boolean;
}

interface INewCompanyDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: ICompanyCreateDto) => void;
  disabled?: boolean;
}

const validators = {
  name: {
    required: 'Наименование обязательно',
    maxLength: {
      value: 255,
      message: 'Максимальная длина 255 символов',
    },
  },
  inn: {
    required: 'ИНН обязателен',
    pattern: {
      value: /^\d{10}(\d{2})?$/,
      message: 'ИНН должен состоять из 10 или 12 цифр',
    },
  },
  kpp: {
    pattern: {
      value: /^\d{9}$/,
      message: 'КПП должен состоять из 9 цифр',
    },
  },
};

const buttonSx = {
  textTransform: 'none',
  borderRadius: 2,
  borderWidth: 2,
  padding: '6px 16px',
  '&:hover': {
    borderWidth: 2,
  },
};

export const AddNewCompanyDialog: FC<INewCompanyDialogProps> = ({ isOpen, onClose, onSubmit, disabled }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<IFormInputs>({ mode: 'onChange', defaultValues: { kpp: '', is_blocked: false } });

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="add-company-dialog-title"
      aria-describedby="add-company-dialog-description"
      disableRestoreFocus
      maxWidth={'md'}
      fullWidth
    >
      <DialogTitle id="add-company-dialog-title">Новая компания</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={1.5}>
            <input type="hidden" {...register('is_blocked')} />

            <TextField
              {...register('name', validators.name)}
              autoFocus
              margin="dense"
              label="Наименование"
              type="text"
              fullWidth
              variant="standard"
              error={Boolean(errors.name)}
              helperText={errors.name?.message || 'Формальное наименование компании, как указано в счетах и УПД'}
            />
            <TextField
              {...register('inn', validators.inn)}
              margin="dense"
              label="ИНН"
              type="text"
              fullWidth
              variant="standard"
              error={Boolean(errors.inn)}
              helperText={errors.inn?.message || 'Должен состоять из 10 или 12 цифр'}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                maxLength: 12,
              }}
            />
            <TextField
              {...register('kpp', validators.kpp)}
              margin="dense"
              label="КПП"
              type="text"
              fullWidth
              variant="standard"
              helperText={'КПП должен состоять из 9 цифр'}
              error={Boolean(errors.kpp)}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                maxLength: 9,
              }}
            />
            <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
              <Button
                disabled={disabled || !isValid}
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                sx={buttonSx}
                type="submit"
              >
                Добавить
              </Button>
              <Button onClick={onClose} variant="outlined" sx={buttonSx} type={'button'}>
                Отмена
              </Button>
            </Stack>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};
