import { Action } from '@src/store/action';

import {
  IBankAccountApiService,
  IBankAccountExRsDto,
  IBankAccountCreateDto,
  IUpdateBankAccountDto,
} from '@src/service/types';

class ClBankAccountApiService implements IBankAccountApiService {
  private readonly HOST_URL = '/api/v1/bank_account';
  private GetAllBankAccountsAction = new Action<void, IBankAccountExRsDto[]>();
  private CreateBankAccountAction = new Action<IBankAccountCreateDto, IBankAccountExRsDto>();
  private deleteBankAccountAction = new Action<string, boolean>();
  private updateBankAccountAction = new Action<IUpdateBankAccountDto, IBankAccountExRsDto>();

  async getAll(): Promise<IBankAccountExRsDto[]> {
    const result = await this.GetAllBankAccountsAction.callAction(`${this.HOST_URL}/get_all`, 'GET');

    return result as IBankAccountExRsDto[];
  }

  async createBankAccount(dto: IBankAccountCreateDto): Promise<IBankAccountExRsDto> {
    const result = await this.CreateBankAccountAction.callAction(`${this.HOST_URL}/create`, 'POST', dto);

    return result as IBankAccountExRsDto;
  }

  async deleteBankAccount(id: string): Promise<boolean> {
    const result = await this.deleteBankAccountAction.callAction(`${this.HOST_URL}/${id}`, 'DELETE');

    return result as boolean;
  }

  async updateBankAccount(id: string, dto: IUpdateBankAccountDto): Promise<IBankAccountExRsDto> {
    const result = await this.updateBankAccountAction.callAction(`${this.HOST_URL}/${id}`, 'PATCH', dto);

    return result as IBankAccountExRsDto;
  }
  async getMyBankAccounts(): Promise<IBankAccountExRsDto[]> {
    const result = await this.GetAllBankAccountsAction.callAction(`${this.HOST_URL}/get_my_accounts`, 'GET');

    return result as IBankAccountExRsDto[];
  }
}

export const bankAccountApiService: IBankAccountApiService = new ClBankAccountApiService();
