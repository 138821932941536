import React, { FC } from 'react';
import { ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const Home: FC = () => {
  const theme = useTheme();

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4">Главная страница</Typography>
      </Stack>
      <ListItemText>
        <Link to={'create_runs'} key={'create_runs'} style={{ color: theme.palette.primary.light }}>
          <Typography>Расстановка машин на маршруты</Typography>
        </Link>
        <Link to={'edit_runs'} key={'edit_runs'} style={{ color: theme.palette.primary.light }}>
          <Typography>Внесение информации о выставлении рейса заказчику</Typography>
        </Link>
        <Link to={'transport_data'} key={'transport_data'} style={{ color: theme.palette.primary.light }}>
          <Typography>Внесение транспортных данных</Typography>
        </Link>
        <Link to={'client_documents'} key={'client_documents'} style={{ color: theme.palette.primary.light }}>
          <Typography>Выставление документов заказчику</Typography>
        </Link>
        <Link to={'companies'} key={'companies'} style={{ color: theme.palette.primary.light }}>
          <Typography>Контрагенты</Typography>
        </Link>
        <Link to={'payment_requests'} key={'payment_requests'} style={{ color: theme.palette.primary.light }}>
          <Typography>Заявки на оплату</Typography>
        </Link>
        {/*<Link to={'user_event_log'} key={'user_event_log'} style={{ color: theme.palette.primary.light }}>*/}
        {/*  <Typography>Журнал событий</Typography>*/}
        {/*</Link>*/}
      </ListItemText>
    </Container>
  );
};
