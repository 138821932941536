/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ProgressBar } from '@src/components/ProgressBar/ProgressBar';
import { observer } from 'mobx-react-lite';
import { PageProgressBar } from '@src/components/PageProgressBar/PageProgressBar';
import { GridCellSelectionModel, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { DataTableGrid, ICustomToolbarButtonProps } from '@src/components/DataTable/DataTableGrid';
import Box from '@mui/material/Box';
import { GridAggregationModel } from '@mui/x-data-grid-premium/hooks/features/aggregation/gridAggregationInterfaces';
import { AlertInputDialog, IAlertInputDialogContent } from '@src/components/AlertInputDialog/AlertInputDialog';
import { dateColumnType, filterOperators } from '@src/utils/tables/utils';
import { ClientDocumentsTableStore } from '@src/components/Tables/ClientDocumentsTable/store/ClientDocumentsTableStore';
import { ClientDocumentsTableFilter } from '@src/components/Tables/ClientDocumentsTable/components/ClientDocumentsTableFilter/ClientDocumentsTableFilter';
import {
  IClientDocumentsTableDto,
  IClientDocumentsTableDtoPartial,
  IClientDocumentsTableUpdateRq,
} from '@src/service/types';
import { toStr } from '@src/utils/date_utils';
import dayjs from 'dayjs';
import { DictStore } from '@src/store/DictStore';
import { WsStore } from '@src/store/WsStore';

type ColorFields = keyof Pick<IClientDocumentsTableDto, 'weight_color' | 'weight_arrival_color'>;

export const ClientDocumentsTable: FC = observer(() => {
  const {
    isPendingList,
    init,
    isPendingActions,
    userSettings,
    reloadListRuns,
    updateRun,
    list,
    updatedList,
    getRunById,
    addRegistryToMany,
    addClientInvoiceToMany,
    setValueColor,
  } = ClientDocumentsTableStore;
  const { carNumberMap, driverFioMap } = DictStore;
  const { wsClientDocumentsData, action, item_id } = WsStore;

  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [dialogContent, setDialogContent] = useState<IAlertInputDialogContent>();
  const [viewMode, setViewMode] = useState<boolean>(userSettings.viewMode);
  const [visibilityModel, setVisibilityModel] = useState(userSettings.tableVisibilityModel);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>();
  const [cellSelectionModel, setCellSelectionModel] = useState<GridCellSelectionModel>();
  const [rowsForUpdate, setRowsForUpdate] = useState<object[] | undefined>(undefined);

  useEffect(() => {
    void init();
  }, [init]);

  useEffect(() => {
    if (wsClientDocumentsData && wsClientDocumentsData.length > 0) {
      setRowsForUpdate(makeRows(wsClientDocumentsData));
    }
  }, [wsClientDocumentsData]);

  useEffect(() => {
    if (action === 'delete' && item_id !== undefined) {
      setRowsForUpdate([{ id: item_id, _action: 'delete' }]);
    }
  }, [action, item_id]);

  const isRowsSelected = useMemo(
    () => rowSelectionModel !== undefined && rowSelectionModel.length > 0,
    [rowSelectionModel],
  );

  const columns: GridColDef[] = useMemo(() => {
    const cols: GridColDef[] = [
      {
        field: 'client',
        headerName: 'Клиент',
        description: 'Клиент',
        flex: 8,
        minWidth: 100,
        type: 'string',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        resizable: true,
        groupable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'cargo',
        headerName: 'Груз',
        description: 'Груз',
        flex: 8,
        minWidth: 100,
        type: 'string',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        resizable: true,
        groupable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'route',
        headerName: 'Маршрут',
        description: 'Маршрут',
        flex: 2,
        minWidth: 100,
        type: 'string',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'item_id',
        headerName: 'ИД рейса',
        description: 'ИД рейса',
        flex: 1,
        minWidth: 100,
        type: 'string',
        align: 'center',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'driver_fio',
        headerName: 'Водитель',
        description: 'Водитель',
        flex: 2,
        minWidth: 100,
        type: 'singleSelect',
        align: 'center',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
        valueFormatter: value => value ?? '',
      },
      {
        field: 'car_plate_number',
        headerName: 'Машина',
        description: 'Машина',
        flex: 1,
        minWidth: 100,
        type: 'singleSelect',
        align: 'center',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'tn_document_name',
        headerName: 'Номер ТН',
        description: 'Номер ТН',
        flex: 1,
        minWidth: 100,
        type: 'string',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'client_invoice_name',
        headerName: 'Номер УПД',
        description: 'Номер УПД',
        flex: 1,
        minWidth: 100,
        type: 'string',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'client_registry_name',
        headerName: 'Номер реестра',
        description: 'Номер реестра',
        flex: 1,
        minWidth: 100,
        type: 'string',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'supplier_invoice_name',
        headerName: 'УПД Поставщика',
        description: 'УПД Поставщика',
        flex: 1,
        minWidth: 100,
        type: 'string',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'weight_arrival',
        headerName: 'Вес прибытия',
        description: 'Вес прибытия',
        flex: 1,
        minWidth: 100,
        type: 'number',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'weight',
        headerName: 'Вес отправления',
        description: 'Вес отправления',
        flex: 1,
        minWidth: 100,
        type: 'number',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'date_departure',
        headerName: 'Дата отправления',
        description: 'Дата отправления',
        flex: 1,
        ...dateColumnType,
        minWidth: 100,
        type: 'date',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
        valueGetter: value => value && new Date(value),
        valueFormatter: value => value && dayjs(value).format('DD.MM.YYYY'),
      },
      {
        field: 'date_arrival',
        headerName: 'Дата прибытия',
        description: 'Дата прибытия',
        flex: 1,
        ...dateColumnType,
        minWidth: 100,
        type: 'date',
        align: 'center',
        editable: true,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
        valueGetter: value => value && new Date(value),
        valueFormatter: value => value && dayjs(value).format('DD.MM.YYYY'),
      },
      {
        field: 'supplier',
        headerName: 'Поставщик',
        description: 'Поставщик',
        flex: 1,
        minWidth: 100,
        type: 'string',
        align: 'center',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'trailer_description',
        headerName: 'Прицеп',
        description: 'Прицеп',
        flex: 1,
        minWidth: 200,
        type: 'string',
        align: 'center',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'driver_license',
        headerName: 'ВУ',
        description: 'ВУ',
        flex: 1,
        minWidth: 100,
        type: 'string',
        align: 'center',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'client_price',
        headerName: 'Ставка Клиенту',
        description: 'Вес отправления',
        flex: 1,
        minWidth: 100,
        type: 'number',
        align: 'center',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
      {
        field: 'run_price',
        headerName: 'Стоимость Клиенту',
        description: 'Стоимость Клиенту',
        flex: 1,
        minWidth: 100,
        type: 'number',
        align: 'center',
        editable: false,
        resizable: true,
        filterOperators: filterOperators,
        headerClassName: 'super-app-theme--header',
      },
    ];

    return cols;
  }, []);

  const makeRows = (list: IClientDocumentsTableDto[]) => {
    return list?.map(item => ({
      id: item.item_id,
      item_id: item.item_id,
      car_plate_number: item.car_plate_number,
      driver_fio: item.driver_fio,
      date_departure: item.date_departure,
      date_arrival: item.date_arrival,
      weight: item.weight ? parseFloat(item.weight?.toString() ?? 0) : null,
      weight_arrival: item.weight_arrival ? parseFloat(item.weight_arrival?.toString() ?? 0) : null,
      weight_color: item.weight_color,
      weight_arrival_color: item.weight_arrival_color,
      client: item.client,
      cargo: item.cargo,
      route: item.route,
      tn_document_name: item.tn_document_name,
      client_invoice_name: item.client_invoice_name,
      client_registry_name: item.client_registry_name,
      supplier_invoice_name: item.supplier_invoice_name,
      supplier: item.supplier,
      trailer_description: item.trailer_description,
      driver_license: item.driver_license,
      client_price: item.client_price,
      run_price: item.run_price,
    }));
  };

  const rows = useMemo(() => {
    return makeRows(list);
  }, [list]);

  const aggregationFields: GridAggregationModel = {
    weight: 'sum',
    weight_arrival: 'sum',
  };

  const prepareDtoForUpdate = useCallback(
    (dtos: IClientDocumentsTableDto[]) => {
      return dtos.map(
        dto =>
          ({
            ...dto,
            weight: dto.weight ? (dto.weight.toString() === '' ? null : dto.weight) : null,
            weight_arrival: dto.weight_arrival
              ? dto.weight_arrival.toString() === ''
                ? null
                : dto.weight_arrival
              : null,
            car_id: carNumberMap.get(dto.car_plate_number),
            driver_id: driverFioMap.get(dto.driver_fio),
            date_arrival: dto.date_arrival ? toStr(dto.date_arrival) : dto.date_arrival,
            date_departure: dto.date_departure ? toStr(dto.date_departure) : dto.date_departure,
          }) as IClientDocumentsTableUpdateRq,
      );
    },
    [carNumberMap, driverFioMap],
  );

  const handleUpdate = useCallback(
    async (obj: any): Promise<boolean> => {
      const itemFromList = updatedList.find(item => item.item_id === obj.item_id);
      const weight_color = obj.weight != itemFromList?.weight ? 1 : obj.weight_color;
      const weight_arrival_color = obj.weight_arrival != itemFromList?.weight_arrival ? 1 : obj.weight_arrival_color;
      const result = {
        ...obj,
        weight_color,
        weight_arrival_color,
      };
      await updateRun(prepareDtoForUpdate([result]));

      return result;
    },
    [prepareDtoForUpdate, updateRun, updatedList],
  );

  const handleChangeMode = (): void => {
    const mode = !viewMode;
    const model = {
      ...visibilityModel,
      client_invoice_name: mode,
      client_registry_name: !mode,
      supplier: mode,
      supplier_invoice_name: mode,
    };
    setViewMode(mode);
    setVisibilityModel(model);
    userSettings.saveViewMode(mode);
    userSettings.saveTableVisibilityData(model);
  };

  const handleAddRegeditNumber = useCallback(() => {
    if (rowSelectionModel && rowSelectionModel.length > 0) {
      const _ids: string[] = [...rowSelectionModel].map(id => String(id));
      const _items: IClientDocumentsTableDto[] = updatedList.filter(item =>
        _ids.some(id => id === item.item_id.toString()),
      );
      setDialogContent({
        title: 'Добавление реестра',
        label: 'Номер реестра',
        content: 'Добавить реестр для выбранных рейсов',
        handleApply: value => {
          (async () => {
            try {
              const success = await addRegistryToMany(_ids, value);
              if (success) {
                const updatedItems = _items.map(item => ({ ...item, client_registry_name: value }));
                setRowsForUpdate(makeRows(updatedItems));
              }
            } catch (error) {
              console.error('Error applying changes:', error);
            }
          })();
        },
      });
      setDialogIsOpen(true);
    }
  }, [addRegistryToMany, rowSelectionModel, updatedList]);

  const handleAddUPDNumber = useCallback(() => {
    if (rowSelectionModel && rowSelectionModel.length > 0) {
      const _ids: string[] = [...rowSelectionModel].map(id => String(id));
      const _items: IClientDocumentsTableDto[] = updatedList.filter(item =>
        _ids.some(id => id === item.item_id.toString()),
      );

      setDialogContent({
        title: 'Добавление УПД',
        label: 'Номер УПД',
        content: 'Добавить УПД для выбранных рейсов',
        handleApply: value => {
          (async () => {
            try {
              const success = await addClientInvoiceToMany(_ids, value);
              if (success) {
                const updatedItems = _items.map(item => ({ ...item, client_invoice_name: value }));
                setRowsForUpdate(makeRows(updatedItems));
              }
            } catch (error) {
              console.error('Error applying changes:', error);
            }
          })();
        },
      });
      setDialogIsOpen(true);
    }
  }, [addClientInvoiceToMany, rowSelectionModel, updatedList]);

  const customToolbarButtons: ICustomToolbarButtonProps[] = useMemo(() => {
    const result: ICustomToolbarButtonProps[] = [
      {
        text: 'Добавить реестр',
        onClick: handleAddRegeditNumber,
        disabled: !isRowsSelected,
      },
      {
        text: 'Добавить УПД',
        onClick: handleAddUPDNumber,
        disabled: !isRowsSelected,
      },
    ];

    return result;
  }, [handleAddRegeditNumber, isRowsSelected, handleAddUPDNumber]);

  const onColorPicked = useCallback(
    async (colorValue: number) => {
      if (!cellSelectionModel) {
        return;
      }
      const backendUpdateData: IClientDocumentsTableDtoPartial[] = [];
      const updateItems: IClientDocumentsTableDto[] = [];

      Object.entries(cellSelectionModel).forEach(([id, fields]) => {
        const item = getRunById(id);
        if (item) {
          const itemUpdate: IClientDocumentsTableDtoPartial = { item_id: item.item_id };
          Object.entries(fields).forEach(([field, isSelected]) => {
            if (isSelected && (field === 'weight' || field === 'weight_arrival')) {
              const colorField: ColorFields = `${field}_color`;
              if (item[colorField] !== colorValue && !(item[colorField] === undefined && colorValue === 0)) {
                itemUpdate[colorField] = colorValue;
              }
            }
          });
          if (Object.keys(itemUpdate).length > 1) {
            backendUpdateData.push(itemUpdate);
            updateItems.push({ ...item, ...itemUpdate });
          }
        }
      });

      if (backendUpdateData.length > 0 && updateItems.length > 0) {
        try {
          const success = await setValueColor(backendUpdateData);

          if (success) setRowsForUpdate(makeRows(updateItems));
        } catch (error) {
          console.error('Error applying changes:', error);
        }
      }
    },
    [cellSelectionModel, getRunById, setValueColor],
  );

  return (
    <>
      <Stack direction="row" alignItems="center" mb={5}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h4">Выставление документов заказчику</Typography>
          <Typography variant="subtitle2">Выставление документов</Typography>
        </Box>
        <ProgressBar isLoading={isPendingActions} />
      </Stack>
      <ClientDocumentsTableFilter
        date={userSettings.filterDateRange}
        onDateChanged={range => {
          userSettings.saveFilterDateRange(range);
          void reloadListRuns();
        }}
        viewMode={viewMode}
        onChangeViewMode={handleChangeMode}
        onColorPicked={onColorPicked}
      />
      <PageProgressBar isLoading={isPendingList}>
        <DataTableGrid
          columns={columns}
          rows={rows}
          rowsForUpdate={rowsForUpdate}
          editMode={'cell'}
          checkboxSelection={true}
          hideFooterSelectedRowCount={false}
          rowHeight={30}
          tablePageModel={userSettings.tablePageModel}
          tableFilterModel={userSettings.tableFilterModel}
          tableSortModel={userSettings.tableSortModel}
          tableVisibilityModel={visibilityModel}
          tableDensityMode={userSettings.tableDensityMode}
          tableColumnsWidth={userSettings.columnsWidth}
          tableColumnsOrder={userSettings.columnsOrder}
          saveTablePageData={userSettings.saveTablePageData}
          saveTableVisibilityData={userSettings.saveTableVisibilityData}
          saveTableSortData={userSettings.saveTableSortData}
          saveTableFilterData={userSettings.saveTableFilterData}
          saveTableDensityMode={userSettings.saveTableDensityMode}
          saveTableColumnsWidth={userSettings.saveColumnsWidth}
          saveTableColumnsOrder={userSettings.saveColumnsOrder}
          mutationUpdate={handleUpdate}
          exportFileName={'Выставление документов заказчику'}
          isLoading={isPendingList}
          rowGroupingColumnMode={'multiple'}
          rowGroupingFields={['client', 'cargo']}
          aggregationFields={aggregationFields}
          toolbarCustomButtons={customToolbarButtons}
          rowSelectionModel={rowSelectionModel}
          onChangeRowSelectionModel={setRowSelectionModel}
          onChangeCellSelectionModel={setCellSelectionModel}
          cellsBackgroundColorsForFields={['weight', 'weight_arrival']}
          notBooleanAsUpdateResult
        />
      </PageProgressBar>
      <AlertInputDialog
        title={dialogContent?.title ?? ''}
        label={dialogContent?.label ?? ''}
        content={dialogContent?.content ?? ''}
        handleApply={dialogContent?.handleApply ?? (() => undefined)}
        handleCancel={() => setDialogIsOpen(false)}
        isOpen={dialogIsOpen}
        disabled={isPendingActions}
      />
      {/*<AlertTableDialog*/}
      {/*  title={'Список поставщиков'}*/}
      {/*  handleCancel={() => setDialogWithBalanceTableOpen(false)}*/}
      {/*  isOpen={dialogWithBalanceTableOpen}*/}
      {/*/>*/}
    </>
  );
});
