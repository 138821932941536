import { Action } from '@src/store/action';

import { IUserMinRsDto, IUserApiService } from '@src/service/types';

class CUserApiService implements IUserApiService {
  private readonly HOST_URL = '/api/v1/users';
  private GetAllAction = new Action<void, IUserMinRsDto[]>();
  private GetMeAction = new Action<void, IUserMinRsDto>();

  /**
   * Получить справочные данные о пользователях сервиса
   */
  async getAll(): Promise<IUserMinRsDto[]> {
    const result = await this.GetAllAction.callAction(`${this.HOST_URL}/get_all_minimal`, 'GET');

    return result as IUserMinRsDto[];
  }

  /**
   * Получить информацию о текущем пользователе
   */
  async getMe(): Promise<IUserMinRsDto> {
    const result = await this.GetMeAction.callAction(`${this.HOST_URL}/me`, 'GET');

    return result as IUserMinRsDto;
  }
}

export const userApiService: CUserApiService = new CUserApiService();
