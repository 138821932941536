import { makeAutoObservable } from 'mobx';

import { nowStr } from '@src/utils/date_utils';

import { IUserSettings } from '@src/store/types';
import { SettingsStore } from '@src/store/SettingsStore';

import {
  IClientDocumentsTableDto,
  IClientDocumentsTableUpdateRq,
  IClientDocumentsTableDtoPartial,
} from '@src/service/types';
import { ClientDocumentsTableApiService } from '@src/service/ClientDocumentsTableApiService';

class CClientDocumentsTableStore {
  // список Run
  private _list: IClientDocumentsTableDto[] = [];
  private _updatedList: IClientDocumentsTableDto[] = [];

  // Флаг состояния формирования списка Run
  private _isPendingList = false;
  // Флаг состояния выполнения действий с Run
  private _isPendingActions = false;
  // Стор с настройками пользователя
  private _settingsStore: SettingsStore | undefined;
  private _userSettings: IUserSettings | undefined;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.settingsStore = new SettingsStore('table_4_');
    this.userSettings = this.settingsStore.currentSettings;
  }

  async init(): Promise<void> {
    console.log('ClientDocuments Table Store init...');
    await this.loadListRuns();
  }

  // #region getter/setter

  get settingsStore(): SettingsStore | undefined {
    return this._settingsStore;
  }

  set settingsStore(value: SettingsStore | undefined) {
    this._settingsStore = value;
  }

  get userSettings(): IUserSettings {
    return this._userSettings ?? ({} as IUserSettings);
  }

  set userSettings(value: IUserSettings | undefined) {
    this._userSettings = value;
  }

  get list(): IClientDocumentsTableDto[] {
    return this._list;
  }

  private set list(value: IClientDocumentsTableDto[]) {
    this._list = value;
  }

  get updatedList(): IClientDocumentsTableDto[] {
    return this._updatedList;
  }

  set updatedList(value: IClientDocumentsTableDto[]) {
    this._updatedList = value;
  }

  get isPendingList(): boolean {
    return this._isPendingList;
  }

  private set isPendingList(value: boolean) {
    this._isPendingList = value;
  }

  get isPendingActions(): boolean {
    return this._isPendingActions;
  }

  private set isPendingActions(value: boolean) {
    this._isPendingActions = value;
  }

  // #endregion

  // #region business logic (BL)

  public getRunById(id: number | string): IClientDocumentsTableDto | undefined {
    return this.updatedList.find(run => run.item_id == id);
  }

  public async updateRun(dtos: IClientDocumentsTableUpdateRq[]): Promise<boolean> {
    try {
      this.isPendingActions = true;

      const res = await ClientDocumentsTableApiService.updateMultipleRun(dtos);
      if (res) {
        this.updatedList = this.updatedList.map(dto => {
          const found = dtos.find(d => d.item_id === dto.item_id);

          return found ? { ...dto, ...found } : dto;
        });
      }

      return res;
    } finally {
      this.isPendingActions = false;
    }
  }

  public async reloadListRuns(): Promise<void> {
    await this.loadListRuns();
  }

  private async loadListRuns(): Promise<void> {
    try {
      this.isPendingList = true;
      const dateStartStr = this.settingsStore?.dateStartStr ?? nowStr();
      const dateEndStr = this.settingsStore?.dateEndStr ?? nowStr();

      this.list = await ClientDocumentsTableApiService.getListRuns({ start_day: dateStartStr, end_day: dateEndStr });
      this.updatedList = this.list;
    } finally {
      this.isPendingList = false;
    }
  }

  /**
   * New function replaced updateRun. Used partial income items as args except of full data items.
   * Prevents unexpected behavior (rewrite another users changes).
   * @param updatedItems
   */

  async mutateMany(updatedItems: IClientDocumentsTableDtoPartial[]): Promise<boolean> {
    try {
      this.isPendingActions = true;
      const success = await ClientDocumentsTableApiService.mutateManyWithSameValues(updatedItems);
      if (success) {
        this.updatedList = this.updatedList.map(dto => {
          const found = updatedItems.find(d => d.item_id.toString() === dto.item_id.toString());

          return found ? { ...dto, ...found } : dto;
        });
      }

      return success;
    } finally {
      this.isPendingActions = false;
    }
  }

  public async addRegistryToMany(ids: string[], newValue: string): Promise<boolean> {
    const _updates: IClientDocumentsTableDtoPartial[] = [];
    ids.forEach(id => _updates.push({ item_id: id, client_registry_name: newValue }));

    return await this.mutateMany(_updates);
  }

  public async addClientInvoiceToMany(ids: string[], newValue: string): Promise<boolean> {
    const _updates: IClientDocumentsTableDtoPartial[] = [];
    ids.forEach(id => _updates.push({ item_id: id, client_invoice_name: newValue }));

    return await this.mutateMany(_updates);
  }

  public async setValueColor(updatedItems: IClientDocumentsTableDtoPartial[]): Promise<boolean> {
    return await this.mutateMany(updatedItems);
  }

  // #endregion
}

export const ClientDocumentsTableStore = new CClientDocumentsTableStore();
