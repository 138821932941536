/* eslint-disable @typescript-eslint/no-explicit-any */
import { alpha, darken, lighten, styled, useTheme } from '@mui/material/styles';
import { DataGridPremium } from '@mui/x-data-grid-premium';

// const getBackgroundColor = (color: string, mode: string) =>
//   mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

// const getHoverBackgroundColor = (color: string, mode: string) =>
//   mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

// const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
//   mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);
//
// export const useStyles: any = makeStyles({
//   grid: {
//     // display: 'flex',
//     // flexDirection: 'column-reverse',
//   },
// });
//
// export const Transition = forwardRef(function Transition(
//   props: TransitionProps & {
//     children: React.ReactElement<any, any>;
//   },
//   ref: React.Ref<unknown>,
// ) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

export const StyledDataGrid = styled(DataGridPremium)(() => {
  const theme = useTheme();
  const isLightMode = theme.palette.mode === 'light';
  const commonStyles = {
    border: 0,
    borderRadius: 0,
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiFormLabel-root': {
      fontSize: 12,
      lineHeight: 1,
      marginTop: 4,
    },
    '& .MuiDataGrid-pinnedRows': {
      backgroundColor: 'white',
    },
    '& .MuiDataGrid-columnHeader': {
      '& .MuiIconButton-root': {
        margin: 'auto 0 7px 0',
      },
      ':focus-within': {
        outline: 'none !important',
      },
      '& .MuiInputLabel-shrink': {
        display: 'none',
      },
      '& .MuiButtonBase-root': {},
      '& .MuiInput-root': {
        '& .MuiInputAdornment-root': {
          marginBottom: 0,
        },
        '& .MuiIconButton-root': {
          margin: '0 0 8px 0',
        },
        '& .MuiSvgButton-root': {},
        ':before': {
          bottom: 10,
        },
        ':after': {
          borderBottom: 'none',
        },
      },
    },
    '& .MuiDataGrid-scrollbar--vertical': {
      top: 86,
    },
    '& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell': {
      overflow: 'hidden',
    },
    '& .MuiPaginationItem-root': {
      borderRadius: 0,
    },
    '& .MuiDataGrid-toolbarContainer': {
      '& .MuiButtonBase-root': {
        height: 40,
      },
      '& .MuiButton-text': {
        borderRadius: 4,
        marginBottom: 15,
        padding: theme.spacing(1, 2, 1, 2),
        color: theme.palette.text.primary,
        fontSize: 16,
        lineHeight: '143%',
        letterSpacing: '0.17px',
      },
    },
  };

  const mainAccountStyles = {
    '& .main-account-row': {
      backgroundColor: isLightMode ? 'rgba(76, 215, 100, 0.2)' : 'rgba(76, 215, 100, 0.3)',
      '&:hover': {
        backgroundColor: isLightMode ? 'rgba(76, 215, 100, 0.3)' : 'rgba(76, 215, 100, 0.4)',
      },
      '&.Mui-selected': {
        backgroundColor: isLightMode ? 'rgba(76, 215, 100, 0.35)' : 'rgba(76, 215, 100, 0.45)',
        '&:hover': {
          backgroundColor: isLightMode ? 'rgba(76, 215, 100, 0.4)' : 'rgba(76, 215, 100, 0.5)',
        },
      },
    },
    // Подсветка для полосатых строк
    '& .main-account-row.super-app-theme, & .main-account-row.super-app-theme-even': {
      backgroundColor: isLightMode ? 'rgba(76, 215, 100, 0.2)' : 'rgba(76, 215, 100, 0.3)',
      '&:hover': {
        backgroundColor: isLightMode ? 'rgba(76, 215, 100, 0.3)' : 'rgba(76, 215, 100, 0.4)',
      },
      '&.Mui-selected': {
        backgroundColor: isLightMode ? 'rgba(76, 215, 100, 0.35)' : 'rgba(76, 215, 100, 0.45)',
        '&:hover': {
          backgroundColor: isLightMode ? 'rgba(76, 215, 100, 0.4)' : 'rgba(76, 215, 100, 0.5)',
        },
      },
    },
  };

  const lightModeStyles = {
    color: 'rgba(0,0,0,.85)',
    '& .MuiDataGrid-columnsContainer': {
      // backgroundColor: '#fafafa',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
      borderRight: '1px solid #f0f0f0',
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: '1px solid #f0f0f0',
    },
    '& .MuiDataGrid-cell': {
      color: 'rgba(0,0,0,.85)',
      minHeight: 0,
    },
    '& .MuiDataGrid-cell.cold': {
      backgroundColor: theme.palette.info.light,
    },
    '& .MuiDataGrid-cell.hot': {
      backgroundColor: theme.palette.info.dark,
    },
    '& .MuiDataGrid-row--borderBottom, & .MuiDataGrid-headerFilterRow': {
      backgroundColor: `${theme.palette.primary['lighter']} !important`,
    },
    '& .super-app-theme': {
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.light, 0.5),
      },
      '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(alpha(theme.palette.primary.light, 0.7), theme.palette.mode),
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.light, 0.5),
        },
      },
    },
    '& .super-app-theme-even': {
      backgroundColor: alpha('#E4E7EB', 0.2),
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.light, 0.5),
      },
      '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(alpha(theme.palette.primary.light, 0.7), theme.palette.mode),
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.light, 0.5),
        },
      },
    },
    '& .super-app-theme-blocked': {
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.light, 0.7),
      },
      '&.Mui-selected': {
        backgroundColor: alpha(theme.palette.primary.light, 0.7),
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.light, 0.5),
        },
      },
    },
    '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
      backgroundColor: '#E4E7EB',
    },
    ...mainAccountStyles,
  };

  const darkModeStyles = {
    color: 'rgba(255,255,255,0.85)',
    '& .MuiDataGrid-columnsContainer': {
      // backgroundColor: '#1d1d1d',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
      borderRight: '1px solid #303030',
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: '1px solid #303030',
    },
    '& .MuiDataGrid-cell': {
      color: 'rgba(255,255,255,0.65)',
      minHeight: 0,
    },
    '& .MuiDataGrid-cell.cold': {
      backgroundColor: theme.palette.info.light,
    },
    '& .MuiDataGrid-cell.hot': {
      backgroundColor: theme.palette.info.dark,
    },
    '& .MuiDataGrid-row--borderBottom, & .MuiDataGrid-headerFilterRow': {
      backgroundColor: '#415C80 !important',
    },
    '& .super-app-theme': {
      backgroundColor: '#94A3B60D',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.dark,
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.dark, 0.8),
        },
      },
    },
    '& .super-app-theme-even': {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.dark,
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.dark, 0.8),
        },
      },
    },
    '& .super-app-theme-blocked': {
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.dark,
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.dark, 0.8),
        },
      },
    },
    '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
      backgroundColor: '#415C80',
    },
    ...mainAccountStyles,
  };

  return {
    ...commonStyles,
    ...(isLightMode ? lightModeStyles : darkModeStyles),
  };
});
