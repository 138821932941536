import { makeAutoObservable, runInAction } from 'mobx';

import { IUserSettings } from '@src/store/types';
import { SettingsStore } from '@src/store/SettingsStore';

import { bankAccountApiService } from '@src/service/BankAccountApiService';

import { IBankAccountExRsDto, IBankAccountCreateDto, IUpdateBankAccountDto } from '@src/service/types';
import { companyTableStore } from '@src/components/Tables/CompanyTable/store/CompanyTableStore';

class CBankAccountsTableStore {
  // список BankAccounts
  private _list: IBankAccountExRsDto[] = [];
  // Флаг состояния формирования списка BankAccounts
  // private _isPendingList = false;
  // Флаг состояния выполнения действий с BankAccounts
  private _isPendingActions = false;
  // Стор с настройками пользователя
  private _settingsStore: SettingsStore | undefined;
  private _userSettings: IUserSettings | undefined;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.settingsStore = new SettingsStore('table_bank_accounts_');
    this.userSettings = this.settingsStore.currentSettings;
  }

  // #region getter/setter

  get settingsStore(): SettingsStore | undefined {
    return this._settingsStore;
  }

  set settingsStore(value: SettingsStore | undefined) {
    this._settingsStore = value;
  }

  set userSettings(value: IUserSettings | undefined) {
    this._userSettings = value;
  }

  get list(): IBankAccountExRsDto[] {
    return this._list;
  }

  private set list(value: IBankAccountExRsDto[]) {
    this._list = value;
  }

  get isPendingActions(): boolean {
    return this._isPendingActions;
  }

  private set isPendingActions(value: boolean) {
    this._isPendingActions = value;
  }

  get mainAccountId(): string {
    if (!this._list.length) {
      return '0';
    }
    const mainAccount = this._list.find(acc => acc.is_main);

    return mainAccount?.id ?? '0';
  }

  private sortAccounts(accounts: IBankAccountExRsDto[]): IBankAccountExRsDto[] {
    return accounts.sort((a, b) => {
      if (a.is_main) return -1;
      if (b.is_main) return 1;

      const idA = String(a.id);
      const idB = String(b.id);

      return idA.localeCompare(idB);
    });
  }

  setInitialAccounts(accounts: IBankAccountExRsDto[]) {
    this._list = this.sortAccounts([...accounts]);
  }

  // #endregion

  // #region business logic (BL)

  public async deleteBankAccount(id: string, companyId: string): Promise<boolean> {
    try {
      this.isPendingActions = true;
      const result = await bankAccountApiService.deleteBankAccount(id);
      if (result) {
        const updatedList = this.list.filter(item => item.id !== id);
        this.list = this.sortAccounts(updatedList);

        // Обновляем список счетов в родительском сторе
        await companyTableStore.refreshCompany(companyId);
      }

      return result;
    } finally {
      this.isPendingActions = false;
    }
  }

  public async createBankAccount(dto: IBankAccountCreateDto): Promise<boolean> {
    try {
      this.isPendingActions = true;
      const created = await bankAccountApiService.createBankAccount(dto);
      if (created) {
        this.list = this.sortAccounts([...this.list, { ...created }]);

        // Обновляем список счетов в родительском сторе
        await companyTableStore.refreshCompany(created.company.id);
      }

      return created !== undefined;
    } finally {
      this.isPendingActions = false;
    }
  }

  public async setMainAccount(id: string, company__id: string): Promise<boolean> {
    try {
      this.isPendingActions = true;

      const dto: IUpdateBankAccountDto = {
        is_main: true,
        company__id: company__id,
      };

      const currentMainAccount = this.list.find(item => item.is_main);

      const result = await bankAccountApiService.updateBankAccount(id, dto);
      if (result) {
        runInAction(() => {
          if (currentMainAccount) {
            currentMainAccount.is_main = false;
          }
          const updatedList = this.list.map(item => ({
            ...item,
            is_main: item.id === id,
          }));
          this.list = this.sortAccounts(updatedList);
        });
        await companyTableStore.refreshCompany(company__id);

        return true;
      }

      return false;
    } finally {
      this.isPendingActions = false;
    }
  }

  // #endregion
}

export const BankAccountsTableStore = new CBankAccountsTableStore();
