import {
  IPaymentRequestRsDto,
  IPaymentRequestsTableDisplayDto,
  IPaymentCreateDialog,
  ICreatePaymentRequestDto,
  IUpdatePaymentRequestDto,
  PaymentStatus,
  IPaymentUpdateDialog,
  ICompanyExRsDto,
  ICompanyUpdateDto,
  ICompanyDisplayDto,
} from '@src/service/types';

const DEFAULT_PAYMENT_VALUES = {
  status: PaymentStatus.PENDING,
  is_blocked: false,
  is_paid: false,
} as const;

const DEFAULT_PAYMENT_STATUS_MAP = {
  [PaymentStatus.PENDING]: 'На согласовании',
  [PaymentStatus.APPROVED]: 'Согласовано',
  [PaymentStatus.REJECTED]: 'Отказано',
} as const;

const throwError = (message: string) => {
  throw new Error(message);
};

export const paymentRequestMappers = {
  // from IPaymentRequestRsDto dto
  toDisplay: (apiData: IPaymentRequestRsDto): IPaymentRequestsTableDisplayDto => {
    return {
      payment_date: apiData.payment_date,
      amount: apiData.amount,
      description: apiData.description,
      status: apiData.status,
      is_blocked: apiData.is_blocked,
      is_paid: apiData.is_paid,
      id: apiData.id,
      company_name: apiData.company__name,
      account_number: apiData.bank_account__account_number,
      bank_name: apiData.bank_account__bank_name,
      expense_item: apiData.expense_item__name,
      creator_id: apiData.creator_id,
      creator_name: apiData.creator_name,
    };
  },

  toCreateDialog: (apiData: IPaymentRequestRsDto): IPaymentCreateDialog => {
    return {
      payment_date: apiData.payment_date,
      amount: apiData.amount,
      description: apiData.description,
      company_name: apiData.company__name,
      account_number: apiData.bank_account__account_number,
      bank_name: apiData.bank_account__bank_name,
      expense_item: apiData.expense_item__name,
    };
  },

  fromCreateDialogToApi(
    dialogData: IPaymentCreateDialog,
    bankAccountId: string,
    expenseItemId: string,
  ): ICreatePaymentRequestDto {
    return {
      ...DEFAULT_PAYMENT_VALUES,
      payment_date: dialogData.payment_date ?? throwError('payment_date is required'),
      amount: dialogData.amount ?? throwError('amount is required'),
      description: dialogData.description ?? throwError('description is required'),
      bank_account__id: bankAccountId,
      expense_item__id: expenseItemId,
      creator_id: dialogData.creator_id ?? throwError('creator_id is required'),
      creator_name: dialogData.creator_name ?? throwError('creator_name is required'),
    };
  },

  statusToDisplay: DEFAULT_PAYMENT_STATUS_MAP,

  fromUpdateDisplayToUpdateApi(
    dialogData: IPaymentUpdateDialog,
    bankAccountId: string,
    expenseItemId: string,
  ): IUpdatePaymentRequestDto {
    return {
      bank_account__id: bankAccountId,
      payment_date: dialogData.payment_date,
      description: dialogData.description,
      expense_item__id: expenseItemId,
      amount: dialogData.amount,
      status: dialogData.status,
      is_blocked: dialogData.is_blocked,
      is_paid: dialogData.is_paid,
      creator_id: dialogData.creator_id,
      creator_name: dialogData.creator_name,
    };
  },

  fromUpdateDialogToCreateDialog(dialogData: IPaymentUpdateDialog): IPaymentCreateDialog {
    return {
      payment_date: dialogData.payment_date,
      amount: dialogData.amount,
      description: dialogData.description,
      company_name: dialogData.company_name,
      expense_item: dialogData.expense_item,
      account_number: dialogData.account_number,
      bank_name: dialogData.bank_name,
      creator_id: dialogData.creator_id,
      creator_name: dialogData.creator_name,
    };
  },
};

export const companyMappers = {
  // from RsDto
  toUpdateDto(dialogData: ICompanyExRsDto): ICompanyUpdateDto {
    return {
      kpp: dialogData.kpp,
      name: dialogData.name,
      is_blocked: dialogData.is_blocked,
    };
  },

  toDisplay(apiData: ICompanyExRsDto): ICompanyDisplayDto {
    return {
      id: apiData.id,
      inn: apiData.inn,
      kpp: apiData.kpp,
      name: apiData.name,
      is_blocked: apiData.is_blocked,
    };
  },

  fromDisplayToUpdate(dialogData: ICompanyDisplayDto): ICompanyUpdateDto {
    return {
      kpp: dialogData.kpp,
      name: dialogData.name,
      is_blocked: dialogData.is_blocked,
    };
  },
};
