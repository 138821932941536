import { Action } from '@src/store/action';

import {
  IClientDocumentsTableApiService,
  IClientDocumentsTableDto,
  IClientDocumentsTableDtoPartial,
  IClientDocumentsTableUpdateRq,
  IDaysRqDto,
} from '@src/service/types';

class CClientDocumentsTableApiService implements IClientDocumentsTableApiService {
  private readonly HOST_URL = '/api/v2/client_documents_table';

  private GetRunListAction = new Action<IDaysRqDto, IClientDocumentsTableDto[]>({ isInterrupted: true });
  private UpdateMultipleRunAction = new Action<IClientDocumentsTableUpdateRq[], boolean>();
  private UpdateManyPartialDtoAction = new Action<IClientDocumentsTableDtoPartial[], boolean>();

  /**
   * Получить список записей Runs
   */
  async getListRuns(dto: IDaysRqDto): Promise<IClientDocumentsTableDto[]> {
    const result = await this.GetRunListAction.callAction(
      `${this.HOST_URL}/get_dated?date_from=${dto.start_day}&date_to=${dto.end_day}`,
      'GET',
    );

    if (result === true || !result) {
      return [];
    }

    return result as IClientDocumentsTableDto[];
  }

  /**
   * Обновить данные Run
   */
  async updateMultipleRun(dto: IClientDocumentsTableUpdateRq[]): Promise<boolean> {
    const result = await this.UpdateMultipleRunAction.callAction(`${this.HOST_URL}/multiple_update`, 'PATCH', dto);

    return result !== undefined;
  }

  async mutateManyWithSameValues(dto: IClientDocumentsTableDtoPartial[]): Promise<boolean> {
    const result = await this.UpdateManyPartialDtoAction.callAction(`${this.HOST_URL}/multiple_update`, 'PATCH', dto);

    return result !== undefined;
  }
}

export const ClientDocumentsTableApiService: IClientDocumentsTableApiService = new CClientDocumentsTableApiService();
