import { makeAutoObservable } from 'mobx';

import { IUserSettings } from '@src/store/types';
import { SettingsStore } from '@src/store/SettingsStore';

import { CompanyApiService } from '@src/service/CompanyApiService';

import { ICompanyCreateDto, ICompanyExRsDto, ICompanyUpdateDto } from '@src/service/types';

class ClCompanyTableStore {
  // список Companies
  private _list: ICompanyExRsDto[] = [];
  // Флаг состояния формирования списка Companies
  private _isPendingList = false;
  // Флаг состояния выполнения действий с Companies
  private _isPendingActions = false;
  // Стор с настройками пользователя
  private _settingsStore: SettingsStore | undefined;
  private _userSettings: IUserSettings | undefined;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.settingsStore = new SettingsStore('table_companies_');
    this.userSettings = this.settingsStore.currentSettings;
  }

  async init(): Promise<void> {
    await this.loadListCompanies();
    console.log('Companies Table Store init...');
  }

  // #region getter/setter

  get settingsStore(): SettingsStore | undefined {
    return this._settingsStore;
  }

  set settingsStore(value: SettingsStore | undefined) {
    this._settingsStore = value;
  }

  get userSettings(): IUserSettings {
    return this._userSettings ?? ({} as IUserSettings);
  }

  set userSettings(value: IUserSettings | undefined) {
    this._userSettings = value;
  }

  get list(): ICompanyExRsDto[] {
    return this._list;
  }

  private set list(value: ICompanyExRsDto[]) {
    this._list = value;
  }

  get isPendingList(): boolean {
    return this._isPendingList;
  }

  private set isPendingList(value: boolean) {
    this._isPendingList = value;
  }

  get isPendingActions(): boolean {
    return this._isPendingActions;
  }

  private set isPendingActions(value: boolean) {
    this._isPendingActions = value;
  }

  // #endregion

  public async getCompany(id: string): Promise<ICompanyExRsDto | undefined> {
    try {
      this.isPendingActions = true;
      const result = await CompanyApiService.getCompany(id);
      if (result) {
        // Добавляем элемент в список
        this.list = [...(this.list ? this.list : []), result];

        return result;
      }
    } finally {
      this.isPendingActions = false;
    }
  }

  public async updateCompany(id: string, dto: ICompanyUpdateDto): Promise<boolean> {
    try {
      this.isPendingActions = true;
      const result = await CompanyApiService.updateCompany(id, dto);
      if (result) {
        // Обновляем элемент в списке
        this.list = this.list.map(item => (item.id === result.id ? result : item));

        return true;
      }

      return result !== undefined;
    } finally {
      this.isPendingActions = false;
    }
  }

  // #region business logic (BL)

  public async deleteCompany(id: string): Promise<boolean> {
    try {
      this.isPendingActions = true;
      const result = await CompanyApiService.deleteCompany(id);
      if (result) {
        this.list = this.list.filter(item => item.id !== id);
      }

      return result;
    } finally {
      this.isPendingActions = false;
    }
  }

  public async createCompany(dto: ICompanyCreateDto): Promise<boolean> {
    try {
      this.isPendingActions = true;
      const created = await CompanyApiService.createCompany(dto);
      if (created) {
        this.list = [...(this.list ? this.list : []), { ...created }];
      }

      return created !== undefined;
    } finally {
      this.isPendingActions = false;
    }
  }

  private async loadListCompanies(): Promise<void> {
    try {
      this.isPendingList = true;

      this.list = await CompanyApiService.getAllCompanies();
    } finally {
      this.isPendingList = false;
    }
  }

  public async refreshCompany(companyId: string): Promise<void> {
    try {
      this.list = this.list.filter(item => item.id !== companyId);
      await this.getCompany(companyId);
    } finally {
      this.isPendingActions = false;
    }
  }

  // #endregion
}

export const companyTableStore = new ClCompanyTableStore();
